<template>
  <div
    class="image-block"
    v-loading="loading"
  >

    <label></label>
    <p id="first"><i class="el-icon-check"></i></p>
    <img
      :src="file.presignedUrl"
      @load="onImgLoaded"
      @click.prevent="$emit('openEditDialog', file)"
      @error="imgError"
      alt="Image"
      class="thumbnail"
    >
    <div class="action">
      <font-awesome-icon
        @click.prevent="$emit('openEditDialog', file)"
        class="icon"
        icon="fa-thin fa-magnifying-glass"
        size="2x"
      />
      <font-awesome-icon
        @click.prevent="downloadImage"
        class="icon"
        icon="fa-thin fa-arrow-down-to-line"
        size="2x"
      />
      <font-awesome-icon
        @click.prevent="$emit('deleteImage', file)"
        class="icon"
        icon="fa-thin fa-trash-can"
        size="2x"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImageBlock',
  props: ['file'],
  data() {
    return {
      loading: true,
    };
  },
  methods: {
    imgError() {
      alert('file not found');
    },
    onImgLoaded() {
      this.loading = false;
    },
    downloadImage() {
      // window.open(this.file, '_blank');
      const a = document.createElement('a');
      const url = this.file.presignedUrl;
      a.href = url;
      a.download = url.split('/').pop();
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
  },
};
</script>
<style scoped lang="scss">
.image-block {
  width: 100%;
  height: 96px;
  row-gap: 8px;
  margin: 12px 0;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  background-color: #fff;
  transition: 0.3s ease all;
  overflow: hidden;
  z-index: 0;
  padding: 10px 10px 10px 90px;
  position: relative;

  label {
    position: absolute;
    display: block;
    right: -27px;
    top: -9px;
    width: 60px;
    height: 36px;
    background: #13ce66;
    text-align: center;
    transform: rotate(45deg);
    box-shadow: 0 1px 1px #ccc;
  }

  #first {
    position: absolute;
    right: 3px;
    top: 3px;
    color: white;
    z-index: 1;
    margin: 0;
    padding: 0;
  }

  .action {
    position: absolute;
    top: 28px;
    left: 90px;

    .icon {
      margin: 6px 22px;
      cursor: pointer;
    }
  }

  .action li {
    display: inline;
    list-style-type: none;
    padding-right: 5px;
    font-size: 1.2em;
    margin: 0 18px;
  }
}

.thumbnail {
  vertical-align: middle;
  display: inline-block;
  width: 70px;
  height: 70px;
  position: relative;
  z-index: 1;
  margin-left: -80px;
  background-color: #fff;
  cursor: pointer;
}
</style>