<template>
  <div>

    <b-card
      :id="'article-'+report.uuid"
      title="Card Title"
      img-alt="Image"
      img-top
      no-body
      tag="article"
      footer-tag="footer"
      :class="$store.getters.selectionMode ? 'goso-card-selectionMode' : 'goso-card'"
      :header-class="{
    'card-header-selectionMode': $store.getters.selectionMode,
    'card-header': !$store.getters.selectionMode
  }"
      @mouseover="checkboxVisible = true"
      @mouseleave="selected ? checkboxVisible = true : checkboxVisible = false"
    >

      <!-- Header -->

      <template #header>
        <b-row
          :id="'article-'+report.uuid+'header'"
          no-gutters
          class="d-flex align-items-center goso-card-header"
        >
          <b-col
            cols="6"
            class="inline"
          >
            <div class="detailed-container mr-4">
              <font-awesome-layers fa-fw>
                <font-awesome-icon
                  icon="fa-thin fa-circle"
                  :class="variant"
                  size="2x"
                />
                <font-awesome-icon
                  icon="fa-solid fa-circle"
                  transform="shrink-4"
                  :class="variant"
                  size="2x"
                />
              </font-awesome-layers>

            </div>
            <div class="detailed-container">
              <p class="text-left txt-article-title">
                {{ report.buildingName }} {{ report.level }}{{ report.zone }}{{ report.serial }} {{ report.definition}}
              </p>
              <p class="text-left txt-article-subtitle">
                {{ report.user?.acronym4digits || '...' }} {{ report.interventionDate }}
              </p>
            </div>

          </b-col>
          <b-col
            cols="6"
            class="d-flex justify-content-end"
          >
            <b-form-checkbox
              :class="['mr-1', { 'd-none d-md-inline': isMobile }]"
              v-show="checkboxVisible"
              :id="'checkbox-' + report.uuid"
              :name="'checkbox-' + report.uuid"
              v-model="selected"
              @change="checkboxChange"
              @click.stop
            >
            </b-form-checkbox>
            <el-button
              type="text"
              size="small"
              class="mr-1 p-0"
              @click="openModalMap"
            >
              <font-awesome-icon
                icon="fa-regular fa-map"
                :size="headerIconSize"
              />
            </el-button>
            <el-button
              type="text"
              size="small"
              class="mr-1 p-0"
              @click="showHistory(report.uuid, report.reportRef)"
            >
              <font-awesome-icon
                icon="fa-regular fa-clock"
                :size="headerIconSize"
              />
            </el-button>
            <el-button
              type="text"
              size="small"
              class="mr-1 p-0"
              @click="servePDF(report.uuid)"
            >
              <!-- @click="simplePDF(report.id)" -->
              <font-awesome-icon
                :id="'iconPdf-' + report.uuid"
                icon="fa-regular fa-file-pdf"
                :size="headerIconSize"
              />
              <font-awesome-icon
                :id="'spinnerPdf-' + report.uuid"
                icon="fa-regular fa-spinner"
                spin
                :size="headerIconSize"
                style="display: none;"
              />
            </el-button>

            <!-- <el-button
              type="text"
              size="mini"
              style="padding: 0;"
            >
              <font-awesome-icon
                icon="fa-light fa-ellipsis"
                :size="headerIconSize"
              />
            </el-button> -->
            <el-dropdown
              trigger="click"
              class="ml-2 mr-2"
              @command="(command) => handleReportCommand(command, report.uuid)"
            >
              <span class="el-dropdown-link">
                <font-awesome-icon
                  icon="fa-regular fa-ellipsis"
                  :size="headerIconSize"
                />
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  id="btnOpen"
                  command="OPEN"
                  class="text-left dropdown-action-item dropdown-action-open"
                >{{ $t('status.open') }}</el-dropdown-item>
                <el-dropdown-item
                  id="btnAddressed"
                  command="ADDRESSED"
                  class="text-left dropdown-action-item dropdown-action-addressed"
                >{{ $t('status.addressed') }}</el-dropdown-item>
                <el-dropdown-item
                  command="ONGOING"
                  class="text-left dropdown-action-item dropdown-action-ongoing"
                >{{ $t('status.ongoing') }}</el-dropdown-item>
                <el-dropdown-item
                  command="SOLVED"
                  class="text-left dropdown-action-item dropdown-action-solved"
                >{{ $t('status.solved')  }}</el-dropdown-item>
                <el-dropdown-item
                  command="CLOSED"
                  class="text-left dropdown-action-item dropdown-action-closed"
                >{{ $t('status.closed')  }}</el-dropdown-item>
                <el-dropdown-item
                  command="ONHOLD"
                  class="text-left dropdown-action-item dropdown-action-onHold"
                >{{ $t('status.onhold')  }}</el-dropdown-item>
                <el-dropdown-item
                  command="NOACTION"
                  class="text-left dropdown-action-item dropdown-action-noAction"
                >{{ $t('status.noaction')  }}</el-dropdown-item>
                <el-dropdown-item
                  command="DELETED"
                  class="text-left dropdown-action-item dropdown-action-deleted"
                >{{ $t('status.deleted')  }}</el-dropdown-item>

                <hr>

                <el-dropdown-item command="WI">
                  <font-awesome-layers>
                    <font-awesome-icon
                      icon="fa-light fa-square"
                      transform="grow-6 rotate-45"
                    />
                    <font-awesome-layers-text
                      class="gray8"
                      transform="shrink-6"
                      value="WI"
                    />
                  </font-awesome-layers>
                  &nbsp;{{ $t('action.wi')  }}
                </el-dropdown-item>

                <el-dropdown-item command="COMMENT">
                  <font-awesome-icon
                    icon="fa-light fa-comment"
                    size="lg"
                  /> {{ $t('action.comment')  }}
                </el-dropdown-item>

                <el-dropdown-item command="attachment">
                  <font-awesome-icon
                    icon="fa-light fa-paperclip"
                    size="lg"
                  /> {{ $t('action.document')  }}
                </el-dropdown-item>

                <el-dropdown-item command="closurePhoto">
                  <font-awesome-icon
                    icon="fa-regular fa-image"
                    size="lg"
                  /> {{ $t('action.closurePhoto')  }}
                </el-dropdown-item>

                <el-dropdown-item command="solvedPhoto">
                  <font-awesome-icon
                    icon="fa-light fa-image"
                    size="lg"
                  /> {{ $t('action.solvedPhoto')  }}
                </el-dropdown-item>

                <hr>
                <el-dropdown-item command="EDIT">
                  <font-awesome-icon
                    icon="fa-light fa-pen-square"
                    size="lg"
                  /> {{ $t('action.edit')  }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>

          </b-col>
        </b-row>

      </template>

      <!-- Image component -->

      <transition>
        <div class="image-container">

          <img
            v-lazy="report.presignedUrlPhoto1"
            fluid
            class="article-img"
            :alt="`Photo ${report.uuid}`"
            v-show="isLoad"
            @load="loaded"
            @click.prevent="showPreview(report)"
          />

          <!-- Image count indicator -->
          <div
            v-if="hasMultipleImages"
            class="image-count-indicator"
          >
            <font-awesome-icon :icon="['far', 'image']" />

            <span class="margin-left-6">{{ imageCount }}</span>
          </div>
        </div>
      </transition>

      <!-- Spinner -->

      <div
        class="loading-container"
        v-show="!isLoad"
        v-loading="true"
      ></div>

      <!-- Ref, type, WI, status -->

      <div class="info">
        <b-row class="row-article p-2">
          <b-col
            cols="3"
            class="text-left txt-article-ref txt-article-border"
            v-if="report.reportRef"
          >
            <span v-b-tooltip.hover.bottom="report.reportRef">
              {{ report.reportRef }}
            </span>
          </b-col>
          <b-col
            cols="2"
            class="d-flex justify-content-center txt-article-type txt-article-border"
          >
            <span
              v-b-tooltip.hover.bottom
              :title="formattedInspectionTypeTooltip"
            >
              {{ report.interventionType }}
            </span>
          </b-col>
          <b-col
            cols="3"
            class="d-flex justify-content-center txt-article-di txt-article-border"
          >
            <mark
              class="wiRef"
              v-if="wiData.wiRef != null"
              v-b-tooltip.hover.bottom
              :title="formattedWITooltip"
              @click="openModalWI(report.uuid)"
            >
              {{ wiData.wiRef }}
            </mark>
          </b-col>
          <b-col
            cols="4"
            class="text-right"
          >
            <Status
              :status="report.status"
              :target="'article'"
              class="status"
            />
          </b-col>
        </b-row>

        <!-- Program, remarks, description, standard, corrective action + comments count -->

        <b-row>
          <b-col class="mt-1 mb-1">
            <p
              class="body-chapter"
              v-if="this.report.familyName !== null"
            >{{ this.report.familyName }}</p>
            <p
              class="body-chapter"
              v-if="this.report.activityName !== null"
            >{{ this.report.activityName }}</p>
            <p
              class="body-chapter"
              v-if="this.report.specialtyName !== null"
            >{{ this.report.specialtyName }}</p>
            <div
              class="mt-1"
              v-if="this.report.description !== null && this.report.description !== '' || this.report.correctiveAction !== null && this.report.correctiveAction !== '' || this.report.standard !== null && this.report.standard !== ''"
            ></div>
            <p
              class="body-desc"
              v-if="this.report.description !== null && this.report.description !== ''"
            >{{ $t('report.description')}}: {{ this.report.description }}</p>
            <p
              class="body-desc"
              v-if="this.report.remarkLabels !== null && this.report.remarkLabels !== ''"
            >{{ $t('report.remark')}}: {{ this.report.remarkLabels }}</p>
            <p
              class="body-desc"
              v-if="this.report.correctiveAction !== null && this.report.correctiveAction !== ''"
            >{{ $t('report.correctiveAction')}}: {{ this.report.correctiveAction }}</p>
            <p
              class="body-desc"
              v-if="this.report.standard !== null && this.report.standard !== ''"
            >{{ $t('report.standard')}}: {{ this.report.standard }}</p>

            <div class="mt-1">
              <el-button
                v-if="this.$store.getters.getCommentCount(this.report.uuid) > 0"
                type="text"
                size="medium"
                class="mx-1 p-0"
                @click="openModal"
              >

                <b-badge
                  pill
                  variant="light"
                >
                  <font-awesome-icon
                    :icon="['fal', 'comment']"
                    size="lg"
                  /> {{ formattedCommentCount }}

                </b-badge>

              </el-button>
              <el-button
                type="text"
                size="medium"
                class="mx-1 p-0"
                @click="openModal"
              >
                <!-- <span class="hashtags">
                  #fast #reliable</span> -->
              </el-button>
            </div>
          </b-col>

        </b-row>

      </div>

      <div class="bottom-popup">
        <!-- Popup content goes here -->
        <p style="color: white; text-align: center;">Additional Information</p>
      </div>

      <!-- <template #footer>
            <b-row>
              <b-col cols="2">
                <font-awesome-icon
                  icon="fa-light fa-share-from-square"
                  class="droplet"
                />
              </b-col>
              <b-col
                cols="8"
                class="text-center"
              >
                <font-awesome-icon
                  icon="fa-light fa-angle-left"
                  size="sm"
                  style="margin-left: 0.1rem; margin-right:0.1rem;"
                />
                <font-awesome-icon
                  icon="fa-light fa-circle"
                  size="xs"
                  style="margin-left: 0.1rem; margin-right:0.1rem;"
                />
                <font-awesome-icon
                  icon="fa-light fa-circle"
                  size="xs"
                  style="margin-left: 0.1rem; margin-right:0.1rem; color: blue;"
                />
                <font-awesome-icon
                  icon="fa-light fa-circle"
                  size="xs"
                  style="margin-left: 0.1rem; margin-right:0.1rem;"
                />
                <font-awesome-icon
                  icon="fa-light fa-angle-right"
                  size="sm"
                  style="margin-left: 0.1rem; margin-right:0.1rem;"
                />
              </b-col>
              <b-col
                cols="2"
                class="text-right"
              >
                <el-checkbox
                  class="float-right"
                  v-model="selectedReports"
                ></el-checkbox>
              </b-col>
            </b-row>
          </template> -->

    </b-card>
    <!-- <modal-history></modal-history> -->

  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Status from '@/components/Explore/SharedExploreComponents/Status.vue';
import { reportActionMixin } from '../../../mixins/reportActionMixin';
import { selectionMixin } from '@/mixins/selectionMixin';
import { localRefMixin } from '../../../mixins/localRefMixin';
import moment from 'moment';

import swal from 'sweetalert2';
const swalBootsrap = swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary btn-swal',
    cancelButton: 'btn btn-light btn-swal',
  },
  buttonsStyling: false,
});

export default {
  name: 'article-detailed',
  mixins: [reportActionMixin, localRefMixin, selectionMixin],
  components: {
    Status,
  },
  props: ['report'],
  data() {
    return {
      selected: this.report.isSelected || false, // Initialize with report.isSelected
      checkboxVisible: this.report.isSelected || false, // Make checkbox visible if selected
      isMobile: window.innerWidth < 768, // Initial check for mobile
      headerIconSize: 'lg',
      isLoad: false,
      loading: true,
      componentReportId: '',
      previewUrl: '',
      isFooterVisible: false,
      reportUuid: this.report.uuid,
      loadimage: require('@/assets/gif/loading.gif'),
      errorimage: require('@/assets/gif/error.gif'),
      hasError: false,
    };
  },
  watch: {
    'report.isSelected': {
      immediate: true,
      handler(newVal) {
        this.selected = newVal;
        this.checkboxVisible = newVal; // Show checkbox if report is selected
      },
    },
  },
  mounted() {
    window.addEventListener('resize', this.checkMobile); // Listen for resize
    this.checkMobile(); // Initial check
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkMobile); // Clean up
  },
  methods: {
    checkMobile() {
      // Update `isMobile` based on screen width
      this.isMobile = window.innerWidth < 768;
    },
    openModalWI(reportUuid) {
      this.$store.commit('deleteSelection');

      this.$store.commit('selectReport', reportUuid);
      // Get selected report's data
      const reports = this.$store.state.reports;
      console.log(reports);
      const report = reports.reports.find(
        (report) => report.uuid === reportUuid
      );
      if (report) {
        this.$store.commit('selectReportData', report);
      }
      this.$bvModal.show('modal-wi');
    },
    showPreview(report) {
      this.$emit('show-preview', report);
    },
    openModal() {
      this.$store.commit('setReportUuid', this.report.uuid); // Store reportUuid in Vuex (for single action)
      // Open modal
      this.$bvModal.show('modal-comments');
      const clgStyle = 'color: red;';
      console.log('%c' + 'REPORT', clgStyle);
      console.log(this.report);
    },
    openModalMap() {
      console.log('openModalMap ArticleDetail');
      this.$emit('show-map', this.report);
    },
    toggleFooter() {
      this.isFooterVisible = !this.isFooterVisible;
    },
    notReady() {
      swalBootsrap.fire({
        title: 'Oops',
        text: this.$t('error.notReady'),
        showConfirmButton: true,
        icon: 'info',
      });
    },
    showPreview(report) {
      console.log('image clicked');
      this.$emit('show-preview', report);
    },
    loaded() {
      //わかりやすくするためsetTimeout処理入れてます。
      this.isLoad = true;
    },
    programComplete(family, activity, specialty, description, remarkLabels) {
      let html;
      const clgStyle = 'color: red;';
      console.log('%c' + 'DESCRIPTION=' + description, clgStyle);
      console.log(activity, specialty);
      if (family !== null && family !== '')
        html = `<span class="chapter" style: font-weight: 900;>${family}</span>`;
      if (activity !== null && activity !== '') html += '<br>' + activity;
      if (specialty !== null && specialty !== '') html += '<br>' + specialty;
      if (description != 'null' || description !== '')
        html += '<br>' + description;
      if (remarkLabels != 'null') html += '<br>' + remarkLabels;
      return html;
    },
  },
  // v-html="programComplete(report.familyName, report.activityName, report.specialtyName, report.description, report.remarkLabels)"
  computed: {
    ...mapGetters({
      getWI: 'getWI',
    }),
    formattedWITooltip() {
      let dueDateText;

      if (this.wiData.wiDueDate && this.wiData.wiDueDate !== '0000-00-00') {
        dueDateText = moment(this.wiData.wiDueDate).format('DD-MM-YYYY'); // e.g., "Nov 14, 2024"
      } else {
        dueDateText = this.$tc('wi.noDueDate'); // Display "No Due Date" if date is invalid
      }

      return dueDateText;
    },
    formattedInspectionTypeTooltip() {
      const translationKey = `interventionType.${this.report.interventionType}`;
      return this.$t(translationKey);
    },
    gifStyle() {
      return {
        width: '200px',
        height: 'auto',
        maxWidth: '100%',
      };
    },
    wiData() {
      return this.getWI(this.reportUuid);
    },
    hasMultipleImages() {
      return this.report.presignedUrlPhoto2 || this.report.presignedUrlPhoto3;
    },
    imageCount() {
      let count = 0;
      if (this.report.presignedUrlPhoto1) count++;
      if (this.report.presignedUrlPhoto2) count++;
      if (this.report.presignedUrlPhoto3) count++;
      return count;
    },
    formattedCommentCount() {
      // const count = this.report.commentsQtt;
      const count = this.$store.getters.getCommentCount(this.report.uuid);
      const commentText = this.$tc('comment.comment', count).toUpperCase();
      return `${count} ${commentText}`;
    },
    variant() {
      switch (this.report.priority) {
        case 'LONGTERM':
          return 'longterm';
        case 'MEDIUMTERM':
          return 'mediumterm';
        case 'SHORTTERM':
          return 'shortterm';
        case 'URGENT':
          return 'urgent';
        default:
          break;
      }
      return '';
    },
  },
};
</script>
<style scoped>
.spinnerPdf {
  display: none;
}
.wiRef {
  cursor: pointer;
}
</style>
<style scoped>

.image-container {
  position: relative;
  display: flex;
  justify-content: center;
  overflow: hidden; /* Contains the image zoom effect */
}

.image-count-indicator {
  position: absolute;
  bottom: 10px;
  left: 10px;
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
  padding: 5px 10px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.image-count-indicator .fa-camera {
  margin-right: 5px;
}
</style>
<style scoped>

.hashtags {
  font-weight: 400;
  font-size: 12px;
  color: gray;
}

.comments {
  margin-left: 8px;
  font-size: 11px;
  color: rgba(0, 123, 255, 0.9)
}


.slide-fade-enter-active, .slide-fade-leave-active {
  transition: all 0.3s ease;
}

.slide-fade-enter, .slide-fade-leave-to {
  transform: translateY(100%);
  opacity: 0;
}
</style>
<style scoped>

.circle {
    position: relative;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    margin-top: 0em;
    /* margin-left: auto; */
    margin-right: 2px; 
    border-style: solid;
    border-width: thin;
}
.innercircle {
    position: relative;
    border-style: solid;
    border-width: 2px;
    border-color: #fff;
    background-color: blue;
    /* background: #fff; */
    border-radius: 50%;
    height: 28px;
    width: 28px;
    top: 0;
    left: 0;
    /* margin: -18px 0px 0px -14px; */
}

@media (min-width: 768px) { 
/* .circle {
  min-height: 30px;
  min-width: 30px;
}
.innercircle {
  height: 28px;
    width: 28px;
} */
}

/* box-shadow: rgb(0 0 0 / 8%) 0px 4px 15px; */
.body-chapter {
  font-size: 11px;
  line-height: 15px;
  font-weight: 600 !important;
  color: #262626;
  margin-bottom: 0.1rem;
  /* overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;*/
  margin-left: 8px; 
}
/* .body-desc {
  font-size: 11px;
  line-height: 15px;
  margin-bottom: 0.1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 8px;
  color: #262626;
  margin-bottom: 0.085em;
} */
.body-desc {
  font-size: 11px;
  line-height: 15px;
  /* font-weight: 200 !important; */
  margin-bottom: 0.1rem;
  overflow: hidden;
  /* text-overflow: ellipsis; */
  /* white-space: nowrap; */
  margin-left: 8px;
  color: #262626;
  margin-bottom: 0.085em;
  word-wrap: break-word; /* Optional: ensures long words break and wrap onto the next line */
}

/* HOVER */
/* box-shadow: rgb(0 0 0 / 15%) 0px 6px 25px;
    transition: all 0.1s ease 0s; */
.card {
    border-radius: 0px !important;
    border: none;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 12px;
    border-bottom: 0.0425rem solid rgba(52, 58, 64, 0.285);
}
@media (min-width: 768px) { 
  .card {
    /* border-radius: 6px !important; */
    /* border: 0.0425rem solid rgba(52, 58, 64, 0.285); */
    /* margin-left: 6px;
    margin-right: 6px;
    margin-top: 6px;
    margin-bottom: 6px; */
    border: 1px solid rgba(0,0,0,.125);
    min-width: 300px;
  }
  /* .card:hover {
    box-shadow: rgb(4 17 29 / 25%) 0px 0px 6px 0px;
    transition: all 0.1s ease 0s;
    -wenkit-transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;
    -ms-transition: all .5s;
  } */
}

.card-header {
  padding: 0.975rem 0.525rem 0.975rem 0.525rem;
  /* border: 0.0625rem solid rgba(52, 58, 64, 0.285);
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important; */
  /* background-color: #ffffff; */
  border: none;
}
@media (min-width: 768px) { 
  .card-header {
    padding: 0.75rem 0.525rem 0.75rem 0.525rem;
    /* border-top-left-radius: 6px !important;
    border-top-right-radius: 6px !important; */
    /* background-color: #ffffff; */
    /* border: inherit; */
  }  
}

  

.card-footer {
  background: linear-gradient(rgba(229, 232, 235, 0.392) 0%, rgb(255, 255, 255) 20%);
  -webkit-box-align: center;
  align-items: center;
  /* display: flex; */
  padding: 0.45rem 0.525rem 0.45rem 0.525rem;
  font-weight: 500 !important;
  padding: 12px !important;
  height: 42px !important;
  border-top: none;
  border-bottom-left-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.row-article {
  margin-right: -10px !important;
}

.droplet {
      -webkit-box-align: center;
    align-items: center;
}
.droplet:hover {
  color: rgb(235, 87, 87);
}

.el-tooltip {
  border-radius: 10px;
}

.draw_share_atooltip{
   background: red !important;
 }

 

.goso-card {
  border: none; 
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 15px;
  overflow: hidden;
 }

 .card-header {
  padding: 0.45rem 0.975rem;
  background-color: #ffffff;
}

.card-header-selectionMode {
  padding: 0.45rem 0.975rem;
  background-color: #ecf5ff;
}

 .goso-card-selectionMode {
  background-color: #ecf5ff;
  border: none; 
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 15px;
  overflow: hidden;
}

 .goso-card-header {
  transition: transform 0.3s ease-in-out, margin-bottom 0.3s ease-in-out;
  margin-bottom: 0px;
 }

/* .article-img {
  width: 100%;
  max-height: 320px;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
}

.resized-gif {
  width: 50px; 
  height: auto; 
  max-width: 100%; 
} */

.article-img {
  width: 100%;
  max-height: 320px; /* Set the initial max height */
  object-fit: cover;
  transition: transform 0.3s ease-in-out; /* Add transition for smooth animation */
  background-color: #ffffff;
}

/* Specific styles for resized GIFs */
.resized-gif {
  width: 200px !important; /* Adjust width as needed */
  height: auto !important; /* Maintain aspect ratio */
  max-width: 100% !important; /* Ensure it does not exceed container width */
}



.goso-card:hover .image-container .article-img,
.goso-card-selectionMode:hover .image-container .article-img {
  transform: scale(1.08); /* Scale the image on hover */
  transform-origin: center center; /* Keep zoom centered */
  transition: transform 0.3s ease-in-out; /* Smooth zoom transition */
}


.info {
  margin-top: 0;
}


.status {
  transition: transform 0.3s ease-in-out, margin-top 0.3s ease-in-out;
}
.txt-article-ref {
  transition: transform 0.3s ease-in-out, margin-top 0.3s ease-in-out;
}
.txt-article-type {
  transition: transform 0.3s ease-in-out, margin-top 0.3s ease-in-out;
}
.txt-article-di {
  transition: transform 0.3s ease-in-out, margin-top 0.3s ease-in-out;
}



.status {
  display: inline-block; /* Ensure the status div fits its content */
  text-align: right; /* Align text to the right */
}

.text-right {
  text-align: right !important; /* Ensure text alignment to the right */
}




.card-custom-body {
  font-size: 12px;
  margin: 0.4rem 0.4rem;
}

.txt-article-title {
  font-weight: 600;
  line-height: 15px;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 5px;
  color: #262626;
  margin-top: 0.1em;
  margin-bottom: 0.1em;
}

.txt-article-subtitle {
  /* color: #262626; */
  color: #797878;
  line-height: 12px;
  font-size: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0.1em;
  margin-left: 5px;
}


.txt-article-body {
color: #262626;
line-height: 12px;
font-size: 12px;
font-weight: lighter !important;
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
margin-top: 0;
margin-bottom: 0.3rem;
}

.txt-article-ref {
  color: #6c757d!important;
  line-height: 13px !important;
  font-size: 13px;
  font-weight: 300;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0.1em;
  margin-top: 4px;
}
.txt-article-type {
  color: #6c757d!important;
  line-height: 13px !important;
  font-size: 13px;
  font-weight: 300;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0.1em;
  margin-top: 4px;
}
.txt-article-di {
  color: #262626;
  line-height: 12px !important;
  font-size: 11px;
  font-weight: lighter !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0.1em;
  margin-top: 4px;
}
.text-di-logo {
  font-weight: 700;
}
/* .txt-article-ref {
  color: #262626;
  line-height: 12px !important;
  font-size: 12px;
  font-weight: lighter !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0.1em;
} */


.longterm {
    color: blue !important;
}
.mediumterm {
    color: yellow !important;
}
.shortterm {
    color: orange !important;
}
.urgent {
    color: red !important;
}


.detailed-container {
  display: inline-block;
  vertical-align: middle;
}
.loading-container {
  width: 100%; 
  height: 200px;
}

.txt-article-border {
  border-right: 1px solid rgba(0, 0, 0, .0975);
}
</style>

<style scoped>
.hover-card {
  position: relative;
  overflow: hidden;
}

.bottom-popup {
  position: absolute;
  bottom: -40px; /* Start hidden below the card */
  left: 0;
  right: 0;
  height: 40px;
  background-color: #007bff; /* Blue color */
  border-bottom-left-radius: 8px; /* Rounded bottom left corner */
  border-bottom-right-radius: 8px; /* Rounded bottom right corner */
  transition: bottom 0.2s ease; /* Smooth pop-up effect */
  display: flex;
  align-items: center;
  justify-content: center;
}

</style>