<template>
  <thead style="z-index: 11;">
    <tr>
      <th
        class="header"
        scope="col"
      >
        <b-form-checkbox
          id="checkbox-all"
          :checked="isAllSelected"
          name="checkbox-all"
          value="true"
          unchecked-value="false"
          class="margin-bottom-10"
          @change="toggleAllSelection"
        />
      </th>
      <th
        class="align-middle min-width-80"
        scope="col"
        id="id"
        :class="cols.id"
      >ID
        <span class="caret-wrapper">
          <i
            class="sort-caret ascending"
            :class="{ active: currentSortingPreference === 'idAsc' }"
            @click="handleSortingCommand('idAsc')"
          ></i>
          <i
            class="sort-caret descending"
            :class="{ active: currentSortingPreference === 'idDesc' }"
            @click="handleSortingCommand('idDesc')"
          ></i>
        </span>
      </th>
      <!-- <th
        class="align-middle"
        scope="col"
        id="interventionType"
        style="min-width: 100px;"
        :class="cols.type"
      >TYPE
        <span class="caret-wrapper">
          <i
            class="sort-caret ascending"
            @click="sortAscending"
          ></i>
          <i
            class="sort-caret descending"
            @click="sortDescending"
          ></i>
        </span>
      </th> -->
      <th
        class="align-middle min-width-100"
        scope="col"
        id="result"
        :class="cols.result"
      >{{ $t('explore.tableHeader.result')}}
        <span class="caret-wrapper">
          <i
            class="sort-caret ascending"
            :class="{ active: currentSortingPreference === 'resultAsc' }"
            @click="handleSortingCommand('resultAsc')"
          ></i>
          <i
            class="sort-caret descending"
            :class="{ active: currentSortingPreference === 'resultDesc' }"
            @click="handleSortingCommand('resultDesc')"
          ></i>
        </span>
      </th>
      <th
        class="align-middle min-width-110"
        scope="col"
        id="status"
        :class="cols.status"
      >{{ $t('explore.tableHeader.status')}}
        <span class="caret-wrapper">
          <i
            class="sort-caret ascending"
            :class="{ active: currentSortingPreference === 'statusAsc' }"
            @click="handleSortingCommand('statusAsc')"
          ></i>
          <i
            class="sort-caret descending"
            :class="{ active: currentSortingPreference === 'statusDesc' }"
            @click="handleSortingCommand('statusDesc')"
          ></i>
        </span>
      </th>
      <th
        class="align-middle min-width-100"
        scope="col"
        id="di"
        :class="cols.di"
      >WI
        <span class="caret-wrapper">
          <i
            class="sort-caret ascending"
            :class="{ active: currentSortingPreference === 'wiAsc' }"
            @click="handleSortingCommand('wiAsc')"
          ></i>
          <i
            class="sort-caret descending"
            :class="{ active: currentSortingPreference === 'wiDesc' }"
            @click="handleSortingCommand('wiDesc')"
          ></i>
        </span>
      </th>
      <!-- <th class="header"
        scope="col"
        id="addressedTo"
        :class="cols.addressedTo"
        style="min-width: 140px;"
      >Addressé à
        <span class="caret-wrapper">
          <i
            class="sort-caret ascending"
            @click="sortAscending"
          ></i>
          <i
            class="sort-caret descending"
            @click="sortDescending"
          ></i>
        </span>
      </th> -->
      <th
        class="align-middle min-width-180"
        scope="col"
        id="buildingName"
        :class="cols.building"
      >{{ $t('explore.tableHeader.reference')}}
        <span class="caret-wrapper">
          <i
            class="sort-caret ascending"
            :class="{ active: currentSortingPreference === 'refAsc' }"
            @click="handleSortingCommand('refAsc')"
          ></i>
          <i
            class="sort-caret descending"
            :class="{ active: currentSortingPreference === 'refDesc' }"
            @click="handleSortingCommand('refDesc')"
          ></i>
        </span>
      </th>
      <!-- <th class="header"
        scope="col"
        id="level"
        :class="cols.level"
        style="min-width: 80px;"
      >Niv.
        <span class="caret-wrapper">
          <i
            class="sort-caret ascending"
            @click="sortAscending"
          ></i>
          <i
            class="sort-caret descending"
            @click="sortDescending"
          ></i>
        </span>
      </th> -->
      <!-- <th class="header"
        scope="col"
        id="zone"
        :class="cols.zone"
        style="min-width: 80px;"
      >Zo.
        <span class="caret-wrapper">
          <i
            class="sort-caret ascending"
            @click="sortAscending"
          ></i>
          <i
            class="sort-caret descending"
            @click="sortDescending"
          ></i>
        </span>
      </th> -->
      <!-- <th class="header"
        scope="col"
        id="serial"
        :class="cols.serial"
        style="min-width: 100px;"
      >Serial
        <span class="caret-wrapper">
          <i
            class="sort-caret ascending"
            @click="sortAscending"
          ></i>
          <i
            class="sort-caret descending"
            @click="sortDescending"
          ></i>
        </span>
      </th> -->
      <!-- <th class="header"
        scope="col"
        id="definition"
        :class="cols.definition"
        style="min-width: 100px;"
      >Def.
        <span class="caret-wrapper">
          <i
            class="sort-caret ascending"
            @click="sortAscending"
          ></i>
          <i
            class="sort-caret descending"
            @click="sortDescending"
          ></i>
        </span>
      </th> -->
      <th
        class="align-middle min-width-300 max-width-400"
        scope="col"
        id="program"
        :class="cols.program"
      >{{ $t('explore.tableHeader.program')}}
        <span class="caret-wrapper">
          <i
            class="sort-caret ascending"
            :class="{ active: currentSortingPreference === 'programAsc' }"
            @click="handleSortingCommand('programAsc')"
          ></i>
          <i
            class="sort-caret descending"
            :class="{ active: currentSortingPreference === 'programDesc' }"
            @click="handleSortingCommand('programDesc')"
          ></i>
        </span>
      </th>
      <th
        class="align-middle min-width-360"
        scope="col"
        id="description"
        :class="cols.description"
      >{{ $t('explore.tableHeader.description')}}
        <span class="caret-wrapper">
          <i
            class="sort-caret ascending"
            :class="{ active: currentSortingPreference === 'descriptionAsc' }"
            @click="handleSortingCommand('descriptionAsc')"
          ></i>
          <i
            class="sort-caret descending"
            :class="{ active: currentSortingPreference === 'descriptionDesc' }"
            @click="handleSortingCommand('descriptionDesc')"
          ></i>
        </span>
      </th>

      <th
        class="align-middle min-width-180"
        scope="col"
        id="interventionDate"
        :class="cols.interventionDate"
      >{{ $t('explore.tableHeader.date')}}
        <span class="caret-wrapper">
          <i
            class="sort-caret ascending"
            :class="{ active: currentSortingPreference === 'latestReports' }"
            @click="handleSortingCommand('latestReports')"
          ></i>
          <i
            class="sort-caret descending"
            :class="{ active: currentSortingPreference === 'oldestReports' }"
            @click="handleSortingCommand('oldestReports')"
          ></i>
        </span>
      </th>
      <th
        class="align-middle min-width-180"
        scope="col"
        id="description"
        :class="cols.interventionDate"
      >{{ $t('explore.tableHeader.by')}}
        <!-- <span class="caret-wrapper">
          <i
            class="sort-caret ascending"
            @click="sortAscending"
          ></i>
          <i
            class="sort-caret descending"
            @click="sortDescending"
          ></i>
        </span> -->
      </th>
      <th
        class="align-middle min-width-180"
        scope="col"
        id="description"
        :class="cols.interventionDate"
      >{{ $t('explore.tableHeader.entity')}}
        <!-- <span class="caret-wrapper">
          <i
            class="sort-caret ascending"
            @click="sortAscending"
          ></i>
          <i
            class="sort-caret descending"
            @click="sortDescending"
          ></i>
        </span> -->
      </th>
    </tr>
  </thead>
</template>
<script>
import axiosResource from '@/utils/axiosResource';
import { selectionMixin } from '@/mixins/selectionMixin';
import { sortMixin } from '@/mixins/sortMixin';

export default {
  name: 'table-header',
  mixins: [selectionMixin, sortMixin],
  data() {
    return {
      isAllSelected: this.$store.getters.allSelected,
      idCol: '',
      typeCol: '',
      resCol: '',
      statusCol: '',
      cols: {
        id: '',
        type: '',
        result: '',
        status: '',
        di: '',
        addressedTo: '',
        building: '',
        level: '',
        zone: '',
        serial: '',
        definition: '',
        program: '',
        description: '',
        interventionDate: '',
      },
      currentSortedColumn: '',
      sorting: {
        column1: {
          none: true,
          asc: false,
          dsc: false,
        },
      },
    };
  },
  computed: {
    currentSortingPreference() {
      return this.$store.getters.getSortingPreference;
    },
    allSelected() {
      return this.$store.getters.allSelected;
    },
    reports() {
      return this.$store.getters.reports;
    },
  },
  watch: {
    idCol: function () {
      console.log('Change all other columns to nothing');
    },
    allSelected(newVal) {
      // Keep isAllSelected in sync with Vuex state
      this.isAllSelected = newVal;
    },
    reports: {
      deep: true,
      handler(reports) {
        // Check if all reports are selected
        const allSelected = reports.every((report) => report.isSelected);

        // Update the header checkbox based on reports' selection state
        if (allSelected !== this.isAllSelected) {
          this.$store.commit('setAllSelected', allSelected);
        }
      },
    },
  },
  methods: {
    async checkboxClickHandler() {
      const reportUuids = this.$store.getters.allUuids;
      const reports = this.$store.getters.reports;
      // Toggle selection state
      this.isAllSelected = !this.isAllSelected;

      if (this.isAllSelected) {
        // Attempt to select all reports
        try {
          const response = await axiosResource.post(`/selection/temporary/`, {
            reportUuids,
          });
          if (response.data.success) {
            // Dispatch the action to update the selected count in Vuex
            const selectedCount = response.data.selectedCount;
            this.$store.dispatch('updateSelectedCount', selectedCount);

            this.$store.commit('setAllSelected', true);
            reports.forEach((report) => {
              report.isSelected = true;
            });
          }
        } catch (error) {
          console.error('Error selecting all reports:', error);

          // Handle max selection limit error
          if (error.response?.data?.error === 'error.selection.exceeds') {
            const maxSelection = error.response?.data?.maxSelection || 999;
            this.$toast.error(
              this.$t('error.selection.exceeds', {
                maxSelection: maxSelection,
              }),
              {
                position: 'top-center',
                timeout: 5000,
              }
            );
          } else {
            const errorMessage =
              error.response?.data?.message || 'error.unknownError';
            console.log(errorMessage);
            console.log(this.$t(errorMessage));
            this.$toast.error(this.$t(errorMessage), {
              position: 'top-center',
              timeout: 5000,
            });
          }

          // Revert checkbox state on error
          this.isAllSelected = false;
        }
      } else {
        // Attempt to unselect all reports
        try {
          const response = await axiosResource.delete(`/selection/temporary`, {
            data: { reportUuids },
          });
          if (response.data.success) {
            // Dispatch the action to update the selected count in Vuex
            const selectedCount = response.data.selectedCount;
            this.$store.dispatch('updateSelectedCount', selectedCount);

            this.$store.commit('setAllSelected', false);
            reports.forEach((report) => {
              report.isSelected = false;
            });
          }
        } catch (error) {
          console.error('Error unselecting all reports:', error);
          this.isAllSelected = true; // Maintain checkbox state on error

          const errorMessage =
            error.response?.data?.message || 'error.unknownError';
          this.$toast.error(this.$t(errorMessage), {
            position: 'top-center',
            timeout: 5000,
          });
        }
      }
    },

    resetAllSorting() {
      const cols = JSON.parse(JSON.stringify(this.cols));

      for (const [key, value] of Object.entries(cols)) {
        this.cols[key] = '';
      }
    },
    sortAscending(e) {
      this.resetAllSorting();
      const id = e.target.parentElement.parentElement.id;
      this.currentSortedColumn = id;
      this.cols[id] = 'ascending';
      // Set in store and refresh
      this.$store.commit('setSorting', { column: id, order: 'ASC' });
      this.$store.commit('initPage');
      this.$store.commit('initReports', 'TableHeader');
      this.$store.commit('resetQttLoaded');
      this.$store.dispatch('fetchReports');
    },
    sortDescending(e) {
      this.resetAllSorting();
      const id = e.target.parentElement.parentElement.id;
      this.currentSortedColumn = id;
      this.cols[id] = 'descending';
      // Set in store and refresh
      this.$store.commit('setSorting', { column: id, order: 'DESC' });
      this.$store.commit('initPage');
      this.$store.commit('initReports', 'TableHeader');
      this.$store.commit('resetQttLoaded');
      this.$store.dispatch('fetchReports');
    },
  },
};
</script>
<style lang="scss">
// .el-table thead {
//   border-bottom: 1px solid magenta !important;
// }
// .el-table__header {
//   border-bottom: 1px solid black !important;
// }
.table-explore tr {
  height: 56px;
}
.table-explore td {
  vertical-align: middle;
}
.table-explore thead th,
.tabtable-explorele th {
  color: #525f7f;
  border: none !important;
  font-weight: 400;
  padding-top: 0.65rem !important;
  padding-bottom: 0.15rem !important;
}
// .table th,
// .table td {
//   padding-top: 0.05rem !important;
//   padding-bottom: 0.15rem !important;
// }
</style>
<style scoped>
.sort {
  cursor: pointer;
}
.el-table .caret-wrapper {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    height: 34px;
    width: 24px;
    vertical-align: middle;
    cursor: pointer;
    overflow: initial;
    position: relative;
}



/* .sort-caret {
    width: 0;
    height: 0;
    border: 5px solid transparent;
    position: absolute;
    left: 7px;
}
.sort-caret.ascending {
    border-bottom-color: #c0c4cc;
    top: 5px;
}
.ascending .sort-caret.ascending {
    border-bottom-color: #409eff;
}
.el-table .sort-caret.descending {
    border-top-color: #c0c4cc;
    bottom: 7px;
}
.descending .sort-caret.descending {
    border-top-color: #409eff;
} */
i {
    font-style: italic;
}

.header {
      position: sticky;
      top:0;
  }
</style>
<style scoped>
/* General styles for the caret */
.sort-caret {
    width: 0;
    height: 0;
    border: 5px solid transparent;
    position: absolute;
    left: 7px;
    cursor: pointer; /* Ensures the caret looks interactive */
    transition: border-color 0.2s ease; /* Smooth transition for color changes */
}

/* Ascending caret styles */
.sort-caret.ascending {
    border-bottom-color: #c0c4cc; /* Default gray */
    top: 5px;
}

/* Descending caret styles */
.sort-caret.descending {
    border-top-color: #c0c4cc; /* Default gray */
    bottom: 7px;
}

/* Hover effect - turns blue */
.sort-caret.ascending:hover {
    border-bottom-color: #409eff; /* Blue on hover */
}

.sort-caret.descending:hover {
    border-top-color: #409eff; /* Blue on hover */
}

/* Active state - keeps caret blue */
.sort-caret.active.ascending {
    border-bottom-color: #409eff; /* Blue for active ascending */
}

.sort-caret.active.descending {
    border-top-color: #409eff; /* Blue for active descending */
}
</style>
