<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        lg="12"
        xl="8"
        class="mb-5"
      >
        <div
          v-if="mapZoneVisible"
          class="mapzone"
          @click="checkLevelSelected"
        >
          <label>
            <font-awesome-icon
              class="icon"
              icon="fa-solid fa-map-location-dot"
            />
            <span><span class="map-zone-blue">{{ $t('create.text.markLocation') }}</span> {{ $t('create.text.onMap') }}</span>
          </label>
        </div>

        <MapBlock
          ref="mapBlock"
          @openDialog="openDialog"
          v-if="mapBlockVisible"
          :fileName="file"
          :x="marker.percent.x"
          :y="marker.percent.y"
        ></MapBlock>

        <el-alert
          v-if="mlActive"
          type="success"
          :closable="false"
          class="mt-4 mb-5"
        ><i class="el-icon-info"></i> {{ $t('create.text.mlActive') }}
        </el-alert>

        <!-- DIALOG  -->

        <el-dialog
          id="locationMapDialog"
          :title="$t('create.map.markMap') + ' ' + displayLocalRef"
          fullscreen
          :visible.sync="dialogVisible"
          @opened="getDocument"
        >
          <b-row v-loading="loading">
            <b-col
              cols="12"
              class="d-flex justify-content-center"
            >
              <div id="canvas-container">
                <canvas id="pdf-renderer"></canvas>
                <div id="marker-layer"></div>
              </div>

              <div
                id="zoom-container"
                class="d-flex justify-content-between"
              >
                <font-awesome-icon
                  icon="fa-light fa-minus"
                  size="2x"
                  class="zoom-control"
                  @click="zoomOut"
                />
                <span class="zoom-span">ZOOM</span>

                <font-awesome-icon
                  icon="fa-light fa-plus"
                  size="2x"
                  class="zoom-control"
                  @click="zoomIn"
                />
              </div>
            </b-col>
          </b-row>

          <div
            slot="footer"
            class="fixed-bottom mb-4 mx-4"
          >
            <!-- dialog-footer fixed-bottom -->
            <el-checkbox
              v-model="isCenter"
              :checked="isCenter"
              class="float-left"
            >{{ $t('create.text.setAsDefault') }} <el-button
                type="text"
                icon="el-icon-question"
                v-b-modal="'modal-is-center'"
                circle
              ></el-button>
            </el-checkbox>

            <el-button @click.prevent="dialogVisible = false">{{ $t('action.close') }}</el-button>
            <el-button
              type="primary"
              @click.prevent="save"
            >OK</el-button>
          </div>
        </el-dialog>
      </b-col>
    </b-row>
    <modal-is-center></modal-is-center>
  </div>
</template>

<script>
import axiosResource from '@/utils/axiosResource';
import pdfjsLib from 'pdfjs-dist/build/pdf';
import 'pdfjs-dist/web/pdf_viewer.css';
pdfjsLib.GlobalWorkerOptions.workerSrc =
  '../../node_modules/pdfjs-dist/build/pdf.worker.js';
import MapBlock from './MapBlock.vue';
import ModalIsCenter from '../../components/Modals/ModalIsCenter.vue';
import swal from 'sweetalert2';
import { localRefMixin } from '../../mixins/localRefMixin';

const swalBootsrap = swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary btn-swal',
    cancelButton: 'btn btn-light btn-swal',
  },
  buttonsStyling: false,
});

export default {
  name: 'location-map',
  components: {
    MapBlock,
    ModalIsCenter,
  },
  props: {
    mode: {
      type: String,
      required: true,
    },
    report: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  mixins: [localRefMixin],
  data() {
    return {
      file: null,
      drawingKey: null,
      mapZoneVisible: true,
      mapBlockVisible: false,
      dialogVisible: false,
      pdf: null,
      scale: 1,
      loading: true,
      canvas: {
        width: null,
        height: null,
      },
      marker: {
        pixel: {
          x: null,
          y: null,
        },
        percent: {
          x: null,
          y: null,
        },
      },
      intel: {
        x: null,
        y: null,
      },
      isCenter: false,
      mlActive: false,
      zoomCenter: { x: 0, y: 0 },
    };
  },
  mounted() {
    if (this.mode === 'edit') {
      this.initializeFromReport();
    } else {
      this.locationAwareness();
    }
  },
  computed: {
    displayLocalRef() {
      const buildingValue = this.$store.getters.building.label;
      const levelValue = this.$store.getters.level;
      const zoneValue = this.$store.getters.zone;
      const serial = this.$store.getters.serial;
      const definitionValue = this.$store.getters.definition.value;
      return this.localRef(
        buildingValue,
        levelValue,
        zoneValue,
        serial,
        definitionValue
      );
    },
  },
  methods: {
    updateMarkerPosition() {
      const newPosition = {
        x: this.marker.percent.x,
        y: this.marker.percent.y,
      };
      const clgStyle = 'color: blue;font-size: 18px;';
      console.log('%c' + 'updateMarkerPosition', clgStyle);
      console.log(newPosition);
      this.marker.percent = newPosition;
      this.$emit('location-updated'); // Emit event to clear error message
    },
    initializeFromReport() {
      if (this.report.presignedUrlDrawing) {
        this.drawingKey = this.report.drawingKey;
        this.file = this.report.presignedUrlDrawing;
        this.marker.percent.x = this.report.markerX;
        this.marker.percent.y = this.report.markerY;
        this.mapZoneVisible = false;
        this.mapBlockVisible = true;
        setTimeout(() => {
          this.$refs.mapBlock.getDocument();
          this.drawMarkerIcon(50, 50);
        }, 500);
      }
    },
    locationAwareness() {
      const projectUuid = this.$store.getters.currentProject.uuid;
      const buildingName = this.$store.getters.building.label;
      let level, zone, serial;
      this.$store.getters.level != ''
        ? (level = this.$store.getters.level)
        : (level = null);
      this.$store.getters.zone != ''
        ? (zone = this.$store.getters.zone)
        : (zone = null);

      this.$store.getters.serial != ''
        ? (serial = this.$store.getters.serial)
        : (serial = null);

      const definition = this.$store.getters.definition.value;
      // console.log(
      //   `level=${level}, zone=${zone}, serial=${serial}, definition=${definition}`
      // );
      axiosResource
        .get(
          `/ml/locationAwareness/${projectUuid}/${buildingName}/${level}/${zone}/${serial}/${definition}`
        )
        .then((res) => {
          // console.log('locationAwareness()');
          // console.log(res);
          if (res.data.success) {
            this.scale = 2;
            this.getDrawing(true);
            this.dialogVisible = false;
            this.marker.percent.x = res.data.markerX;
            this.marker.percent.y = res.data.markerY;
            // console.log(this.marker.percent.x, this.marker.percent.y);
            this.mlActive = true;
            this.mapZoneVisible = false;
            this.mapBlockVisible = true;
            setTimeout(() => {
              const clgStyle = 'color: red;';
              // console.log('%c' + 'file=' + this.file, clgStyle);
              this.$refs.mapBlock.getDocument();
            }, 500);
          } else {
            this.mlActive = false;
          }
        })
        .catch((err) => {
          this.$globalSwal.error(err.response.data.msg);
        });
    },
    checkLevelSelected() {
      if (this.$store.getters.level !== '') {
        this.getDrawing(false);
      } else {
        swalBootsrap.fire({
          title: this.$t('create.text.noLevelSelected'),
          text: this.$t('create.text.selectLevel'),
          icon: 'info',
          confirmButtonText: 'Ok',
        });
      }
    },
    getDrawing(aware) {
      // const clgStyle = 'color: orange; font-size:24px;';
      // console.log('%c' + 'getDrawing (init)', clgStyle);
      const buildingUuid = this.$store.getters.building.uuid;
      const level = this.$store.getters.level;
      // console.log('buildingUuid:', buildingUuid);
      // Axios call with buildingId and level (2 digits ref)
      axiosResource
        .get(`/admin/buildings/drawing/specific/${buildingUuid}/${level}`)
        .then((res) => {
          this.file = res.data.presignedUrl;
          this.drawingKey = res.data.drawingKey;
          // console.log('%c' + 'getDrawing => drawingKey', clgStyle);
          // console.log('res.data.drawingKey= ', res.data.drawingKey);
          if (!aware) this.dialogVisible = true;
        })
        .catch((err) => {
          this.$globalSwal.error(err.response.data.msg);
        });
      // set path to this.file when done using the presigned url
    },
    openDialog() {
      this.dialogVisible = true;

      // Set the marker position if coordinates exist
      if (this.marker.percent.x != null && this.marker.percent.y != null) {
        this.drawMarkerIcon(
          (this.marker.percent.x / 100) * this.canvas.width,
          (this.marker.percent.y / 100) * this.canvas.height
        );
      }
    },
    save() {
      this.updateMarkerPosition();
      this.dialogVisible = false;
      this.mapZoneVisible = false;
      this.mapBlockVisible = true;
      this.$refs.mapBlock.render();
    },
    async getDocument() {
      const url = this.file;
      var loadingTask = pdfjsLib.getDocument(url);
      loadingTask.promise.then((pdf) => {
        this.pdf = pdf;
        this.render();
      });
    },
    render() {
      const pdf = this.pdf;
      pdf.getPage(1).then((page) => {
        let vm = this;
        let canvas = document.getElementById('pdf-renderer');
        let ctx = canvas.getContext('2d');

        let refViewport = page.getViewport({ scale: 1 });
        const refWidth = refViewport.width;
        const refHeight = refViewport.height;

        // Get max available dimensions
        const parentElement = document.querySelector('#locationMapDialog');
        const dialogFull_map = parentElement.querySelector('.el-dialog');
        const dialogHeader_map =
          parentElement.querySelector('.el-dialog__header');
        const dialogBody_map = parentElement.querySelector('.el-dialog__body');
        const dialogFooter_map =
          parentElement.querySelector('.el-dialog__footer');
        const availableWidth_map = dialogBody_map.clientWidth - 40;
        const availableHeight_map =
          dialogFull_map.clientHeight -
          dialogHeader_map.clientHeight -
          dialogFooter_map.clientHeight -
          100;

        const calculateAspectRatioFit = (pdfW, pdfH, screenW, screenH) => {
          let ratio = Math.min(screenW / pdfW, screenH / pdfH);
          // console.log('RATIO=', ratio);
          // this.scale = ratio;
          return { canvasW: pdfW * ratio, canvasH: pdfH * ratio };
        };
        let { canvasW, canvasH } = calculateAspectRatioFit(
          refWidth,
          refHeight,
          availableWidth_map,
          availableHeight_map
        );
        // console.log(canvasW, canvasH);

        const canvasContainer = document.getElementById('canvas-container');
        canvasContainer.style.width = canvasW;
        canvasContainer.style.height = availableHeight_map + 'px';
        // console.log(`availableHeight_map: ${availableHeight_map}`);

        canvas.addEventListener('mousedown', function (e) {
          vm.getCursorPosition(canvas, e);
        });

        // let newScale = this.scale;
        let viewport = page.getViewport({ scale: this.scale });
        canvas.width = viewport.width;
        canvas.height = viewport.height;
        this.canvas.width = viewport.width;
        this.canvas.height = viewport.height;

        const renderContext = {
          canvasContext: ctx,
          viewport,
        };

        // Render PDF
        page.render(renderContext).promise.then(() => {
          vm.loading = false;
          if (this.marker.percent.x == null) {
            const clgStyle = 'color: red;';
            console.log('%c' + 'marker percent is NULL', clgStyle);
          }
          if (this.mlActive) this.scrollTo();
          this.drawMarkerIcon(
            (this.marker.percent.x / 100) * this.canvas.width,
            (this.marker.percent.y / 100) * this.canvas.height
          );
        });
      });
    },
    getCursorPosition(canvas, event) {
      const rect = canvas.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;
      // console.log(`x: ${x}, y: ${y}`);
      this.marker.pixel.x = x;
      this.marker.pixel.y = y;
      this.marker.percent.x = ((x * 100) / this.canvas.width).toFixed(4);
      this.marker.percent.y = ((y * 100) / this.canvas.height).toFixed(4);
      // console.log(`x: ${this.marker.percent.x}%, y: ${this.marker.percent.y}%`);

      this.zoomCenter = { x, y };
      this.drawMarkerIcon(x, y);
    },
    zoomIn() {
      if (this.scale >= 4) {
        return;
      }
      const container = document.getElementById('canvas-container');
      const oldCenter = {
        x: container.scrollLeft + container.clientWidth / 2,
        y: container.scrollTop + container.clientHeight / 2,
      };
      this.scale *= 2;
      this.render();
      this.clearMarker();
      this.repositionToCenter(oldCenter);
    },
    zoomOut() {
      if (this.scale == 1) {
        return;
      }
      const container = document.getElementById('canvas-container');
      const oldCenter = {
        x: container.scrollLeft + container.clientWidth / 2,
        y: container.scrollTop + container.clientHeight / 2,
      };
      this.scale /= 2;
      this.render();
      this.clearMarker();
      this.repositionToCenter(oldCenter);
    },
    clearMarker() {
      const markerLayer = document.getElementById('marker-layer');
      markerLayer.innerHTML = '';
    },
    repositionToCenter(oldCenter) {
      const container = document.getElementById('canvas-container');
      const newCenter = {
        x: oldCenter.x * (this.scale / (this.scale === 1 ? 2 : this.scale / 2)),
        y: oldCenter.y * (this.scale / (this.scale === 1 ? 2 : this.scale / 2)),
      };
      container.scrollTo({
        top: newCenter.y - container.clientHeight / 2,
        left: newCenter.x - container.clientWidth / 2,
        behavior: 'smooth',
      });
    },
    drawMarkerIcon(x, y) {
      // const clgStyle = 'color: magenta;font-size: 14px;';
      // console.log('%c' + 'drawMarkerIcon ' + `x:${x}, y:${y}`, clgStyle);

      // Adjust for marker icon dimensions
      x = x - 15;
      y = y - 39.9;

      const markerLayer = document.getElementById('marker-layer');
      if (!markerLayer) {
        console.error('Marker layer not found in DOM.');
        return;
      }

      markerLayer.innerHTML = ''; // Clear previous marker
      const marker = document.createElement('img');
      marker.src = '/assets/marker.png';
      marker.style.position = 'absolute';
      marker.style.left = `${x}px`;
      marker.style.top = `${y}px`;
      markerLayer.appendChild(marker);
    },
    scrollTo() {
      // const clgStyle = 'color: blue;';
      // console.log('%c' + 'scrollTo() fired', clgStyle);
      const container = document.getElementById('canvas-container');
      const containerPdf = document.getElementById('pdf-renderer');

      const scrollToX =
        (this.marker.percent.x / 100) * containerPdf.clientWidth;
      const scrollToY =
        (this.marker.percent.y / 100) * containerPdf.clientHeight;

      container.scrollTo({
        top: scrollToY - 400,
        left: scrollToX - 300,
        behavior: 'smooth',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#canvas-container {
  overflow: scroll !important;
  position: relative;
}
#pdf-renderer {
  position: relative;
}
#marker-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
#zoom-container {
  position: fixed;
  margin: auto;
  bottom: 80px;
  border-radius: 30px;
  background-color: rgba(28, 28, 30, 0.7);
  padding: 20px;
  width: 180px;
  height: 60px;
}
.zoom-control {
  color: #fff;
  cursor: pointer;
}
.mapzone {
  width: 100%;
  height: 180px;
  row-gap: 8px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  background-color: #fff;
  transition: 0.3s ease all;

  label {
    display: block;
    width: 100%;
    height: 180px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .icon {
    font-size: 56px;
    color: #c0c4cc;
    margin: 0px 0 16px;
    line-height: 50px;
  }
}
.mapzone:hover {
  border-color: #409eff;
}
.active-mapzone {
  background-color: rgba(32, 159, 255, 0.06);
  border: 2px dashed #409eff;

  i {
    margin: 0px 0 16px;
  }
}
.map-zone-blue {
  color: #409eff;
}
.zoom-span {
  color: #fff;
  font-weight: lighter;
  padding-top: 2px;
}
</style>
