<template>
  <div
    id="mapContainer"
    class="row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-1 row-cols-xl-1 ml-md-4 my-4 pr-md-5"
  >
    <!-- <el-button
      type="warning"
      @click="render"
    ></el-button> -->
    <b-row v-show="instructionVisible">
      <b-col
        cols="12"
        class=" 
          d-flex
          justify-content-center mx-0"
      >
        <div class="text-center">
          <font-awesome-icon
            icon="fa-light fa-map"
            size="6x"
            class="my-5"
          />
          <br>
          <span class="text-noMap text-center">{{ $t('explore.map.instruction') }}</span>
          <br><small>{{ $t('explore.map.hint') }}</small>
          <!-- v-if="((buildingId == null && level == null) || (buildingId != null && level == null)) || ((buildingId == '' && level == '') || (buildingId != '' && level == ''))" -->
        </div>
      </b-col>
    </b-row>

    <b-row
      v-loading="loadingMap"
      class="min-height-200"
    >

      <b-col
        cols="12"
        class="
          d-flex
          justify-content-center pr-0 mr-0"
      >

        <div
          v-show="mapVisible"
          id="canvas-container"
          class="map-canvas-container"
        >
          <div v-show="tolltipVisible">
            <div
              id="tooltipContainer"
              class="tooltip-container"
            >
              <ArticleDetailed
                :report="reportToShow"
                v-if="reportToShow.interventionType != 'QUICK'"
              />
              <ArticleQuick
                :report="reportToShow"
                v-if="reportToShow.interventionType == 'QUICK'"
              />
            </div>
          </div>

          <canvas
            ref="pdfRenderer"
            id="pdf-renderer"
          >
          </canvas>

        </div>

        <div
          v-if="zoomVisible"
          id="zoom-container"
          class="d-flex justify-content-between"
        >
          <font-awesome-icon
            icon="fa-light fa-minus"
            size="2x"
            class="zoom-control"
            @click="zoomOut"
          />
          <span class="zoom-span">ZOOM</span>

          <font-awesome-icon
            icon="fa-light fa-plus"
            size="2x"
            class="zoom-control"
            @click="zoomIn"
          />
        </div>

      </b-col>
    </b-row>
    <!-- <b-row>
      <b-col>
        <canvas
          id="canvach"
          width="50"
          height="50"
        ></canvas>
      </b-col>
    </b-row> -->
    <modal-history ref="modalHistory"></modal-history>
  </div>
</template>
<script>
import axiosResource from '@/utils/axiosResource';
import pdfjsLib from 'pdfjs-dist/build/pdf';
import 'pdfjs-dist/web/pdf_viewer.css';
pdfjsLib.GlobalWorkerOptions.workerSrc =
  '../../node_modules/pdfjs-dist/build/pdf.worker.js';
import ArticleDetailed from './CardComponents/ArticleDetailed.vue';
import ArticleQuick from './CardComponents/ArticleQuick.vue';
import ModalHistory from '../../components/Modals/ModalHistory.vue';

import { selectionMixin } from '@/mixins/selectionMixin';

export default {
  name: 'map-view',
  mixins: [selectionMixin],
  components: {
    ArticleDetailed,
    ArticleQuick,
    ModalHistory,
  },
  data() {
    return {
      tolltipVisible: false,
      reportToShow: [],
      oldCursorX: 0,
      oldCursorY: 0,
      avX: 0,
      avY: 0,
      totalDistance: 0,
      lastKnownScrollPosition: 0,
      ticking: false,
      instructionVisible: true,
      mapVisible: false,
      zoomVisible: false,
      buildingUuid: this.$store.getters.building.uuid,
      level: this.$store.getters.level,
      selectedReports: this.$store.getters.selectedReports,
      drawgingPresignedURL: null,
      drawingKey: null,
      scale: 1,
      canvas: {
        width: null,
        height: null,
      },
      loadingMap: true,
    };
  },
  computed: {
    checkBuildingUuid: function () {
      return this.$store.getters.building.uuid;
    },
    checkLevel: function () {
      return this.$store.getters.level;
    },
    filterChanged: function () {
      return this.$store.getters.filterChanged;
    },
  },
  watch: {
    filterChanged: async function () {
      const readyToRender = this.readyToRender();
      if (readyToRender) {
        this.getDrawing();
      }
    },
    checkBuildingUuid: async function (val) {
      this.loadingMap = true;
      this.mapVisible = false;
      this.buildingUuid = val;
      // const readyToRender = this.readyToRender();
      // if (readyToRender) {
      //   this.getDrawing();
      // }
    },
    checkLevel: async function (val) {
      this.loadingMap = true;
      this.mapVisible = false;
      this.loadingMap = true;
      this.level = val;
      const readyToRender = this.readyToRender();
      // if (readyToRender) {
      //   this.getDrawing();
      // }
    },
  },
  created() {
    document.addEventListener('keydown', this.handleKeydown);
  },
  destroyed() {
    document.removeEventListener('keydown', this.handleKeydown);
  },
  mounted() {
    this.$store.commit('loading', false); // DEBUG only otherwise the loader keeps on spinning for some reasons
    this.exitSelection(); // Exit selection
    let vm = this;

    // Close tooltip when cursor leaves tooltip
    // const tooltipContainer = document.getElementById('tooltipContainer');
    // tooltipContainer.addEventListener('mouseleave', () => {
    //   vm.tolltipVisible = false;
    // });
    const readyToRender = this.readyToRender();
    if (readyToRender) {
      this.getDrawing();
    } else {
      this.loadingMap = false;
      this.zoomVisible = false;
    }
    const clgStyle = 'color: red;';
    console.log('%c' + 'reportToShow', clgStyle);
    console.log(this.reportToShow);
  },
  methods: {
    handleKeydown(evt) {
      if (evt.key === 'Escape') {
        this.tooltipVisible = false;
      }
    },
    readyToRender() {
      this.mapVisible = false;
      const buildingUuid = this.buildingUuid;
      const level = this.level;

      if (
        (!buildingUuid && !level) ||
        (buildingUuid && !level) ||
        (!buildingUuid && level)
      ) {
        this.instructionVisible = true;
        this.loadingMap = false;
        return false;
      } else {
        this.instructionVisible = false;
        return true;
      }
    },
    getDrawing() {
      const buildingUuid = this.$store.getters.building.uuid;
      const level = this.$store.getters.level;

      // Axios call with buildingUuid and level (2 digits ref)
      axiosResource
        .get(`/admin/buildings/drawing/specific/${buildingUuid}/${level}`)
        .then((res) => {
          this.drawgingPresignedURL = res.data.presignedUrl;
          this.drawingKey = res.data.drawingKey;
          this.getDocument();
        })
        .catch((err) => {
          this.loadingMap = false;
          this.instructionVisible = true;
          this.mapVisible = false;
          this.zoomVisible = false;
          this.$globalSwal.error(err.response.data.msg);
        });
    },
    async getDocument() {
      const url = this.drawgingPresignedURL;
      var loadingTask = pdfjsLib.getDocument(url);
      loadingTask.promise.then((pdf) => {
        this.pdf = pdf;
        this.render();
      });
    },
    render() {
      const pdf = this.pdf;
      pdf.getPage(1).then((page) => {
        let vm = this;
        let canvas = document.getElementById('pdf-renderer');
        let ctx = canvas.getContext('2d');

        // let refViewport = page.getViewport({ scale: 1 });
        // const refWidth = refViewport.width;
        // const refHeight = refViewport.height;

        // Get max available dimensions
        // const parentElement = document.getElementById('mapContainer');

        // const availableWidth_map = parentElement.offsetWidth;
        // const availableHeight_map = parentElement.offsetHeight;
        // const clgStyle = 'color: red;';
        // console.log(
        //   '%c' + 'w:' + availableWidth_map + ' h=' + availableHeight_map,
        //   clgStyle,
        // );

        // const calculateAspectRatioFit = (pdfW, pdfH, screenW, screenH) => {
        //   let ratio = Math.min(screenW / pdfW, screenH / pdfH);
        //   console.log('RATIO=', ratio);
        //   // this.scale = ratio;
        //   return { canvasW: pdfW * ratio, canvasH: pdfH * ratio };
        // };
        // let { canvasW, canvasH } = calculateAspectRatioFit(
        //   refWidth,
        //   refHeight,
        //   availableWidth_map,
        //   availableHeight_map,
        // );
        // console.log(canvasW, canvasH);

        // const canvasContainer = document.getElementById('canvas-container');
        // canvasContainer.style.width = canvasW;
        // canvasContainer.style.height = availableHeight_map + 'px';
        // console.log(`availableHeight_map: ${availableHeight_map}`);

        // canvas.addEventListener('mousedown', function (e) {
        //   vm.getCursorPosition(canvas, e);
        // });

        // let newScale = this.scale;
        let viewport = page.getViewport({ scale: this.scale });
        canvas.width = viewport.width;
        canvas.height = viewport.height;
        this.canvas.width = viewport.width;
        this.canvas.height = viewport.height;

        const renderContext = {
          canvasContext: ctx,
          viewport,
        };

        // Render PDF
        page
          .render(renderContext)
          .then(() => {
            vm.mapVisible = true;
            vm.loadingMap = false;
            vm.zoomVisible = true;
            const reports = this.$store.getters.reports;
            vm.canvas.width;

            let i;
            for (i = 0; i < reports.length; i++) {
              this.avX += reports[i].markerX;
              this.avY += reports[i].markerY;
              // const x = (reports[i].markerX / 100) * vm.canvas.width;
              // const y = (reports[i].markerY / 100) * vm.canvas.height;
              vm.drawMarker(reports[i]);
            }

            vm.avX = vm.avX / reports.length;
            vm.avY = vm.avY / reports.length;
            console.log(`vm.avX = ${vm.avX}`, `vm.avY=${vm.avY}`);
            vm.scrollTo();
          })
          .then(function () {
            vm.scrollTo();
          });
      });
    },
    scrollTo() {
      const container = document.getElementById('canvas-container');
      const containerPdf = document.getElementById('pdf-renderer');

      const scrollToX = (this.avX / 100) * containerPdf.clientWidth;
      const scrollToY = (this.avY / 100) * containerPdf.clientHeight;

      // console.log(`containerPdf.clientWidth= ${containerPdf.clientWidth}`);
      // console.log(`containerPdf.clientHeight= ${containerPdf.clientHeight}`);
      // console.log(`scrollToX=${scrollToX} // scrollToY=${scrollToY}`);

      container.scrollTo({
        top: scrollToY - 400,
        left: scrollToX - 300,
        behavior: 'smooth',
      });
      // container.scrollTo(scrollToX, scrollToY, 'instant');
    },
    zoomIn() {
      if (this.scale >= 4) {
        return;
      }
      this.scale *= 2;
      this.render();
    },
    zoomOut() {
      if (this.scale == 1) {
        return;
      }
      this.scale /= 2;
      this.render();
    },

    drawMarker(report) {
      let canvas = document.getElementById('pdf-renderer');
      let ctx = canvas.getContext('2d');

      let _outside = this;

      class detailedMarker {
        constructor(x, y, radius, color, report) {
          this.xpoint = x;
          this.ypoint = y;
          this.radius = radius;
          this.interventionType = report.interventionType;
          this.quickInteraction = report.quickInteraction;
          this.priority = report.priority;
          this.report = report;
          this.color = color;
        }

        drawIcon(context) {
          // this.xpoint -= 15;
          // this.ypoint -= 39.9;
          // Clear previous marker
          // ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
          // Draw marker

          if (this.interventionType == 'QUICK') {
            // Define color upon quick type
            const quick = this.quickInteraction.split('.')[0];
            switch (quick) {
              case 'like':
                this.color = '#007BFF';
                break;
              case 'visited':
                this.color = '#343A40';
                break;
              case 'locked':
                this.color = '#EC407A';
                break;
              case 'busy':
                this.color = '#42906B';
                break;
              default:
                break;
            }
            const xIcon = this.xpoint - 8;
            const yIcon = this.ypoint - 8;
            var img = new Image();
            img.onload = function () {
              context.drawImage(img, xIcon, yIcon, 16, 16);
            };
            const quickInteraction = this.quickInteraction.split('.');
            img.src = `/assets/marker-${quickInteraction[0]}-${quickInteraction[1]}.png`;
            // Draw circle
            context.beginPath();
            context.arc(
              this.xpoint,
              this.ypoint,
              this.radius,
              0,
              Math.PI * 2,
              false
            );
            context.strokeStyle = this.color;
            context.lineWidth = 2;
            context.stroke();
            /// Check if active or inactive interaction
            if (this.quickInteraction.split('.')[1] == 'active') {
              context.fillStyle = this.color;
              context.fill();
            } else {
              context.fillStyle = 'white';
              context.fill();
            }

            context.closePath();
          } else {
            // Define color
            switch (this.priority) {
              case 'INFO':
                this.color = 'gray';
                break;
              case 'LONGTERM':
                this.color = 'blue';
                break;
              case 'MEDIUMTERM':
                this.color = 'yellow';
                break;
              case 'SHORTTERM':
                this.color = 'orange';
                break;
              case 'URGENT':
                this.color = 'red';
                break;
              default:
                break;
            }
            // Draw circle
            context.beginPath();
            context.arc(
              this.xpoint,
              this.ypoint,
              this.radius,
              0,
              Math.PI * 2,
              false
            );
            context.strokeStyle = this.color;
            context.lineWidth = 1;
            context.stroke();
            context.fillStyle = this.color;
            context.fill();
            context.closePath();
          }
        }
        clickMarker(xmouse, ymouse) {
          // const pdfContainer = document.getElementById('pdf-renderer');
          // var rect = pdfContainer.getBoundingClientRect();
          // console.log(rect.top, rect.right, rect.bottom, rect.left);
          // console.log(xmouse, this.xpoint);
          const distance = Math.sqrt(
            (xmouse - this.xpoint) * (xmouse - this.xpoint) +
              (ymouse - this.ypoint) * (ymouse - this.ypoint)
          );
          if (distance < this.radius) {
            // Set tooltip position
            // Get current cursor position

            document.getElementById(
              'tooltipContainer'
            ).style.top = `${this.ypoint}px`;
            document.getElementById(
              'tooltipContainer'
            ).style.left = `${this.xpoint}px`;
            _outside.reportToShow = this.report;
            const clgStyle = 'color: red;';
            console.log('%c' + 'REPORT TO PAS AS reportToShow', clgStyle);
            console.log(this.report);
            // Show tooltip
            _outside.tolltipVisible = true;
            // const message = this.$t('explore.map.escape');
            // this.$message('This is a message.');
            console.log('Open report tolltip');
            _outside.$message(_outside.$tc('explore.map.escape'));
            return true;
          } else {
            console.log('HIDE tooltip');
            return false;
          }
        }
        highlight() {}
        changeColor(newColor) {
          this.color = newColor;
          this.drawIcon(ctx);
        }
      }

      const iconX = (report.markerX / 100) * this.canvas.width;
      const iconY = (report.markerY / 100) * this.canvas.height;
      let icon = new detailedMarker(iconX, iconY, 16, '#007BFF', report);

      icon.drawIcon(ctx);

      let _this = this;
      canvas.addEventListener('click', (event) => {
        const rect = canvas.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;
        _this.oldCursorX = event.clientX;
        _this.oldCursorY = event.clientY;
        _this.totalDistance = 0;
        icon.clickMarker(x, y);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
#canvas-container {
  border: 1px solid #e5e8eb;
  margin-left: 6px;
  padding: 4px;
}
#zoom-container {
  position: fixed;
  margin: auto;
  bottom: 80px;
  border-radius: 30px;
  background-color: rgba(28, 28, 30, 0.7);
  padding: 20px;
  width: 180px;
  height: 60px;
}
.zoom-control {
  color: #fff;
  cursor: pointer;
}
.text-noMap {
  font-size: 24px;
  font-weight: 400;
  color: #6c757d;
}

.tooltip-container {
  z-index: 1;
  min-width: 300px;
  position: absolute;
  top: 200px;
  left: 200px;
  // height: 200px;
  // background-color: blue;
}
.map-canvas-container {
  overflow: scroll !important;
  position: relative;
  height: 800px;
}
.zoom-span {
  color: #fff;
  font-weight: lighter;
  padding-top: 2px;
}
</style>