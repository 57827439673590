<template>
  <b-modal
    id="modal-address"
    :title="$t('modal.address.title')"
    button-size="sm"
    @hide="clearFields"
  >
    <!-- Warning Alert for multiple reports, but not exceeding 100 -->
    <b-alert
      variant="warning"
      v-if="!this.$store.getters.reportUuid && this.$store.getters.selectedCount > 1 && this.$store.getters.selectedCount <= 100"
      :show="true"
    >
      {{ $t('modal.address.multipleReports', { count: this.$store.getters.selectedCount }) }}
    </b-alert>

    <!-- Danger Alert for too many selected reports (over 100) -->
    <b-alert
      variant="danger"
      v-if="!this.$store.getters.reportUuid && this.$store.getters.selectedCount > 100"
      :show="true"
    >
      {{ $t('modal.address.tooManyReports', { count: 100 }) }}
    </b-alert>

    <div v-if="this.$store.getters.selectedCount <= 100">
      <p>{{ $t('modal.address.selectEntity') }}</p>
      <form ref="form">
        <b-form-group class="mb-4">
          <el-select
            v-model="form.entityUuid"
            @blur="$v.form.entityUuid.$touch"
            filterable
            clearable=""
            :placeholder="$t('filter.select')"
            class="w-100"
            @change="fetchUsersInEntity"
          >
            <el-option
              v-for="item in entitiesOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <label
            class="error"
            v-if="!$v.form.entityUuid.required && $v.form.entityUuid.$dirty"
          >{{ $t('validate.required') }}
          </label>
        </b-form-group>

        <p
          class="mt-3 mb-4"
          v-if="form.entityUuid"
        >
          <el-switch v-model="form.byEmail">
          </el-switch> {{ $t('modal.address.byEmail') }}
        </p>

        <p v-if="form.byEmail">{{ $t('modal.address.selectUser') }}</p>

        <b-form-group
          class="mb-4"
          v-if="form.byEmail"
        >
          <el-select
            v-model="form.toUserUuid"
            @blur="$v.form.toUserUuid.$touch"
            filterable
            clearable=""
            :placeholder="$t('filter.select')"
            class="w-100"
          >
            <el-option
              v-for="item in usersOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <label
            class="error"
            v-if="!$v.form.toUserUuid.required && $v.form.toUserUuid.$dirty"
          >{{ $t('validate.required') }}
          </label>
        </b-form-group>

        <el-input
          type="textarea"
          class="mb-3"
          :rows="2"
          :placeholder="$t('modal.address.customInstruction')"
          v-model="form.instruction"
          v-if="form.byEmail"
          :autosize="{ minRows: 3, maxRows: 4}"
          maxlength="100"
          show-word-limit
        ></el-input>
      </form>
    </div>
    <template #modal-footer="{ hide }">
      <b-button
        size="sm"
        variant="primary"
        @click="address()"
        :disabled="!$store.getters.reportUuid && $store.getters.selectedCount > 100"
      >
        {{ $t('action.confirm') }}
      </b-button>
      <b-button
        size="sm"
        variant="light"
        @click="hide()"
      >
        {{ $t('action.close') }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import axiosResource from '@/utils/axiosResource';
import swal from 'sweetalert2';
import { required, requiredIf } from 'vuelidate/lib/validators';
const swalBootstrap = swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary btn-swal',
    cancelButton: 'btn btn-light btn-swal',
  },
  buttonsStyling: false,
});

export default {
  name: 'modal-address',
  data() {
    return {
      usersOptions: [],
      entitiesOptions: [],
      form: {
        entityUuid: '',
        toUserUuid: '',
        instruction: '',
        byEmail: false,
      },
      isSubmitting: false,
    };
  },
  validations: {
    form: {
      entityUuid: { required },
      toUserUuid: {
        required: requiredIf(function () {
          return this.form.byEmail;
        }),
      },
    },
  },
  mounted() {
    this.isSubmitting = false;
    this.fetchEntities();
  },
  watch: {
    'form.byEmail': function (newValue) {
      // Clear specific form fields when byEmail is changed
      this.form.toUserUuid = '';
      this.form.instruction = '';

      // Reset validation state
      this.$nextTick(() => {
        this.$v.form.$reset();
      });
    },
  },
  computed: {
    reportUuid() {
      return this.$store.getters.reportUuid; // Access `reportUuid` from Vuex
    },
  },
  methods: {
    clearFields() {
      this.form.toUserUuid = '';
      this.form.instruction = '';
      this.form.byEmail = false;
      this.form.entityUuid = '';
      this.$v.form.$reset();
    },
    address() {
      this.$v.form.$touch();

      if (this.$v.form.$invalid) {
        this.submitStatus = 'ERROR';
        this.isSubmitting = false;
      } else {
        this.$store.commit('loading', true);
        this.isSubmitting = true; // Lock UI
        const reportUuid = this.$store.getters.reportUuid;
        axiosResource
          .post(`/reports/address`, {
            data: { form: this.form },
            reportUuid: reportUuid,
          })
          .then((response) => {
            this.isSubmitting = false;
            this.$bvModal.hide('modal-address');
            this.$store.commit('loading', false);
            if (response.data.success) {
              // Close modal on success
              this.$emit('close');
              this.$store.commit('clearReportUuid'); // Clear reportUuid after address action

              this.$globalSwal.successSimple('success.done');
              this.$store.dispatch('changeStatus', {
                status: 'ADDRESSED',
                reportUuids: response.data.reportUuids,
              });
            } else if (response.data.failedReportRefs) {
              // Handle partial failure with specific failed report references
              const failedReports = response.data.failedReportRefs.join(', ');
              swalBootstrap.fire({
                title: this.$t('error.addressPartialFailure'),
                text: this.$t('error.updateSomeReports', {
                  num: response.data.failedReportRefs.length,
                  refs: failedReports,
                }),
                icon: 'warning',
              });
            }
          })
          .catch((error) => {
            this.isSubmitting = false;
            this.$store.commit('loading', false);

            // Close the modal in case of error
            this.$bvModal.hide('modal-address');
            console.log(error);

            if (error.response.data.message == 'error.report.containsQuick') {
              swalBootstrap.fire({
                title: this.$t('error.sorry'),
                text: this.$t('error.report.containsQuick'),
                icon: 'error',
              });
            } else {
              swalBootstrap.fire({
                title: this.$t('error.sorry'),
                text:
                  this.$t(error.response?.data?.error, {
                    num: error.response?.data?.maxSelection,
                  }) || this.$t('error.unknown'),
                icon: 'error',
              });
            }
          });

        this.submitStatus = 'PENDING';
        setTimeout(() => {
          this.submitStatus = 'OK';
        }, 500);
      }
    },
    fetchEntities() {
      axiosResource
        .get('/admin/entities/accessLess')
        .then((response) => {
          const entitiesObj = response.data;
          entitiesObj.forEach((element) => {
            this.entitiesOptions.push({
              value: `${element.entity.uuid}`,
              label: `${element.entity.name}`,
            });
          });
        })
        .catch((err) => {
          this.loading = false;
          this.$globalSwal.error(err.response.data.msg);
        });
    },
    fetchUsersInEntity() {
      if (!this.form.entityUuid) {
        this.usersOptions = []; // Clear users if no entity is selected
        return;
      }
      axiosResource
        .get(`/admin/entities/${this.form.entityUuid}/users`)
        .then((response) => {
          this.usersOptions = []; // Clear previous users before setting new ones
          const usersObj = response.data.results; // assuming the API returns a 'results' array

          usersObj.forEach((element) => {
            let label;
            if (element.status !== 'ACTIVE') {
              label = element.name.acronym4digits;
            } else {
              label = element.name.complete;
            }
            this.usersOptions.push({
              value: `${element.uuid}`,
              label: label,
            });
          });
        })
        .catch((err) => {
          this.usersOptions = []; // Also clear users on error to avoid displaying incorrect data
          console.error('Error fetching users:', err);
          this.$globalSwal.error(
            'Failed to fetch users',
            err.response.data.msg
          );
        });
    },
    fetchUsers() {
      axiosResource
        .get('/admin/users/accessLess')
        .then((response) => {
          const usersObj = response.data;
          usersObj.forEach((element) => {
            this.usersOptions.push({
              value: `${element.uuid}_${element.username}`,
              label: `${element.name.complete}`,
            });
          });
        })
        .catch((err) => {
          this.loading = false;
          this.$globalSwal.error(err.response.data.msg);
        });
    },
  },
};
</script>