<template>
  <div
    class="el-table"
    id="infiniteScrollTable"
  >

    <table class="table table-hover table-explore mb-0">
      <table-header class="explore-table-header"></table-header>
      <tbody class="explore-table-body">

        <tr
          v-if="$store.getters.qtt == 0"
          height="50px"
        >
          <td colspan="19">
            <div class="noMatch-table">{{ $tc('explore.noMatch') }}</div>
          </td>
        </tr>
        <template v-for="report in sourceData">
          <TableRow
            v-if="renderRow(report.uuid)"
            :report="report"
            :toggleExpanded="toggleExpanded"
            v-bind:key="report.uuid"
          />
          <tr
            v-bind:key="report.uuid+'-details'"
            :id="'detailsRow-'+report.uuid"
            v-if="expanded.includes(report.uuid) && report.interventionType != 'QUICK'"
          >
            <td colspan="18">
              <DetailedRow :report="report" />
            </td>
          </tr>
        </template>

      </tbody>
    </table>
    <div class="safety-margin"></div>
    <modal-view-photo ref="modalViewPhoto"></modal-view-photo>
  </div>
</template>
<script>
import TableHeader from './TableComponents/TableHeader.vue';

// Static Import (eager loading)
// import TableRow from './TableComponents/TableRow.vue';
// import DetailedRow from './TableComponents/DetailedRow.vue';

// Lazy Load these components instead
const TableRow = () => import('./TableComponents/TableRow.vue');
const DetailedRow = () => import('./TableComponents/DetailedRow.vue');

import ModalViewPhoto from '../Modals/ModalViewPhoto.vue';
import debounce from 'lodash/debounce';

import { selectionMixin } from '@/mixins/selectionMixin';

export default {
  name: 'table-view',
  mixins: [selectionMixin],
  components: {
    TableHeader,
    TableRow,
    DetailedRow,
    ModalViewPhoto,
  },
  data() {
    return {
      detailsVisible: true,
      selectedReports: this.$store.getters.selectedReports,
      qttLoaded: 0,
      expanded: [],
      isFetching: false,
    };
  },
  beforeDestroy() {
    this.$store.commit('SET_FETCHING', false);
  },
  watch: {
    selectedReports: {
      handler(newVal) {
        // This can force updates if there are issues with reactivity
        this.$forceUpdate(); // You might need to define this method to force re-render if needed
      },
      deep: true,
      immediate: true,
    },
    // '$store.getters.selectionMode': {
    //   handler(newVal, oldVal) {
    //     if (newVal !== oldVal) {
    //       this.$forceUpdate(); // Similarly, ensure reactivity by forcing update
    //     }
    //   },
    //   immediate: true,
    // },
    // selectedReports: {
    //   handler() {
    //     this.$store.commit('setSelection', this.selectedReports);
    //   },
    //   deep: true,
    // },
  },
  computed: {
    sourceData() {
      return this.$store.getters.reports;
    },
    // sourceData() {
    //   return this.$store.getters.selectionMode
    //     ? this.$store.getters.selectedReportsData
    //     : this.$store.getters.reports;
    // },
  },
  methods: {
    renderRow(reportUuid) {
      return true;
      // return this.$store.getters.selectionMode
      //   ? this.$store.getters.selectedReports.includes(reportUuid)
      //   : true;
    },
    toggleExpanded(uuid) {
      const index = this.expanded.indexOf(uuid);
      if (index > -1) {
        this.expanded.splice(index, 1);
      } else {
        this.expanded.push(uuid);
      }
    },
    handleScroll() {
      const infiniteScrollTable = document.getElementById(
        'infiniteScrollTable'
      );
      infiniteScrollTable.onscroll = debounce(() => {
        const { scrollTop } = infiniteScrollTable;
        if (
          scrollTop + infiniteScrollTable.clientHeight >
          infiniteScrollTable.scrollHeight - 10
        ) {
          this.append();
        }
      }, 100); // Adjust the debounce time as necessary
      // }
    },
    async append() {
      if (this.$store.getters.isFetching) return;

      // this.$store.commit('SET_FETCHING', true);

      const qtt = this.$store.getters.qtt;
      const qttLoaded = this.$store.getters.qttLoaded;
      const selectionMode = this.$store.getters.selectionMode;
      if (qttLoaded < qtt) {
        this.$store.commit('incrementPage');
        // If in selection mode, fetch from selection
        if (this.$store.getters.selectionMode) {
          // this.$store.dispatch('fetchReportsFromSelection');
          await this.$store.dispatch('fetchReportsGeneric', true);
        } else {
          // this.$store.dispatch('fetchReports');
          await this.$store.dispatch('fetchReportsGeneric');
        }
      } else {
        this.$store.commit('SET_FETCHING', false); // Reset flag if no more data to fetch
      }
    },
  },
  async mounted() {
    this.$store.commit('setSelectionMode', false); // Exit selection mode
    this.$store.commit('loading', true);
    this.handleScroll();
    this.$store.commit('initPage');
    this.$store.commit('initReports');
    this.$store.commit('resetQttLoaded');
    try {
      await this.$store.dispatch('fetchReportsGeneric');
    } catch (error) {
      console.error('Error fetching reports:', error);
    }
  },
};
</script>
<style  lang="scss">
.el-table {
  border-left: 1px solid rgb(229, 232, 235);
  // width: calc(100vh + 340px);
  height: calc(100vh - 108px);
  overflow: scroll;
  padding-left: 12px;
}
.sort {
  cursor: pointer;
}
.blue {
  color: #409eff;
}
.table-hover tbody tr:hover td {
  background-color: #f6f9fc;
}
.tooltip-inner {
  text-align: left;
}
.description-cell {
  width: 360px;
  max-width: 360px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}
.description-cell::first-letter {
  text-transform: capitalize;
}
.noMatch-table {
  margin-left: 20px;
  font-size: 14px;
  font-weight: 500;
  position: relative;
  left: calc(100vw / 3) !important;
}

.table-explore td,
.table-explore th {
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: rgba(0, 0, 0, 0.1);
}
.table-explore tbody {
  // height: 300px;
  overflow-y: auto;
}

.table-explore thead > tr > th {
  // border-bottom: 1px solid red !important;
}
</style>
<style scoped>
.table {
  border-collapse: collapse !important;
}

.table thead th {
  border-top: none !important;
  border-bottom: 1px solid magenta !important;
  box-shadow: inset 0 2px 0 #000000, inset 0 -2px 0 #000000;
  padding: 2px 0;
}
</style>
