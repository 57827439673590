// selectionMixin.js
import axiosResource from '@/utils/axiosResource';

export const selectionMixin = {
  data() {
    return {
      isAllSelected: this.$store.getters.allSelected,
      selected: this.report?.isSelected || false, // Initialize with report's selected state
      checkboxVisible: this.report?.isSelected || false, // Show checkbox if selected
    };
  },
  watch: {
    isAllSelected(newVal) {
      // Sync with Vuex state
      this.$store.commit('setAllSelected', newVal);
    },
    reports: {
      deep: true,
      handler(reports) {
        // Check if all reports are selected
        const allSelected = reports.every((report) => report.isSelected);

        // Update header checkbox state
        if (allSelected !== this.isAllSelected) {
          this.isAllSelected = allSelected;
          this.$store.commit('setAllSelected', allSelected);
        }
      },
    },
  },
  computed: {
    reports() {
      return this.$store.getters.reports;
    },
  },
  methods: {
    async toggleAllSelection() {
      const reportUuids = this.$store.getters.allUuids;
      const reports = this.$store.getters.reports;

      this.isAllSelected = !this.isAllSelected;

      if (this.isAllSelected) {
        try {
          const response = await axiosResource.post(`/selection/temporary/`, {
            reportUuids,
          });
          if (response.data.success) {
            const selectedCount = response.data.selectedCount;
            this.$store.dispatch('updateSelectedCount', selectedCount);
            this.$store.commit('setAllSelected', true);

            reports.forEach((report) => {
              report.isSelected = true;
            });
          }
        } catch (error) {
          this.handleSelectionError(error, false);
          this.isAllSelected = false; // Revert on error
        }
      } else {
        try {
          const response = await axiosResource.delete(`/selection/temporary`, {
            data: { reportUuids },
          });
          if (response.data.success) {
            const selectedCount = response.data.selectedCount;
            this.$store.dispatch('updateSelectedCount', selectedCount);
            this.$store.commit('setAllSelected', false);

            reports.forEach((report) => {
              report.isSelected = false;
            });
          }
        } catch (error) {
          this.handleSelectionError(error, true);
          this.isAllSelected = true; // Revert on error
        }
      }
    },
    async handleSelectionCommand(command) {
      const clgStyle = 'color: white;background-color: gray;';
      console.log('%c' + 'handleSelectionCommand: ' + command, clgStyle);

      if (command == 'emptySelection') {
        await this.emptySelection();
      } else if (command == 'showSelection') {
        this.showSelection();
      } else if (command == 'exitSelection') {
        this.exitSelection();
      }
    },
    async checkboxChange() {
      // Toggle `isSelected` for the individual report
      this.report.isSelected = !this.report.isSelected;

      // Check if all reports are selected and update header checkbox if needed
      if (this.$store.getters.reports.every((report) => report.isSelected)) {
        this.$store.commit('setAllSelected', true);
      }

      if (this.selected) {
        await this.addToSelection();
      } else {
        await this.removeFromSelection();
      }
    },
    async addToSelection() {
      const reportUuids = [this.report.uuid];
      try {
        const response = await axiosResource.post(`/selection/temporary/`, {
          reportUuids,
        });

        if (response.data.success) {
          this.report.isSelected = true;
          const selectedCount = response.data.selectedCount;
          this.$store.dispatch('updateSelectedCount', selectedCount);
        }
      } catch (error) {
        this.handleSelectionError(error, false);
      }
    },
    async removeFromSelection() {
      const reportUuids = [this.report.uuid];
      try {
        const response = await axiosResource.delete(`/selection/temporary/`, {
          data: { reportUuids },
        });

        if (response.data.success) {
          this.report.isSelected = false;
          const selectedCount = response.data.selectedCount;
          this.$store.dispatch('updateSelectedCount', selectedCount);
        }
      } catch (error) {
        this.handleSelectionError(error, true);
      }
    },
    handleSelectionError(error, fallbackState) {
      console.log(error);
      if (error.response?.data?.error === 'error.selection.exceeds') {
        const maxSelection = error.response?.data?.maxSelection || 999;
        this.$toast.error(
          this.$t('error.selection.exceeds', { maxSelection: maxSelection }),
          {
            position: 'top-center',
            timeout: 5000,
          }
        );
        this.isAllSelected = fallbackState;
      } else {
        const errorMessage =
          error.response?.data?.message || 'error.unknownError';
        this.$toast.error(this.$t(errorMessage), {
          position: 'top-center',
          timeout: 5000,
        });
      }
    },
    async exitSelection() {
      if (this.$store.getters.selectionMode) {
        const clgStyle = 'color: pink;';
        console.log('%c' + 'exitSelection', clgStyle);
        // EXIT SELECTION
        this.$store.commit('setSelectionMode', false);
        // INIT STORE FOR SEARCH
        this.$store.commit('initPage');
        this.$store.commit('initReports');
        this.$store.commit('resetQttLoaded');
        // FETCH
        await this.$store.dispatch('fetchReportsGeneric');
      }
    },
    async showSelection() {
      const clgStyle = 'color: blue;';
      console.log('%c' + 'showSelection', clgStyle);
      try {
        // INIT STORE FOR DISPLAY
        this.$store.commit('initPage');
        this.$store.commit('initReports');
        this.$store.commit('resetQttLoaded');
        // Call the fetch method from the Vuex store
        await this.$store.dispatch('fetchReportsGeneric', true);

        this.$store.commit('setSelectionMode', true);
      } catch (error) {
        console.error('Error displaying selection:', error);
      }
    },
    async emptySelection() {
      this.$store.commit('setSelectionMode', false);
      const reportUuids = this.$store.getters.allUuids;
      const reports = this.$store.getters.reports;
      // Attempt to unselect all reports
      try {
        const response = await axiosResource.delete(`/selection/temporary`, {
          data: { reportUuids: [] }, // Send an empty array to clear all selections
        });
        if (response.data.success) {
          // Dispatch the action to update the selected count in Vuex
          const selectedCount = response.data.selectedCount;
          this.$store.dispatch('updateSelectedCount', selectedCount);

          this.$store.commit('setAllSelected', false);
          reports.forEach((report) => {
            report.isSelected = false;
          });
        }
      } catch (error) {
        console.error('Error unselecting all reports:', error);
        this.isAllSelected = true; // Maintain checkbox state on error

        const errorMessage =
          error.response?.data?.message || 'error.unknownError';
        this.$toast.error(this.$t(errorMessage), {
          position: 'top-center',
          timeout: 5000,
        });
      }
    },
    async addAllToSelection() {
      const reportUuids = this.$store.getters.allUuids;
      const reports = this.$store.getters.reports;
      // Toggle selection state
      this.isAllSelected = !this.isAllSelected;

      if (this.isAllSelected) {
        // Attempt to select all reports
        try {
          const response = await axiosResource.post(`/selection/temporary/`, {
            reportUuids,
          });
          if (response.data.success) {
            // Dispatch the action to update the selected count in Vuex
            const selectedCount = response.data.selectedCount;
            this.$store.dispatch('updateSelectedCount', selectedCount);

            this.$store.commit('setAllSelected', true);
            reports.forEach((report) => {
              report.isSelected = true;
            });
          }
        } catch (error) {
          console.error('Error selecting all reports:', error);

          // Handle max selection limit error
          if (error.response?.data?.error === 'error.selection.exceeds') {
            const maxSelection = error.response?.data?.maxSelection || 999;
            this.$toast.error(
              this.$t('error.selection.exceeds', {
                maxSelection: maxSelection,
              }),
              {
                position: 'top-center',
                timeout: 5000,
              }
            );
          } else {
            const errorMessage =
              error.response?.data?.message || 'error.unknownError';
            console.log(errorMessage);
            console.log(this.$t(errorMessage));
            this.$toast.error(this.$t(errorMessage), {
              position: 'top-center',
              timeout: 5000,
            });
          }

          // Revert checkbox state on error
          this.isAllSelected = false;
        }
      } else {
        // Attempt to unselect all reports
        try {
          const response = await axiosResource.delete(`/selection/temporary`, {
            data: { reportUuids },
          });
          if (response.data.success) {
            // Dispatch the action to update the selected count in Vuex
            const selectedCount = response.data.selectedCount;
            this.$store.dispatch('updateSelectedCount', selectedCount);

            this.$store.commit('setAllSelected', false);
            reports.forEach((report) => {
              report.isSelected = false;
            });
          }
        } catch (error) {
          console.error('Error unselecting all reports:', error);
          this.isAllSelected = true; // Maintain checkbox state on error

          const errorMessage =
            error.response?.data?.message || 'error.unknownError';
          this.$toast.error(this.$t(errorMessage), {
            position: 'top-center',
            timeout: 5000,
          });
        }
      }
    },
  },
};
