<template>
  <b-modal
    id="modal-comments"
    :title="$t('modal.comments.title')"
    size="lg"
    button-size="sm"
    @show="onShow"
  >
    <b-alert
      variant="danger"
      v-if="!this.reportUuid && this.$store.getters.selectedCount > this.maxSelection"
      :show="true"
    >
      {{ $t('modal.comments.tooManyReports', { count: this.maxSelection }) }}
    </b-alert>
    <b-alert
      variant="warning"
      v-if="!this.reportUuid && this.$store.getters.selectedCount > 1 && this.$store.getters.selectedCount <= this.maxSelection "
      :show="true"
    >
      {{ $t('modal.comments.multipleReports', { count: this.$store.getters.selectedCount }) }}
    </b-alert>
    <b-row
      v-for="comment in comments"
      :key="comment.uuid"
    >
      <b-col
        class="comment-item"
        cols="10"
      >
        <h4>
          <b-badge
            pill
            variant="light"
          >
            <font-awesome-icon :icon="['fal', 'comment']" />
            {{ comment.comment }}
          </b-badge>
          <span class="userAcronym4">
            {{ comment.user.acronym4digits }} ({{ comment.dateCreated }})
          </span>
        </h4>
      </b-col>
      <b-col cols="2">
        <b-link
          v-if="$store.getters.loggedUser.uuid === comment.byUserUuid"
          @click.prevent="deleteComment(comment.uuid)"
          href="#"
          class="font-size-10"
        >
          {{ $t('comment.delete') }}
        </b-link>
      </b-col>
    </b-row>

    <br />

    <form
      @submit.prevent="onFormSubmit"
      v-if="this.reportUuid || this.$store.getters.selectedCount <= this.maxSelection"
    >
      <b-row>
        <b-col cols="10">
          <b-form-group>
            <b-form-input
              class="custom-input"
              :placeholder="$t('modal.comments.instruction')"
              v-model.trim="form.comment"
              maxlength="100"
            ></b-form-input>

            <!-- Error messages, shown only after submission and dirty state -->
            <label
              class="error"
              v-if="!$v.form.comment.required && $v.form.comment.$dirty"
            >
              {{ $t('validate.required') }}
            </label>
            <label
              class="error"
              v-if="!$v.form.comment.maxLength && $v.form.comment.$dirty"
            >
              {{ $t('validate.maxLength', { val: '200' }) }}
            </label>
            <label
              class="error"
              v-if="!$v.form.comment.minLength && $v.form.comment.$dirty"
            >
              {{ $t('validate.minLength', { val: '2' }) }}
            </label>
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-button
            type="button"
            size="sm"
            variant="primary"
            block
            class="custom-button"
            @click="onFormSubmit"
            :disabled="this.$store.getters.loader"
          >
            {{ $t('action.add') }}
          </b-button>
        </b-col>
      </b-row>
    </form>

    <template #modal-footer="{ hide }">
      <div class="d-flex justify-content-between align-items-center w-100">
        <small class="text-muted">* {{ $t('validate.required') }}</small>
        <div>
          <b-button
            size="sm"
            variant="light"
            @click="hide"
          >
            {{ $t('action.cancel') }}
          </b-button>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import axiosResource from '@/utils/axiosResource';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import { globalSwal } from '@/plugins/globalSwal';
import swal from 'sweetalert2';

const swalBootstrap = swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary btn-swal',
    cancelButton: 'btn btn-light btn-swal',
  },
  buttonsStyling: false,
});

export default {
  name: 'modal-comments',
  data() {
    return {
      comments: [],
      form: {
        comment: '',
      },
      maxSelection: 100,
    };
  },
  validations: {
    form: {
      comment: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(200),
      },
    },
  },
  computed: {
    reportUuid() {
      return this.$store.getters.reportUuid; // Access `reportUuid` from Vuex
    },
  },
  methods: {
    onShow() {
      // Init modal
      this.$v.$reset();
      this.comments = [];
      this.form.comment = '';

      if (this.$store.getters.selectedCount == 1 || this.reportUuid) {
        this.fetchComments();
      }
    },
    fetchComments() {
      axiosResource
        .post(`comments/fetch`, {
          reportUuid: this.reportUuid,
        })
        .then((response) => {
          this.comments = response.data.comments;
        })
        .catch((error) => console.error('Failed to load comments', error));
    },
    onFormSubmit() {
      // Trigger validation manually when the form is submitted
      this.$v.form.$touch();

      // If validation fails, do not proceed
      if (this.$v.form.$invalid) {
        return;
      }

      // Otherwise, add the comment
      this.addComment();
    },
    addComment() {
      this.$store.commit('loading', true);
      axiosResource
        .post(`comments/add`, {
          reportUuid: this.reportUuid,
          comment: this.form.comment,
        })
        .then((response) => {
          this.$store.commit('loading', false);
          this.comments.push(response.data); // Assuming the API returns the added comment
          // Increment count (for articles)
          this.$store.dispatch(
            'incrementCommentCount',
            response.data.reportUuids
          );

          this.form.comment = ''; // Clear the input field
          this.$v.form.comment.$reset(); // Reset the validation state
          // If multiple add, close modal and show succes swal
          if (this.$store.getters.selectedCount > 1) {
            this.$bvModal.hide('modal-comments');
            globalSwal.successSimple('success.done');
          }
          // Refresh comments (for single report from article)
          if (this.reportUUid !== '') {
            this.fetchComments(); // Refresh the list after adding a new comment
          }
        })
        .catch((error) => {
          this.$store.commit('loading', false);

          // Check if the error response contains specific error data
          if (error.response?.data) {
            const responseData = error.response.data;

            if (responseData.message === 'error.report.containsQuick') {
              globalSwal.error(
                this.$t('error.report.containsQuick') // Display localized error message
              );
            } else if (
              responseData.error === 'command.protect.selectLessThan'
            ) {
              globalSwal.error(
                this.$t('error.selection.exceeds', {
                  maxSelection: responseData.maxSelection,
                })
              );
            } else {
              // Generic error message fallback
              globalSwal.error(
                this.$t(responseData.message || 'error.unknownError')
              );
            }
          } else {
            // Handle errors without specific response data
            globalSwal.error(this.$t('error.unknownError'));
          }

          console.error('Failed to add comment', error);
        });
    },
    deleteComment(commentUuid) {
      swalBootstrap
        .fire({
          title: this.$t('comment.sure'),
          text: this.$t('comment.warning'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: this.$t('comment.yesDelete'),
        })
        .then((result) => {
          if (result.isConfirmed) {
            axiosResource
              .put(`comments/${commentUuid}`)
              .then((response) => {
                this.$store.dispatch('decrementCommentCount', this.reportUuid);
                this.fetchComments(); // Refresh the list after deleting the comment
                globalSwal.successSimple('success.done');
              })
              .catch((error) => {
                console.error('Failed to delete comment', error);
                swalBootstrap.fire(
                  'Failed!',
                  'Failed to delete the comment: ' + error.message,
                  'error'
                );
              });
          }
        });
    },
  },
};
</script>

<style scoped>
.userAcronym4 {
  color: gray;
  font-size: 10px;
}

.custom-input {
  height: 36px; /* Adjust this based on your actual input height */
}

.custom-button {
  height: 36px; /* Ensure the button height matches the input */
}

.error {
  color: red;
  font-size: 12px;
}
</style>
