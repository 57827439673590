import { render, staticRenderFns } from "./TableView.vue?vue&type=template&id=134d6124&scoped=true"
import script from "./TableView.vue?vue&type=script&lang=js"
export * from "./TableView.vue?vue&type=script&lang=js"
import style0 from "./TableView.vue?vue&type=style&index=0&id=134d6124&prod&lang=scss"
import style1 from "./TableView.vue?vue&type=style&index=1&id=134d6124&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "134d6124",
  null
  
)

export default component.exports