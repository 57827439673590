<template>
  <div>
    <!-- FILTER -->
    <div
      id="sidebar-filter"
      class="sidebar-filter"
    >
      <!-- <div
        v-if="this.$store.getters.currentStatsView == 'curve'"
        class="mt-5 text-center"
      >
        <font-awesome-icon
          :icon="['fal', 'filters']"
          size="2x"
        />
        <p class="mt-4">Filters do not apply to this instrument.</p>

      </div> -->

      <!-- CLEAR ALL FILTERS -->

      <div
        class="filter-section-button  d-flex align-items-center justify-content-end "
        id="reset"
        @click="toggleSection"
      >
        <div class="mr-auto">
          <span v-show="open">{{ $t('filter.resetAll') }}</span>
        </div>
        <div>
          <el-button
            type="text"
            class="ml-2 clear-button"
            @click.stop="clearAllFilters"
          >
            <font-awesome-icon
              icon="fa-regular fa-arrows-rotate"
              size="lg"
              class="clear-icon-margin"
            />
          </el-button>
        </div>
      </div>

      <!-- KEYWORD -->
      <div v-if="isMobile || (!isMobile && (this.$store.getters.section == 'explore' || this.$store.getters.currentStatsView !== 'curve'))">
        <div v-show="open">
          <div
            class="filter-section-button d-flex align-items-center justify-content-end"
            id="keyword"
            @click="toggleSection"
          >
            <div class="mr-auto">
              <span v-show="open">{{ $t('filter.keyword') }}</span>
            </div>
            <div v-if="section.keyword">
              <el-button
                type="text"
                class="ml-2 clear-button"
                @click.stop="clearKeywords(true)"
              >
                <font-awesome-icon
                  icon="fa-regular fa-arrows-rotate"
                  size="lg"
                  class="mr-1"
                />
              </el-button>
            </div>
            <div
              class="p-2"
              v-show="open"
            >
              <font-awesome-icon
                v-if="section.keyword"
                icon="fa-regular fa-angle-up"
                size="lg"
              />
              <font-awesome-icon
                v-else
                icon="fa-regular fa-angle-down"
                size="lg"
              />
            </div>
          </div>
        </div>
        <div
          v-if="section.keyword"
          class="my-3 ml-2 mr-4"
        >
          <div v-if="
        open">
            <div
              v-for="tag in dynamicTags"
              :key="tag"
              class="tag-container"
            >
              <el-tag
                closable
                type="info"
                :disable-transitions="false"
                @close="handleClose(tag)"
              >
                {{tag}}
              </el-tag>

            </div>

            <el-input
              class="input-new-tag"
              v-if="inputVisible"
              v-model="keywordValue"
              size="small"
              ref="saveTagInput"
              @keyup.enter.native="handleInputConfirm"
              @blur="handleInputConfirm"
            >
            </el-input>
            <el-button
              v-else
              class="button-new-tag"
              @click="showInput"
              :disabled="$store.getters.isFetching"
            >+</el-button>

          </div>

        </div>
      </div>

      <!-- LOCATION -->

      <div v-if="isMobile || (!isMobile && (this.$store.getters.section == 'explore' || this.$store.getters.currentStatsView !== 'curve'))">
        <div v-show="open">
          <div
            class="filter-section-button d-flex align-items-center justify-content-end"
            id="reference"
            @click="toggleSection"
          >
            <div class="mr-auto">
              <span v-show="open">{{ $t('filter.localisation') }}</span>
            </div>
            <div v-if="section.reference">
              <el-button
                type="text"
                class="ml-2 clear-button"
                @click.stop="clearLocation(true)"
              >
                <font-awesome-icon
                  icon="fa-regular fa-arrows-rotate"
                  size="lg"
                  class="mr-1"
                />
              </el-button>
            </div>
            <div
              class="p-2"
              v-show="open"
            >
              <font-awesome-icon
                v-if="section.reference"
                icon="fa-regular fa-angle-up"
                size="lg"
              />
              <font-awesome-icon
                v-else
                icon="fa-regular fa-angle-down"
                size="lg"
              />
            </div>
          </div>
        </div>
        <div
          v-if="section.reference"
          class="my-3 ml-3 mr-4"
        >
          <b-row v-if="
        open">
            <b-col
              cols="8"
              lg="8"
              class="col-small-margin"
            >
              <b-form-group id="input-group-3">
                <el-select
                  class="w-100"
                  v-model="buildingValue"
                  filterable
                  clearable
                  :placeholder="$t('building.building')"
                  :disabled="$store.getters.isFetching"
                  @change="buildingChange"
                >
                  <el-option
                    v-for="item in buildingOptions"
                    :key="item.uuid"
                    :label="item.ref"
                    :value="item.uuid"
                  >
                  </el-option>
                </el-select>
              </b-form-group>
            </b-col>
            <b-col
              cols="4"
              lg="4"
              class="col-small-margin"
            >
              <b-form-group id="input-group-3">
                <el-select
                  class="w-100"
                  v-model="levelValue"
                  filterable
                  clearable
                  :placeholder="$t('building.level')"
                  :disabled="$store.getters.isFetching"
                  @change="levelChange"
                >
                  <el-option
                    v-for="item in levelOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="open">
            <b-col
              cols="4"
              md="4"
              class="col-small-margin"
            >
              <b-form-group id="input-group-3">
                <el-select
                  class="w-100"
                  v-model="zoneValue"
                  filterable
                  clearable
                  :placeholder="$t('building.zone')"
                  :disabled="$store.getters.isFetching"
                  @change="zoneChange"
                >
                  <el-option
                    v-for="item in zoneOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </b-form-group>
            </b-col>
            <b-col
              cols="4"
              md="4"
              class="col-small-margin"
            >
              <b-form-group id="input-group-2">
                <el-input
                  :placeholder="$t('filter.serial')"
                  v-model="serial"
                  clearable
                  maxlength="6"
                  @input="onInput"
                ></el-input>
              </b-form-group>
            </b-col>
            <b-col
              cols="4"
              md="4"
              class="col-small-margin"
            >
              <b-form-group id="input-group-3">
                <el-select
                  class="w-100"
                  v-model="definitionValue"
                  filterable
                  clearable
                  :placeholder="$t('filter.definition')"
                  :disabled="$store.getters.isFetching"
                  @change="definitionChange"
                >
                  <el-option
                    v-for="item in definitionOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </b-form-group>

            </b-col>
          </b-row>

          <!-- <h5 cl   ass="mt-3">{{ $t('filter.spot') }}</h5> -->
          <b-row v-show="open">
            <b-col class="col-small-margin">
              <el-select
                class="w-100"
                v-model="locationValue"
                multiple
                clearable
                :placeholder="$t('filter.spot')"
                :disabled="$store.getters.isFetching"
                @change="changeLocation"
              >
                <el-option
                  v-for="item in locationOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </b-col>
          </b-row>
        </div>
      </div>

      <!-- RESULT -->

      <div v-if="isMobile || (!isMobile && (this.$store.getters.section == 'explore' || this.$store.getters.currentStatsView !== 'curve'))">
        <div v-show="open">
          <div
            class="filter-section-button d-flex align-items-center justify-content-end"
            id="result"
            @click="toggleSection"
          >
            <div
              class="mr-auto"
              v-show="open"
            >
              <span v-show="open">{{ $t('filter.result') }}</span>
            </div>
            <div v-if="section.result">
              <el-button
                type="text"
                class="ml-2 clear-button"
                @click.stop="clearResult(true)"
              >
                <!-- {{ $t('filter.reset') }} -->
                <font-awesome-icon
                  icon="fa-regular fa-arrows-rotate"
                  size="lg"
                  class="mr-1"
                />
              </el-button>
            </div>
            <div
              class="p-2"
              v-show="open"
            >
              <font-awesome-icon
                v-if="section.result"
                icon="fa-regular fa-angle-up"
                size="lg"
              />
              <font-awesome-icon
                v-else
                icon="fa-regular fa-angle-down"
                size="lg"
              />
            </div>
          </div>
        </div>
        <div
          v-show="section.result"
          class="my-3 ml-2 mr-1"
        >
          <div v-show="open">

            <!-- Detailed reports -->

            <!-- <h5 class="mt-3">{{ $t('explore.detailedReport') }}</h5> -->

            <button
              id="btnSearchInfo"
              data-genus="priority"
              value="INFO"
              type="button"
              class="btn btn-lg btn-filter btn-filter-info text-left btn-filter-100"
              :class="{ active: $store.getters.priority.INFO }"
              :disabled="$store.getters.isFetching"
              @click="toggleActive"
            >{{ $t('priority.info.value').charAt(0).toUpperCase() + $t('priority.info.value').toLowerCase().slice(1) }}
            </button>
            <br>
            <button
              id="btnSearchLongTerm"
              data-genus="priority"
              value="LONGTERM"
              type="button"
              class="btn btn-lg btn-filter btn-filter-longterm text-left"
              :class="{ active: $store.getters.priority.LONGTERM }"
              :disabled="$store.getters.isFetching"
              @click="toggleActive"
            >{{ $t('priority.longterm.value').charAt(0).toUpperCase() + $t('priority.longterm.value').toLowerCase().slice(1) }}
            </button>
            <button
              id="btnSearchMediumTerm"
              data-genus="priority"
              value="MEDIUMTERM"
              type="button"
              class="btn btn-lg btn-filter btn-filter-mediumterm text-left"
              :class="{ active: $store.getters.priority.MEDIUMTERM }"
              :disabled="$store.getters.isFetching"
              @click="toggleActive"
            >{{ $t('priority.mediumterm.value').charAt(0).toUpperCase() + $t('priority.mediumterm.value').toLowerCase().slice(1) }}
            </button>
            <button
              id="btnSearchShortTerm"
              data-genus="priority"
              value="SHORTTERM"
              type="button"
              class="btn btn-lg btn-filter btn-filter-shortterm text-left"
              :class="{ active: $store.getters.priority.SHORTTERM }"
              :disabled="$store.getters.isFetching"
              @click="toggleActive"
            >{{ $t('priority.shortterm.value').charAt(0).toUpperCase() + $t('priority.shortterm.value').toLowerCase().slice(1) }}
            </button>
            <button
              id="btnSearchDanger"
              data-genus="priority"
              value="URGENT"
              type="button"
              class="btn btn-lg btn-md- btn-filter btn-filter-dangerTerm text-left"
              :class="{ active: $store.getters.priority.URGENT }"
              :disabled="$store.getters.isFetching"
              @click="toggleActive"
            >{{ $t('priority.urgent.value').charAt(0).toUpperCase() + $t('priority.urgent.value').toLowerCase().slice(1) }}
            </button>

            <!-- Quick interactions -->

            <!-- <h5 class="mt-3">{{ $t('explore.quickReport') }}</h5> -->

            <!-- <b-row no-gutters>
            <b-col>
              <small class="text-muted">{{ $t('status.ACTIVE') }}</small>
            </b-col>
            <b-col>
              <small class="text-muted">{{ $t('status.INACTIVE') }}</small>
            </b-col>
          </b-row> -->

            <!-- Like ACTIVE -->
            <button
              id="btnSearchQuickLikeActive"
              data-genus="quick"
              value="likeActive"
              type="button"
              class="btn btn-lg btn-filter-small btn-filter-quickLike w-40"
              :class="{ active: $store.getters.quick.likeActive }"
              :disabled="$store.getters.isFetching"
              @click="toggleActive"
            >
              <font-awesome-icon
                icon="fa-solid fa-thumbs-up"
                size="lg"
              />
            </button>
            <!-- Visited ACTIVE -->
            <button
              id="btnSearchVisitedActive"
              data-genus="quick"
              value="visitedActive"
              type="button"
              class="btn btn-lg btn-filter-small btn-filter-visited"
              :class="{ active: $store.getters.quick.visitedActive }"
              :disabled="$store.getters.isFetching"
              @click="toggleActive"
            >
              <font-awesome-icon
                icon="fa-solid fa-person-walking"
                size="lg"
              />
            </button>
            <!-- Like INACTIVE -->
            <button
              id="btnSearchQuickLikeInactive"
              data-genus="quick"
              value="likeInactive"
              type="button"
              class="btn btn-lg btn-filter-small btn-filter-quickLikeInactive"
              :class="{ active: $store.getters.quick.likeInactive }"
              :disabled="$store.getters.isFetching"
              @click="toggleActive"
            >
              <font-awesome-icon
                icon="fa-light fa-thumbs-up"
                size="lg"
              />
              <!-- <font-awesome-layers class="fa-lg fa-fw">
              <font-awesome-icon icon="fa-thin fa-thumbs-up" />
              <font-awesome-icon
                icon="fa-light fa-period"
                transform="up-12 right-9  "
              />
            </font-awesome-layers> -->
            </button>
            <!-- Visited INACTIVE -->
            <button
              id="btnSearchVisitedInactive"
              data-genus="quick"
              value="visitedInactive"
              type="button"
              class="btn btn-lg btn-filter-small btn-filter-visitedInactive"
              :class="{ active: $store.getters.quick.visitedInactive }"
              :disabled="$store.getters.isFetching"
              @click="toggleActive"
            >
              <font-awesome-icon
                icon="fa-thin fa-person-walking"
                size="lg"
              />
            </button>
            <!-- Locked ACTIVE -->
            <button
              id="btnSearchLockedActive"
              data-genus="quick"
              value="lockedActive"
              type="button"
              class="btn btn-lg btn-filter-small btn-filter-locked"
              :class="{ active: $store.getters.quick.lockedActive }"
              :disabled="$store.getters.isFetching"
              @click="toggleActive"
            >
              <font-awesome-icon
                icon="fa-solid fa-lock"
                size="lg"
              />
            </button>
            <!-- Busy ACTIVE -->
            <button
              id="btnSearchBusyActive"
              data-genus="quick"
              value="busyActive"
              type="button"
              class="btn btn-lg btn-filter-small btn-filter-busy"
              :class="{ active: $store.getters.quick.busyActive }"
              :disabled="$store.getters.isFetching"
              @click="toggleActive"
            >
              <font-awesome-icon
                icon="fa-solid fa-ban"
                size="lg"
              />
            </button>
            <!-- Locked INACTIVE -->
            <button
              id="btnSearchLockedInactive"
              data-genus="quick"
              value="lockedInactive"
              type="button"
              class="btn btn-lg btn-filter-small btn-filter-lockedInactive"
              :class="{ active: $store.getters.quick.lockedInactive }"
              :disabled="$store.getters.isFetching"
              @click="toggleActive"
            >
              <font-awesome-icon
                icon="fa-light fa-lock"
                size="lg"
              />
            </button>
            <!-- Busy INACTIVE -->
            <button
              id="btnSearchBusyInactive"
              data-genus="quick"
              value="busyInactive"
              type="button"
              class="btn btn-lg btn-filter-small btn-filter-busyInactive"
              :class="{ active: $store.getters.quick.busyInactive }"
              :disabled="$store.getters.isFetching"
              @click="toggleActive"
            >
              <font-awesome-icon
                icon="fa-light fa-ban"
                size="lg"
              />
            </button>

          </div>
        </div>
      </div>

      <!-- STATUS -->

      <div v-if="isMobile || (!isMobile && (this.$store.getters.section == 'explore' || this.$store.getters.currentStatsView !== 'curve'))">
        <div v-show="open">
          <div
            class="filter-section-button d-flex align-items-center justify-content-end"
            id="status"
            @click="toggleSection"
          >
            <div
              class="mr-auto"
              v-show="open"
            >
              <span v-show="open">{{ $t('filter.status') }}</span>
            </div>
            <div v-if="section.status">
              <el-button
                type="text"
                class="ml-2 clear-button"
                @click.stop="clearStatus(true)"
              >
                <!-- {{ $t('filter.reset') }} -->
                <font-awesome-icon
                  icon="fa-regular fa-arrows-rotate"
                  size="lg"
                  class="mr-1"
                />
              </el-button>
            </div>
            <div
              class="p-2"
              v-show="open"
            >
              <font-awesome-icon
                v-if="section.status"
                icon="fa-regular fa-angle-up"
                size="lg"
              />
              <font-awesome-icon
                v-else
                icon="fa-regular fa-angle-down"
                size="lg"
              />
            </div>

          </div>
        </div>
        <div
          v-show="section.status"
          class="my-3 ml-2 mr-1"
        >
          <div v-show="open">

            <!-- <h5>{{ $t('filter.activeStatus') }}</h5> -->

            <button
              id="btnSearchStatusOpen"
              data-genus="status"
              value="OPEN"
              type="button"
              class="btn btn-lg btn-filter btn-filter-status-open text-left"
              :class="{ active: $store.getters.status.OPEN }"
              :disabled="$store.getters.isFetching"
              @click="toggleActive"
            >
              {{ $t('status.open') }}
            </button>

            <button
              id="btnSearchStatusAddressed"
              data-genus="status"
              value="ADDRESSED"
              type="button"
              class="btn btn-lg btn-filter btn-filter-status-addressed text-left"
              :class="{ active: $store.getters.status.ADDRESSED }"
              :disabled="$store.getters.isFetching"
              @click="toggleActive"
            >{{ $t('status.addressed') }}
            </button>
            <button
              id="btnSearchStatusOngoing"
              data-genus="status"
              value="ONGOING"
              type="button"
              class="btn btn-lg btn-filter btn-filter-status-ongoing text-left"
              :class="{ active: $store.getters.status.ONGOING }"
              :disabled="$store.getters.isFetching"
              @click="toggleActive"
            >{{ $t('status.ongoing') }}
            </button>
            <button
              id="btnSearchStatusSolved"
              data-genus="status"
              value="SOLVED"
              type="button"
              class="btn btn-lg btn-filter btn-filter-status-solved text-left"
              :class="{ active: $store.getters.status.SOLVED }"
              :disabled="$store.getters.isFetching"
              @click="toggleActive"
            >{{ $t('status.solved')  }}
            </button>

            <!-- <h5 class="mt-3">{{ $t('filter.passiveStatus') }}</h5> -->

            <button
              id="btnSearchStatusClosed"
              data-genus="status"
              value="CLOSED"
              type="button"
              class="btn btn-lg btn-filter btn-filter-status-closed text-left"
              :class="{ active: $store.getters.status.CLOSED }"
              :disabled="$store.getters.isFetching"
              @click="toggleActive"
            >{{ $t('status.closed')  }}
            </button>
            <button
              id="btnSearchStatusOnHold"
              data-genus="status"
              value="ONHOLD"
              type="button"
              class="btn btn-lg btn-filter btn-filter-status-onHold text-left"
              :class="{ active: $store.getters.status.ONHOLD }"
              :disabled="$store.getters.isFetching"
              @click="toggleActive"
            >{{ $t('status.onhold')  }}
            </button>
            <button
              id="btnSearchStatusNoAction"
              data-genus="status"
              value="NOACTION"
              type="button"
              class="btn btn-lg btn-filter btn-filter-status-noAction text-left"
              :class="{ active: $store.getters.status.NOACTION }"
              :disabled="$store.getters.isFetching"
              @click="toggleActive"
            >{{ $t('status.noaction')  }}
            </button>
            <button
              id="btnSearchStatusDeleted"
              data-genus="status"
              value="DELETED"
              type="button"
              class="btn btn-lg btn-filter btn-filter-status-deleted text-left"
              :class="{ active: $store.getters.status.DELETED }"
              :disabled="$store.getters.isFetching"
              @click="toggleActive"
            >{{ $t('status.deleted')  }}
            </button>

          </div>
        </div>
      </div>

      <!-- WHEN -->

      <div>
        <div v-show="open">
          <div
            class="filter-section-button d-flex align-items-center justify-content-end"
            id="when"
            @click="toggleSection"
          >
            <div
              class="mr-auto"
              v-show="open"
            >
              <span v-show="open">{{ $t('filter.when') }}</span>
            </div>
            <div v-if="section.when">
              <el-button
                type="text"
                class="ml-2 clear-button"
                @click.stop="clearWhen"
              >
                <!-- {{ $t('filter.reset') }} -->
                <font-awesome-icon
                  icon="fa-regular fa-arrows-rotate"
                  size="lg"
                  class="mr-1"
                />
              </el-button>
            </div>
            <div
              class="p-2"
              v-show="open"
            >
              <font-awesome-icon
                v-if="section.when"
                icon="fa-regular fa-angle-up"
                size="lg"
              />
              <font-awesome-icon
                v-else
                icon="fa-regular fa-angle-down"
                size="lg"
              />
            </div>
          </div>
        </div>
        <div
          v-show="section.when"
          class="my-3 ml-2 mr-4"
        >
          <div v-show="open">

            <b-row>
              <b-col>
                <b-input-group
                  :prepend="$t('date.from')"
                  class="mb-2"
                >
                  <el-date-picker
                    v-model="dateStart"
                    type="date"
                    :placeholder="$t('filter.select')"
                    class="date-picker"
                    :disabled="$store.getters.isFetching"
                  >
                  </el-date-picker>
                </b-input-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-input-group
                  :prepend="$t('date.to')"
                  class="mb-2 date-prefix"
                >
                  <el-date-picker
                    v-model="dateEnd"
                    type="date"
                    :placeholder="$t('filter.select')"
                    class="date-picker"
                    :disabled="$store.getters.isFetching"
                  >
                  </el-date-picker>
                </b-input-group>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>

      <!-- PROGRAM -->

      <div v-if="isMobile || (!isMobile && (this.$store.getters.section == 'explore' || (this.$store.getters.currentStatsView !== 'program' && this.$store.getters.currentStatsView !== 'curve')))">
        <div v-show="open">
          <div
            class="filter-section-button d-flex align-items-center justify-content-end"
            id="program"
            @click="toggleSection"
          >
            <div
              class="mr-auto"
              v-show="open"
            >
              <span v-show="open">{{ $t('filter.program') }}</span> <small v-if="this.$store.getters.programDefaultCheckedKeys.length != 0">({{ this.$store.getters.programDefaultCheckedKeys.length }})</small>
            </div>
            <div v-if="section.program">
              <el-button
                type="text"
                class="ml-2 clear-button"
                @click.stop="resetTree"
              >
                <!-- {{ $t('filter.reset') }} -->
                <font-awesome-icon
                  icon="fa-regular fa-arrows-rotate"
                  size="lg"
                  class="mr-1"
                />
              </el-button>
            </div>
            <div
              class="p-2"
              v-show="open"
            >
              <font-awesome-icon
                v-if="section.program"
                icon="fa-regular fa-angle-up"
                size="lg"
              />
              <font-awesome-icon
                v-else
                icon="fa-regular fa-angle-down"
                size="lg"
              />
            </div>
          </div>
        </div>
        <div
          v-show="section.program"
          class="my-3 ml-2 mr-1"
        >

          <div
            v-show="open"
            class=" mr-2 pr-2"
          >
            <el-input
              :placeholder="$t('filter.filterKeyword')"
              clearable
              v-model="filterText"
              prefix-icon="el-icon-search"
              class="custom-el-filter mb-2"
            >
            </el-input>
            <el-tree
              id="programTree"
              class="sidebar-inner-container"
              v-loading="loading"
              check-strictly
              :data="treeData"
              show-checkbox
              node-key="uuid"
              :expand-on-click-node="true"
              :filter-node-method="filterNode"
              :default-checked-keys="treeDefaultCheckedKeys"
              accordion
              ref="tree"
              :props="defaultProps"
              @check-change="handleProgramCheckChange"
              :disabled="$store.getters.isFetching"
            >
            </el-tree>

            <el-checkbox
              v-model="exactMatch"
              size="mini"
              class="mt-3"
              border
              :disabled="$store.getters.isFetching"
            >
              Exact match
            </el-checkbox>

          </div>
        </div>
      </div>

      <!-- WHO -->

      <div v-if="isMobile || (!isMobile && (this.$store.getters.section == 'explore' || this.$store.getters.currentStatsView !== 'curve'))">
        <div v-show="open">
          <div
            class="filter-section-button d-flex align-items-center justify-content-end"
            id="who"
            @click="toggleSection"
          >
            <div
              class="mr-auto"
              v-show="open"
            >
              <span v-show="open">{{ $t('filter.who') }}</span>
            </div>
            <div v-if="section.who">
              <el-button
                type="text"
                class="ml-2 clear-button"
                @click.stop="clearWho"
              >
                <!-- {{ $t('filter.reset') }} -->
                <font-awesome-icon
                  icon="fa-regular fa-arrows-rotate"
                  size="lg"
                  class="mr-1"
                />
              </el-button>
            </div>
            <div
              class="p-2"
              v-show="open"
            >
              <font-awesome-icon
                v-if="section.who"
                icon="fa-regular fa-angle-up"
                size="lg"
              />
              <font-awesome-icon
                v-else
                icon="fa-regular fa-angle-down"
                size="lg"
              />
            </div>
          </div>
        </div>
        <div
          v-show="section.who"
          class="my-3 ml-2 mr-1"
        >
          <div v-show="open">

            <el-select
              :placeholder="$t('filter.entity')"
              v-model="entityValue"
              class="mb-4 width-94"
              multiple
              clearable
              filterable
              :disabled="$store.getters.isFetching"
            >
              <el-option
                v-for="item in entityOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>

            <!-- <h5 class="mt-3">{{ $t('filter.user') }}</h5> -->

            <el-select
              :placeholder="$t('filter.user')"
              v-model="inspectorValue"
              class="mb-4 width-94"
              multiple
              clearable
              filterable
              :disabled="$store.getters.isFetching"
            >
              <el-option
                v-for="item in inspectorOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>

            <el-select
              :placeholder="$t('filter.nature')"
              v-model="natureValue"
              class="width-94"
              multiple
              clearable
              filterable
              :disabled="$store.getters.isFetching"
            >
              <el-option
                v-for="item in natureOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>

          </div>
        </div>
      </div>

      <!-- OTHER -->

      <div v-if="isMobile || (!isMobile && (this.$store.getters.section == 'explore' || this.$store.getters.currentStatsView !== 'curve'))">
        <div v-show="open">
          <div
            class="filter-section-button d-flex align-items-center justify-content-end"
            id="other"
            @click="toggleSection"
          >
            <div
              class="mr-auto"
              v-show="open"
            >
              <span v-show="open">{{ $t('filter.other') }}</span>
            </div>
            <div v-if="section.other">
              <el-button
                type="text"
                class="ml-2 clear-button"
                @click.stop="clearOther"
              >
                <!-- {{ $t('filter.reset') }} -->
                <font-awesome-icon
                  icon="fa-regular fa-arrows-rotate"
                  size="lg"
                  class="mr-1"
                />
              </el-button>
            </div>
            <div
              class="p-2"
              v-show="open"
            >
              <font-awesome-icon
                v-if="section.other"
                icon="fa-regular fa-angle-up"
                size="lg"
              />
              <font-awesome-icon
                v-else
                icon="fa-regular fa-angle-down"
                size="lg"
              />
            </div>
          </div>
        </div>
        <div
          v-show="section.other"
          class="my-3 ml-2 mr-1"
        >
          <div v-show="open">

            <button
              id="btnSearchWithWI"
              type="button"
              data-genus="wi"
              value="withWI"
              class="btn btn-lg btn-filter btn-filter-wi text-center"
              :class="{ active: $store.getters.withWI }"
              :disabled="$store.getters.isFetching"
              @click="toggleWI('withWI')"
            >
              {{ $t('wi.with') }}
            </button>
            <button
              id="btnSearchWithoutWI"
              type="button"
              data-genus="wi"
              value="withoutWI"
              class="btn btn-lg btn-filter btn-filter-wi text-center"
              :class="{ active: $store.getters.withoutWI }"
              :disabled="$store.getters.isFetching"
              @click="toggleWI('withoutWI')"
            >
              {{ $t('wi.without') }}
            </button>

            <el-select
              :placeholder="$t('filter.interventionType')"
              class="mb-4 mt-3 width-94"
              v-model="interventionTypeValue"
              multiple
              clearable
              :disabled="$store.getters.isFetching"
            >
              <el-option
                v-for="item in interventionTypeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>

          </div>
        </div>
      </div>

      <div class="safety-margin"></div>

    </div>

    <!-- MOBILE BOTTOM CONTROLS -->
    <div class="fixed-bottom d-block d-lg-none d-xl-none d-md-none btn-container">
      <b-row>
        <b-col>
          <router-link to="/stats">
            <el-button class="btn-block btn-jumbo btn-white-blue-text">{{ $t('filter.search.stats') }}</el-button>
          </router-link>
        </b-col>
        <b-col>
          <router-link to="/explore">
            <el-button
              type="primary"
              class="btn-block btn-jumbo"
            >{{ $t('filter.search.reports') }}</el-button>
          </router-link>
        </b-col>
      </b-row>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axiosResource from '@/utils/axiosResource';
import levels from '../assets/data/levels';
import zones from '../assets/data/zones';
// import definitions from '../assets/data/definitions';
import locations from '../assets/data/locations';
import Moment from 'moment';

import { selectionMixin } from '@/mixins/selectionMixin';

import debounce from 'lodash/debounce';

export default {
  name: 'side-bar-filter',
  mixins: [selectionMixin],
  props: ['purpose'],
  data() {
    return {
      isMobile: false,
      needsFetch: true,
      loading: false,
      filterText: '',
      projectId: '',
      projectUuid: '',
      open: this.$store.getters.sidebar,
      section: {
        keyword: true,
        reference: true,
        result: true,
        status: true,
        program: false,
        when: true,
        who: false,
        other: false,
      },
      treeDefaultCheckedKeys: this.$store.getters.programDefaultCheckedKeys,
      treeData: [
        {
          id: 1,
          level: 1,
          family: 1,
          label: '1 GÉNÉRALITÉS',
        },
        {
          id: 2,
          level: 1,
          family: 2,
          label: '2 GROS OEUVRE',
        },
        {
          id: 3,
          level: 1,
          family: 3,
          label: '3 CLOS ET COUVERT',
        },
        {
          id: 4,
          level: 1,
          family: 4,
          label: '4 DIVISION - AMÉNAGEMENT',
        },
        {
          id: 5,
          level: 1,
          family: 5,
          label: '5 FINITIONS - REVÊTEMENT',
        },
        {
          id: 6,
          level: 1,
          family: 6,
          label: '6 LOTS TECHNIQUES',
        },
      ],
      defaultProps: {
        children: 'children',
        label: 'label',
      },
      buildingOptions: [],
      buildingValue: '',
      levelOptions: levels,
      levelValue: '',
      zoneOptions: zones,
      zoneValue: '',
      serial: this.$store.getters.serial,
      definitionOptions: [],
      definitionValue: '',
      interventionTypeOptions: [
        {
          value: 'IS',
          label: 'IS - Inspection systématique',
        },
        {
          value: 'INS',
          label: 'INS - Inspection non systématique',
        },
        {
          value: 'FC',
          label: 'FC - Fiche constat',
        },
        {
          value: 'ST',
          label: 'ST - Suivi de travaux',
        },
      ],
      interventionTypeValue: this.$store.getters.interventionType,
      entityOptions: [],
      entityValue: this.$store.getters.entity,
      inspectorOptions: [],
      inspectorValue: this.$store.getters.inspector,
      natureOptions: [
        {
          value: 'BM',
          label: 'BM',
        },
        {
          value: 'CONTROLLER',
          label: 'CONTROLLER',
        },
        {
          value: 'FIRM',
          label: 'FIRM',
        },
      ],
      natureValue: this.$store.getters.nature,
      locationOptions: locations,
      locationValue: [],
      pickerOptions: {
        shortcuts: [
          {
            text: 'Last week',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Last month',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Last 3 months',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            },
          },
        ],
      },
      interventionDateValue: '',
      dynamicTags: [...this.$store.getters.freeTag],
      inputVisible: false,
      keywordValue: '',
      dateStart: this.$store.getters.dateStart,
      dateEnd: this.$store.getters.dateEnd,
      withWI: this.$store.getters.withWI,
      withoutWI: this.$store.getters.witouthWI,
    };
  },
  created() {
    // Create a debounced version of the serial handler
    this.debouncedFetch = debounce(this.serialHandler, 500); // 500ms delay
    // const startOfMonth = moment().startOf('month').format('YYYY-MM-DD hh:mm');
    // alert(startOfMonth);
    // TODO
    // var begin = Moment().format('YYYY-MM-01');
    // if (this.$store.getters.dateStart == '') this.dateStart = begin;
  },
  mounted() {
    this.getBuildings();
    this.setFilters();
    this.fetchProgram();
    this.fetchEntities();
    this.fetchUsers();
    this.fetchDefinitions();

    this.checkIfMobile();
    window.addEventListener('resize', this.checkIfMobile);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkIfMobile);
  },
  watch: {
    buildingFromStore(newBuilding) {
      this.buildingValue = newBuilding.label;
    },
    async interventionTypeValue(arr) {
      this.$store.commit('setInterventionType', arr);
      if (this.purpose == 'EXPLORE') {
        // INIT STORE FOR SEARCH
        this.$store.commit('initPage');
        this.$store.commit('initReports');
        this.$store.commit('resetQttLoaded');
        // FETCH
        if (this.needsFetch) await this.$store.dispatch('fetchReportsGeneric');
      }
      if (this.purpose == 'STATS') {
        this.$store.dispatch('fetchStats', 'general');
        this.$store.dispatch('fetchStats', 'status');
        this.$store.dispatch('fetchStats', 'statusAP');
        this.$store.dispatch('fetchStats', 'locationHint');
        this.$store.dispatch('fetchStats', 'quickInteractions');
        this.$store.dispatch('fetchStats', 'detailedInteractions');
      }
    },
    async entityValue(arr) {
      this.$store.commit('setEntity', arr);
      if (this.purpose == 'EXPLORE') {
        // INIT STORE FOR SEARCH
        this.$store.commit('initPage');
        this.$store.commit('initReports');
        this.$store.commit('resetQttLoaded');
        // FETCH
        if (this.needsFetch) await this.$store.dispatch('fetchReportsGeneric');
      }

      if (this.purpose == 'STATS') {
        if (this.$store.getters.currentStatsView == 'program') {
          this.$store.dispatch('fetchStats', 'fetchCategoriesStats');
        } else if (this.$store.getters.currentStatsView == 'curve') {
          // console.log('need CURVE only');
        } else {
          this.$store.dispatch('fetchStats', 'general');
          this.$store.dispatch('fetchStats', 'status');
          this.$store.dispatch('fetchStats', 'statusAP');
          this.$store.dispatch('fetchStats', 'locationHint');
          this.$store.dispatch('fetchStats', 'quickInteractions');
          this.$store.dispatch('fetchStats', 'detailedInteractions');
        }
      }
    },
    async inspectorValue(arr) {
      this.$store.commit('setInspector', arr);
      if (this.purpose == 'EXPLORE') {
        // INIT STORE FOR SEARCH
        this.$store.commit('initPage');
        this.$store.commit('initReports');
        this.$store.commit('resetQttLoaded');
        // FETCH
        if (this.needsFetch) await this.$store.dispatch('fetchReportsGeneric');
      }
      if (this.purpose == 'STATS') {
        this.$store.dispatch('fetchStats', 'general');
        this.$store.dispatch('fetchStats', 'status');
        this.$store.dispatch('fetchStats', 'statusAP');
        this.$store.dispatch('fetchStats', 'locationHint');
        this.$store.dispatch('fetchStats', 'quickInteractions');
        this.$store.dispatch('fetchStats', 'detailedInteractions');
      }
    },
    async natureValue(arr) {
      this.$store.commit('setNature', arr);
      if (this.purpose == 'EXPLORE') {
        // INIT STORE FOR SEARCH
        this.$store.commit('initPage');
        this.$store.commit('initReports');
        this.$store.commit('resetQttLoaded');
        // FETCH
        if (this.needsFetch) await this.$store.dispatch('fetchReportsGeneric');
      }
      if (this.purpose == 'STATS') {
        this.$store.dispatch('fetchStats', 'general');
        this.$store.dispatch('fetchStats', 'status');
        this.$store.dispatch('fetchStats', 'statusAP');
        this.$store.dispatch('fetchStats', 'locationHint');
        this.$store.dispatch('fetchStats', 'quickInteractions');
        this.$store.dispatch('fetchStats', 'detailedInteractions');
      }
    },
    async dateStart(val) {
      this.$store.commit('setSelectionMode', false); // Exit selection
      let date;
      if (val !== null) {
        date = Moment(val).format('YYYY-MM-DD');
      } else {
        date = '';
      }
      this.$store.commit('setDateStart', date);
      if (this.purpose == 'EXPLORE') {
        // INIT STORE FOR SEARCH
        this.$store.commit('initPage');
        this.$store.commit('initReports');
        this.$store.commit('resetQttLoaded');
        // FETCH
        if (this.needsFetch) await this.$store.dispatch('fetchReportsGeneric');
      }
      if (this.purpose == 'STATS') {
        this.$store.dispatch('fetchStats', 'general');
        this.$store.dispatch('fetchStats', 'status');
        this.$store.dispatch('fetchStats', 'statusAP');
        this.$store.dispatch('fetchStats', 'locationHint');
        this.$store.dispatch('fetchStats', 'quickInteractions');
        this.$store.dispatch('fetchStats', 'detailedInteractions');
      }
    },
    async dateEnd(val) {
      this.$store.commit('setSelectionMode', false); // Exit selection
      let date;
      if (val !== null) {
        date = Moment(val).format('YYYY-MM-DD');
      } else {
        date = '';
      }
      this.$store.commit('setDateEnd', date);
      if (this.purpose == 'EXPLORE') {
        // INIT STORE FOR SEARCH
        this.$store.commit('initPage');
        this.$store.commit('initReports');
        this.$store.commit('resetQttLoaded');
        // FETCH
        if (this.needsFetch) await this.$store.dispatch('fetchReportsGeneric');
      }
      if (this.purpose == 'STATS') {
        this.$store.dispatch('fetchStats', 'general');
        this.$store.dispatch('fetchStats', 'status');
        this.$store.dispatch('fetchStats', 'statusAP');
        this.$store.dispatch('fetchStats', 'locationHint');
        this.$store.dispatch('fetchStats', 'quickInteractions');
        this.$store.dispatch('fetchStats', 'detailedInteractions');
      }
    },
    async withWI(val) {
      this.$store.commit('setSelectionMode', false); // Exit selection
      this.$store.commit('setWithWI', val);
      if (this.purpose == 'EXPLORE') {
        // INIT STORE FOR SEARCH
        this.$store.commit('initPage');
        this.$store.commit('initReports');
        this.$store.commit('resetQttLoaded');
        // FETCH
        if (this.needsFetch) await this.$store.dispatch('fetchReportsGeneric');
      }
      if (this.purpose == 'STATS') {
        this.$store.dispatch('fetchStats', 'general');
        this.$store.dispatch('fetchStats', 'status');
        this.$store.dispatch('fetchStats', 'statusAP');
        this.$store.dispatch('fetchStats', 'locationHint');
        this.$store.dispatch('fetchStats', 'quickInteractions');
        this.$store.dispatch('fetchStats', 'detailedInteractions');
      }
    },
    filterText(val) {
      this.$refs.tree.filter(val);
    },
    async serial(val) {
      this.$store.commit('setSelectionMode', false); // Exit selection
      this.$store.commit('setSerial', val);
      if (this.purpose == 'EXPLORE') {
        // INIT STORE FOR SEARCH
        this.$store.commit('initPage');
        this.$store.commit('initReports');
        this.$store.commit('resetQttLoaded');
        // FETCH
        if (this.needsFetch) await this.$store.dispatch('fetchReportsGeneric');
      }
      if (this.purpose == 'STATS') {
        if (this.purpose == 'STATS') {
          this.$store.dispatch('fetchStats', 'general');
          this.$store.dispatch('fetchStats', 'status');
          this.$store.dispatch('fetchStats', 'statusAP');
          this.$store.dispatch('fetchStats', 'locationHint');
          this.$store.dispatch('fetchStats', 'quickInteractions');
          this.$store.dispatch('fetchStats', 'detailedInteractions');
        }
      }
    },
  },
  computed: {
    // ...mapGetters(['section']),
    exactMatch: {
      get() {
        return this.$store.getters.exactMatch;
      },
      set(value) {
        this.updateExactMatch(value);
      },
    },
    buildingFromStore() {
      return this.$store.getters.building;
    },
  },
  methods: {
    async toggleWI(type) {
      // Toggle the specified type
      const currentState = this.$store.getters[type];
      this.$store.commit(`SET_${type.toUpperCase()}`, !currentState);

      // Perform additional logic based on the purpose
      this.$store.commit('setSelectionMode', false); // Exit selection
      if (this.purpose === 'EXPLORE') {
        this.$store.commit('initPage');
        this.$store.commit('initReports');
        this.$store.commit('resetQttLoaded');
        if (this.needsFetch) await this.$store.dispatch('fetchReportsGeneric');
      } else if (this.purpose === 'STATS') {
        await Promise.all([
          this.$store.dispatch('fetchStats', 'general'),
          this.$store.dispatch('fetchStats', 'status'),
          this.$store.dispatch('fetchStats', 'statusAP'),
          this.$store.dispatch('fetchStats', 'locationHint'),
          this.$store.dispatch('fetchStats', 'quickInteractions'),
          this.$store.dispatch('fetchStats', 'detailedInteractions'),
        ]);
      }
    },
    onInput(val) {
      this.serial = val;
      this.debouncedFetch(val);
    },
    checkIfMobile() {
      this.isMobile = window.innerWidth < 768; // Assuming mobile devices are less than 768px
    },
    async updateExactMatch(value) {
      this.$store.commit('setSelectionMode', false); // Exit selection
      this.$store.commit('SET_EXACT_MATCH', value);

      if (this.purpose == 'EXPLORE') {
        // INIT STORE FOR SEARCH
        this.$store.commit('initPage');
        this.$store.commit('initReports');
        this.$store.commit('resetQttLoaded');
        // FETCH
        if (fetch) await this.$store.dispatch('fetchReportsGeneric');
      }
      if (this.purpose == 'STATS') {
        this.$store.dispatch('fetchStats', 'general');
        this.$store.dispatch('fetchStats', 'status');
        this.$store.dispatch('fetchStats', 'statusAP');
        this.$store.dispatch('fetchStats', 'locationHint');
        this.$store.dispatch('fetchStats', 'quickInteractions');
        this.$store.dispatch('fetchStats', 'detailedInteractions');
      }
    },
    resetExactMatch() {
      this.$store.commit('setSelectionMode', false); // Exit selection
      this.$store.dispatch('resetExactMatch');
    },
    async clearAllFilters() {
      this.$store.commit('setSelectionMode', false); // Exit selection
      // Prevent dispatch('fetchReports') in WATCH
      this.needsFetch = false;
      // Keywords
      this.clearKeywords(false);
      // Location
      this.clearLocation(false);
      // Result
      this.clearResult(false);
      // Status
      this.clearStatus(false);
      // Program
      this.resetTree(false);
      // When
      this.clearWhen();
      // Who
      this.clearWho();
      // Other
      this.clearOther();
      // INIT STORE FOR SEARCH (SECURITY)
      this.$store.commit('initPage');
      this.$store.commit('initReports');
      this.$store.commit('resetQttLoaded');
      // FETCH
      await this.$store.dispatch('fetchReportsGeneric');
    },
    async clearStatus(fetch) {
      this.$store.commit('setSelectionMode', false); // Exit selection
      this.$store.dispatch('clearStatus');
      if (this.purpose == 'EXPLORE') {
        // INIT STORE FOR SEARCH
        this.$store.commit('initPage');
        this.$store.commit('initReports');
        this.$store.commit('resetQttLoaded');
        // FETCH
        if (fetch) await this.$store.dispatch('fetchReportsGeneric');
      }
      if (this.purpose == 'STATS') {
        this.$store.dispatch('fetchStats', 'general');
        this.$store.dispatch('fetchStats', 'status');
        this.$store.dispatch('fetchStats', 'statusAP');
        this.$store.dispatch('fetchStats', 'locationHint');
        this.$store.dispatch('fetchStats', 'quickInteractions');
        this.$store.dispatch('fetchStats', 'detailedInteractions');
      }
    },
    async clearResult(fetch) {
      this.$store.commit('setSelectionMode', false); // Exit selection
      this.$store.dispatch('clearResult');

      if (this.purpose == 'EXPLORE') {
        // INIT STORE FOR SEARCH
        this.$store.commit('initPage');
        this.$store.commit('initReports');
        this.$store.commit('resetQttLoaded');
        // FETCH
        if (fetch) await this.$store.dispatch('fetchReportsGeneric');
      }
      if (this.purpose == 'STATS') {
        this.$store.dispatch('fetchStats', 'general');
        this.$store.dispatch('fetchStats', 'status');
        this.$store.dispatch('fetchStats', 'statusAP');
        this.$store.dispatch('fetchStats', 'locationHint');
        this.$store.dispatch('fetchStats', 'quickInteractions');
        this.$store.dispatch('fetchStats', 'detailedInteractions');
      }
    },
    clearWhen() {
      this.$store.commit('setSelectionMode', false); // Exit selection
      this.dateStart = null;
      this.dateEnd = null;
      // INIT STORE FOR SEARCH (SECURITY)
      this.$store.commit('initPage');
      this.$store.commit('initReports');
      this.$store.commit('resetQttLoaded');
    },
    async clearKeywords(fetch) {
      this.$store.commit('setSelectionMode', false); // Exit selection
      this.dynamicTags = [];
      this.$store.commit('setFreeTag', this.dynamicTags);
      if (this.purpose == 'EXPLORE') {
        // INIT STORE FOR SEARCH
        this.$store.commit('initPage');
        this.$store.commit('initReports');
        this.$store.commit('resetQttLoaded');
        // FETCH
        if (fetch) await this.$store.dispatch('fetchReportsGeneric');
      }
      if (this.purpose == 'STATS') {
        this.$store.dispatch('fetchStats', 'general');
        this.$store.dispatch('fetchStats', 'status');
        this.$store.dispatch('fetchStats', 'statusAP');
        this.$store.dispatch('fetchStats', 'locationHint');
        this.$store.dispatch('fetchStats', 'quickInteractions');
        this.$store.dispatch('fetchStats', 'detailedInteractions');
      }
    },
    async handleClose(tag) {
      this.$store.commit('setSelectionMode', false); // Exit selection
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
      this.$store.commit('setFreeTag', this.dynamicTags);
      if (this.purpose == 'EXPLORE') {
        // INIT STORE FOR SEARCH
        this.$store.commit('initPage');
        this.$store.commit('initReports');
        this.$store.commit('resetQttLoaded');
        // FETCH
        await this.$store.dispatch('fetchReportsGeneric');
      }
      if (this.purpose == 'STATS') {
        this.$store.dispatch('fetchStats', 'general');
        this.$store.dispatch('fetchStats', 'status');
        this.$store.dispatch('fetchStats', 'statusAP');
        this.$store.dispatch('fetchStats', 'locationHint');
        this.$store.dispatch('fetchStats', 'quickInteractions');
        this.$store.dispatch('fetchStats', 'detailedInteractions');
      }
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    async handleInputConfirm() {
      this.$store.commit('setSelectionMode', false); // Exit selection
      let inputValue = this.keywordValue;
      if (inputValue) {
        this.dynamicTags.push(inputValue);
        this.$store.commit('setFreeTag', this.dynamicTags);

        if (this.purpose == 'EXPLORE') {
          // INIT STORE FOR SEARCH
          this.$store.commit('initPage');
          this.$store.commit('initReports');
          this.$store.commit('resetQttLoaded');
          // FETCH
          await this.$store.dispatch('fetchReportsGeneric');
        }
        if (this.purpose == 'STATS') {
          this.$store.dispatch('fetchStats', 'general');
          this.$store.dispatch('fetchStats', 'status');
          this.$store.dispatch('fetchStats', 'statusAP');
          this.$store.dispatch('fetchStats', 'locationHint');
          this.$store.dispatch('fetchStats', 'quickInteractions');
          this.$store.dispatch('fetchStats', 'detailedInteractions');
        }
      }
      this.inputVisible = false;
      this.keywordValue = '';
    },
    fetchDefinitions() {
      const projectUuid = this.$store.getters.currentProject.uuid;
      // INITIAL CALL TO GET ACCESS TOKEN
      axiosResource
        .get(`/admin/definitions/accessLess/${projectUuid}`)
        .then((response) => {
          // this.definitionOptions = response.data;
          response.data.forEach((obj) => {
            this.definitionOptions.push({ value: obj.name, label: obj.name });
          });
        })
        .catch((err) => {
          if (err.response.data.msg == 'error.noIntegrityToken') {
            this.$bvModal.show('modal-integrity');
          } else {
            this.$globalSwal.error(err.response.data.msg);
          }
        });
    },
    async handleProgramCheckChange(data, checked) {
      this.$store.commit('setSelectionMode', false); // Exit selection
      if (checked) {
        this.$store.commit('setProgramChecked', data.uuid);
        this.$store.dispatch({
          type: 'addProgram',
          rubric: data.level,
          value: data.label,
        });

        if (this.purpose == 'EXPLORE') {
          // INIT STORE FOR SEARCH
          this.$store.commit('initPage');
          this.$store.commit('initReports');
          this.$store.commit('resetQttLoaded');
          // FETCH
          if (fetch) await this.$store.dispatch('fetchReportsGeneric');
        }
        if (this.purpose == 'STATS') {
          this.$store.dispatch('fetchStats', 'general');
          this.$store.dispatch('fetchStats', 'status');
          this.$store.dispatch('fetchStats', 'statusAP');
          this.$store.dispatch('fetchStats', 'locationHint');
          this.$store.dispatch('fetchStats', 'quickInteractions');
          this.$store.dispatch('fetchStats', 'detailedInteractions');
        }
      } else {
        this.$store.commit('removeProgramChecked', data.uuid);
        this.$store.dispatch({
          type: 'removeProgram',
          rubric: data.level,
          value: data.label,
        });

        if (this.purpose == 'EXPLORE') {
          // INIT STORE FOR SEARCH
          this.$store.commit('initPage');
          this.$store.commit('initReports');
          this.$store.commit('resetQttLoaded');
          // FETCH
          if (fetch) await this.$store.dispatch('fetchReportsGeneric');
        }
        if (this.purpose == 'STATS') {
          this.$store.dispatch('fetchStats', 'general');
          this.$store.dispatch('fetchStats', 'status');
          this.$store.dispatch('fetchStats', 'statusAP');
          this.$store.dispatch('fetchStats', 'locationHint');
          this.$store.dispatch('fetchStats', 'quickInteractions');
          this.$store.dispatch('fetchStats', 'detailedInteractions');
        }
      }
    },
    async clearLocation(fetch) {
      this.$store.commit('setSelectionMode', false); // Exit selection
      this.buildingValue = '';
      this.levelValue = '';
      this.zoneValue = '';
      this.serial = '';
      this.definitionValue = '';
      this.locationValue = [];
      this.$store.commit('setBuilding', { uuid: null, label: null });
      this.$store.commit('setLevel', '');
      this.$store.commit('setZone', '');
      this.$store.commit('setSerial', '');
      this.$store.commit('setDefinition', { value: null, label: null });
      this.$store.commit('setLocation', []);
      if (this.purpose == 'EXPLORE') {
        // INIT STORE FOR SEARCH
        this.$store.commit('initPage');
        this.$store.commit('initReports');
        this.$store.commit('resetQttLoaded');
        // FETCH
        if (fetch) await this.$store.dispatch('fetchReportsGeneric');
      }
      if (this.purpose == 'STATS') {
        this.$store.dispatch('fetchStats', 'general');
        this.$store.dispatch('fetchStats', 'status');
        this.$store.dispatch('fetchStats', 'statusAP');
        this.$store.dispatch('fetchStats', 'locationHint');
        this.$store.dispatch('fetchStats', 'quickInteractions');
        this.$store.dispatch('fetchStats', 'detailedInteractions');
      }
    },
    setFilters() {
      this.buildingValue = this.$store.getters.building.label;
      this.levelValue = this.$store.getters.level;
      this.zoneValue = this.$store.getters.zone;
      this.definitionValue = this.$store.getters.definition.value;
      this.locationValue = this.$store.getters.location;
    },
    async buildingChange(uuid) {
      this.$store.commit('setSelectionMode', false); // Exit selection
      if (uuid != '') {
        const item = this.buildingOptions.filter((item) => {
          return item.uuid == uuid;
        });
        const ref = item[0].ref;
        this.$store.commit('setBuilding', { uuid: uuid, label: ref });
      } else {
        this.$store.commit('setBuilding', { uuid: null, label: null });
      }
      if (this.purpose == 'EXPLORE') {
        // INIT STORE FOR SEARCH
        this.$store.commit('initPage');
        this.$store.commit('initReports');
        this.$store.commit('resetQttLoaded');
        // FETCH
        await this.$store.dispatch('fetchReportsGeneric');
      }
      if (this.purpose == 'STATS') {
        if (this.$store.getters.currentStatsView == 'program') {
          this.$store.dispatch('fetchStats', 'fetchCategoriesStats');
        } else if (this.$store.getters.currentStatsView == 'curve') {
          // This filter does not apply to CURVE stats
        } else {
          this.$store.dispatch('fetchStats', 'general');
          this.$store.dispatch('fetchStats', 'status');
          this.$store.dispatch('fetchStats', 'statusAP');
          this.$store.dispatch('fetchStats', 'locationHint');
          this.$store.dispatch('fetchStats', 'quickInteractions');
          this.$store.dispatch('fetchStats', 'detailedInteractions');
        }
      }
    },
    async levelChange(val) {
      this.$store.commit('setSelectionMode', false); // Exit selection
      this.$store.commit('setLevel', val);
      if (this.purpose == 'EXPLORE') {
        // INIT STORE FOR SEARCH
        this.$store.commit('initPage');
        this.$store.commit('initReports');
        this.$store.commit('resetQttLoaded');
        // FETCH
        await this.$store.dispatch('fetchReportsGeneric');
      }
      if (this.purpose == 'STATS') {
        this.$store.dispatch('fetchStats', 'general');
        this.$store.dispatch('fetchStats', 'status');
        this.$store.dispatch('fetchStats', 'statusAP');
        this.$store.dispatch('fetchStats', 'locationHint');
        this.$store.dispatch('fetchStats', 'quickInteractions');
        this.$store.dispatch('fetchStats', 'detailedInteractions');
      }
    },
    async zoneChange(val) {
      this.$store.commit('setSelectionMode', false); // Exit selection
      this.$store.commit('setZone', val);
      if (this.purpose == 'EXPLORE') {
        // INIT STORE FOR SEARCH
        this.$store.commit('initPage');
        this.$store.commit('initReports');
        this.$store.commit('resetQttLoaded');
        // FETCH
        await this.$store.dispatch('fetchReportsGeneric');
      }
      if (this.purpose == 'STATS') {
        this.$store.dispatch('fetchStats', 'general');
        this.$store.dispatch('fetchStats', 'status');
        this.$store.dispatch('fetchStats', 'statusAP');
        this.$store.dispatch('fetchStats', 'locationHint');
        this.$store.dispatch('fetchStats', 'quickInteractions');
        this.$store.dispatch('fetchStats', 'detailedInteractions');
      }
    },
    // async serialChange(val) {
    //   this.$store.commit('setSelectionMode', false); // Exit selection
    //   this.$store.commit('setSerial', val);
    //   if (this.purpose == 'EXPLORE') {
    //     // INIT STORE FOR SEARCH
    //     this.$store.commit('initPage');
    //     this.$store.commit('initReports');
    //     this.$store.commit('resetQttLoaded');
    //     // FETCH
    //     await this.$store.dispatch('fetchReportsGeneric');
    //   }
    //   if (this.purpose == 'STATS') {
    //     this.$store.dispatch('fetchStats', 'general');
    //     this.$store.dispatch('fetchStats', 'status');
    //     this.$store.dispatch('fetchStats', 'statusAP');
    //     this.$store.dispatch('fetchStats', 'locationHint');
    //     this.$store.dispatch('fetchStats', 'quickInteractions');
    //     this.$store.dispatch('fetchStats', 'detailedInteractions');
    //   }
    // },
    async serialHandler(val) {
      this.$store.commit('setSelectionMode', false); // Exit selection
      this.$store.commit('setSerial', val);

      if (this.purpose == 'EXPLORE') {
        // INIT STORE FOR SEARCH
        this.$store.commit('initPage');
        this.$store.commit('initReports');
        this.$store.commit('resetQttLoaded');

        if (this.needsFetch) {
          await this.$store.dispatch('fetchReportsGeneric');
        }
      }

      if (this.purpose == 'STATS') {
        this.$store.dispatch('fetchStats', 'general');
        this.$store.dispatch('fetchStats', 'status');
        this.$store.dispatch('fetchStats', 'statusAP');
        this.$store.dispatch('fetchStats', 'locationHint');
        this.$store.dispatch('fetchStats', 'quickInteractions');
        this.$store.dispatch('fetchStats', 'detailedInteractions');
      }
    },

    async definitionChange(val) {
      this.$store.commit('setSelectionMode', false); // Exit selection
      if (val != '') {
        const item = this.definitionOptions.filter((item) => {
          return item.label == val;
        });
        const label = item[0].label;
        this.$store.commit('setDefinition', { value: val, label: label });
        if (this.purpose == 'EXPLORE') {
          // INIT STORE FOR SEARCH
          this.$store.commit('initPage');
          this.$store.commit('initReports');
          this.$store.commit('resetQttLoaded');
          // FETCH
          await this.$store.dispatch('fetchReportsGeneric');
        }
        if (this.purpose == 'STATS') {
          this.$store.dispatch('fetchStats', 'general');
          this.$store.dispatch('fetchStats', 'status');
          this.$store.dispatch('fetchStats', 'statusAP');
          this.$store.dispatch('fetchStats', 'locationHint');
          this.$store.dispatch('fetchStats', 'quickInteractions');
          this.$store.dispatch('fetchStats', 'detailedInteractions');
        }
      } else {
        this.$store.commit('setDefinition', { value: null, label: null });
        if (this.purpose == 'EXPLORE') {
          // INIT STORE FOR SEARCH
          this.$store.commit('initPage');
          this.$store.commit('initReports');
          this.$store.commit('resetQttLoaded');
          // FETCH
          await this.$store.dispatch('fetchReportsGeneric');
        }
        if (this.purpose == 'STATS') {
          this.$store.dispatch('fetchStats', 'general');
          this.$store.dispatch('fetchStats', 'status');
          this.$store.dispatch('fetchStats', 'statusAP');
          this.$store.dispatch('fetchStats', 'locationHint');
          this.$store.dispatch('fetchStats', 'quickInteractions');
          this.$store.dispatch('fetchStats', 'detailedInteractions');
        }
      }
    },
    async changeLocation(array) {
      this.$store.commit('setSelectionMode', false); // Exit selection
      this.$store.commit('setLocation', array);
      if (this.purpose == 'EXPLORE') {
        // INIT STORE FOR SEARCH
        this.$store.commit('initPage');
        this.$store.commit('initReports');
        this.$store.commit('resetQttLoaded');
        // FETCH
        await this.$store.dispatch('fetchReportsGeneric');
      }
      if (this.purpose == 'STATS') {
        this.$store.dispatch('fetchStats', 'general');
        this.$store.dispatch('fetchStats', 'status');
        this.$store.dispatch('fetchStats', 'statusAP');
        this.$store.dispatch('fetchStats', 'locationHint');
        this.$store.dispatch('fetchStats', 'quickInteractions');
        this.$store.dispatch('fetchStats', 'detailedInteractions');
      }
    },
    async resetTree(fetch) {
      this.$store.commit('setSelectionMode', false); // Exit selection
      this.$refs.tree.setCheckedKeys([]);
      this.filterText = '';
      this.$store.dispatch('clearProgram');
      this.$store.commit('resetProgramChecked');

      // Reset exactMatch
      this.$store.dispatch('resetExactMatch');

      this.$nextTick(() => {
        this.$forceUpdate();
      });

      if (this.purpose == 'EXPLORE') {
        // INIT STORE FOR SEARCH
        this.$store.commit('initPage');
        this.$store.commit('initReports');
        this.$store.commit('resetQttLoaded');
        // FETCH
        if (fetch) await this.$store.dispatch('fetchReportsGeneric');
      }
      if (this.purpose == 'STATS') {
        this.$store.dispatch('fetchStats', 'general');
        this.$store.dispatch('fetchStats', 'status');
        this.$store.dispatch('fetchStats', 'statusAP');
        this.$store.dispatch('fetchStats', 'locationHint');
        this.$store.dispatch('fetchStats', 'quickInteractions');
        this.$store.dispatch('fetchStats', 'detailedInteractions');
      }
    },
    clearWho() {
      this.$store.commit('setSelectionMode', false); // Exit selection
      this.entityValue = [];
      this.natureValue = [];
      this.inspectorValue = [];
      // INIT STORE FOR SEARCH (SECURITY)
      this.$store.commit('initPage');
      this.$store.commit('initReports');
      this.$store.commit('resetQttLoaded');
    },
    clearOther() {
      this.$store.commit('setSelectionMode', false); // Exit selection
      this.interventionTypeValue = [];
      this.$store.commit('SET_WITHWI', false); // Reset withWI to false
      this.$store.commit('SET_WITHOUTWI', false); // Reset withoutWI to false
      // INIT STORE FOR SEARCH (SECURITY)
      this.$store.commit('initPage');
      this.$store.commit('initReports');
      this.$store.commit('resetQttLoaded');
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.toLowerCase().indexOf(value) !== -1;
    },
    stateDisplay() {
      // States are search or explore
    },
    adjustSidebar() {
      let ele = document.getElementById('wrapper-filter');
      if (window.innerWidth < 768) {
        // ele.setAttribute('style', 'padding-left: 15px !important;');
      } else if (window.innerWidth >= 768) {
        if (this.open) {
          ele.setAttribute('style', 'padding-left: 380px !important;');
        } else {
          ele.setAttribute('style', 'padding-left: 60px !important;');
        }
      }
    },
    active(subsection) {
      const { path } = this.$route;
      const pathArray = path.split('/');
      const routeSubSection = pathArray[2];
      return routeSubSection == subsection ? true : false;
    },
    async toggleActive(e) {
      this.$store.commit('setSelectionMode', false); // Exit selection

      const el = e.currentTarget;
      const genus = el.getAttribute('data-genus');
      const value = el.value;

      // Check if fetching is in progress
      if (this.$store.getters.isFetching) {
        console.warn('Fetch already in progress. Skipping toggle.');
        return;
      }

      // Toggle the Vuex state for the button
      if (genus === 'priority') {
        this.$store.commit('togglePriority', value);
      } else if (genus === 'quick') {
        this.$store.commit('toggleQuick', value);
      } else if (genus === 'status') {
        this.$store.commit('toggleStatus', value);
      }

      // Trigger fetching logic only if needed
      if (this.purpose === 'EXPLORE') {
        // INIT STORE FOR SEARCH
        this.$store.commit('initPage');
        this.$store.commit('initReports');
        this.$store.commit('resetQttLoaded');

        // Fetch reports
        try {
          await this.$store.dispatch('fetchReportsGeneric');
        } catch (error) {
          console.error('Failed to fetch reports:', error);
          // Optionally, revert the toggle in case of an error
          if (genus === 'priority') {
            this.$store.commit('togglePriority', value); // Revert
          } else if (genus === 'quick') {
            this.$store.commit('toggleQuick', value); // Revert
          } else if (genus === 'status') {
            this.$store.commit('toggleStatus', value); // Revert
          }
        }
      } else if (this.purpose === 'STATS') {
        // Fetch stats
        this.$store.dispatch('fetchStats', 'general');
        this.$store.dispatch('fetchStats', 'status');
        this.$store.dispatch('fetchStats', 'statusAP');
        this.$store.dispatch('fetchStats', 'locationHint');
        this.$store.dispatch('fetchStats', 'quickInteractions');
        this.$store.dispatch('fetchStats', 'detailedInteractions');
      }
    },
    toggleSection(e) {
      let section;
      if (e.target !== e.currentTarget) {
        section = e.currentTarget.id;
      } else {
        section = e.target.id;
      }
      switch (section) {
        case 'keyword':
          this.section.keyword = !this.section.keyword;
          break;
        case 'reference':
          this.section.reference = !this.section.reference;
          break;
        case 'result':
          this.section.result = !this.section.result;
          break;
        case 'status':
          this.section.status = !this.section.status;
          break;
        case 'program':
          this.section.program = !this.section.program;
          break;
        case 'when':
          this.section.when = !this.section.when;
          break;
        case 'who':
          this.section.who = !this.section.who;
          break;
        case 'other':
          this.section.other = !this.section.other;
          break;
        default:
          break;
      }
    },
    getBuildings() {
      this.loading = true;
      this.projectId = this.$store.getters.currentProject.id;
      this.projectUuid = this.$store.getters.currentProject.uuid;
      axiosResource
        .get('/admin/buildings/building/accessLess/' + this.projectUuid)
        .then((res) => {
          this.buildingOptions = res.data;
        })
        .catch((err) => {
          this.loading = false;
          this.$globalSwal.error(err.response.data.msg);
        });
    },
    fetchEntities() {
      axiosResource
        .get('/admin/entities/accessLess')
        .then((response) => {
          const entitiesObj = response.data;
          entitiesObj.forEach((element) => {
            this.entityOptions.push({
              value: element.entity.uuid,
              label: element.entity.name,
            });
          });
        })
        .catch((err) => {
          this.loading = false;
          this.$globalSwal.error(err.response.data.msg);
        });
    },
    fetchUsers() {
      axiosResource
        .get('/admin/users/accessLess')
        .then((response) => {
          const usersObj = response.data;
          usersObj.forEach((element) => {
            this.inspectorOptions.push({
              value: element.uuid,
              label: element.name.complete,
            });
          });
        })
        .catch((err) => {
          this.loading = false;
          this.$globalSwal.error(err.response.data.msg);
        });
    },
    fetchProgram() {
      axiosResource
        .get(`/admin/categories/all/accessLess/${this.projectUuid}/true`)
        .then((response) => {
          if (!response.data.success) {
            this.loading = false;
            this.$globalSwal.error('error.sorry', response.data.message);
          } else {
            this.treeData = response.data.results;

            this.loading = false;
          }
        })
        .catch((err) => {
          this.loading = false;
          this.$globalSwal.error(err.response.data.msg);
        });
    },
  },
};
</script>
<style lang="scss">
.col-small-margin {
  padding-right: 6px !important;
  padding-left: 6px !important;
}
.el-input--suffix .el-input__inner {
  padding-right: 30px !important;
  // border-radius: 12px;
}
.el-input__inner {
  // border-radius: 12px;
  // border-top-left-radius: 12px;
  // border-top-right-radius: 12px;
  // border-bottom-right-radius: 12px;
  // border-bottom-left-radius: 12px;
}
.el-popper[x-placement^='bottom'] {
  margin-top: 0;
}

.el-popper .popper__arrow,
.el-popper .popper__arrow::after {
  display: none;
}

.el-select-dropdown {
  border-radius: 12px !important;
  border-top-left-radius: 12px !important;
  border-top-right-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
  border-bottom-left-radius: 12px !important;
  /* margin: 5px 0; */
}

.el-input__inner {
  font-weight: 600 !important;
}
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: pink;
}

.el-select .el-input .el-select__caret {
  // color: rgb(4, 17, 29);
  // font-weight: 700;
  // font-size: 14px;
  transition: transform 0.3s;
  transform: rotateZ(180deg);
  cursor: pointer;
}

.el-select .el-input .el-select__caret {
  // color: rgb(4, 17, 29);
  // font-weight: 900;
  // font-size: 18px;
}
.el-input__suffix {
  right: 7px;
}
.el-select .el-input.is-focus .el-input__inner {
  border-color: rgb(229, 232, 235);
}
.el-select .el-input__inner:focus {
  border-color: rgb(229, 232, 235);
}
.el-button-group > .el-button:first-child {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}
.el-button-group > .el-button:last-child {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}
</style>
<style scoped>

.el-button--text {
    color: #9a9a9a; /* rgb(4, 17, 29) */
}
.el-button--text:hover {
    color: #409EFF;
}
.btn-container {
  background: rgb(255, 255, 255);
  border-top: 1px solid rgb(229, 232, 235);
  padding-left: 18px;
  padding-right: 18px;
  padding-top: 14px;
  padding-bottom: 22px;
  background-color: white;
}
.btn-jumbo {
  border-radius: 12px;
  padding: 17px 24px;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.01em;
}
.btn-white-blue-text{
  color: rgb(32, 129, 226);
}

.filter-section-button {
  color: rgb(4, 17, 29);
  padding: 0px 10px;
  height: 48px;
  font-size: 16px;
  background: rgb(255, 255, 255);
  text-align: left;
  width: 100%;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 10px;
  border-top: 1px solid rgb(229, 232, 235);
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-weight: 600;
  user-select: none;
  background-color: #ffff;
  border: 0px;
}
.filter-section-button:hover {
  background: rgba(229, 232, 235, 0.4);
  /* background: rgba(229, 232, 235, 0.2); */
}

.fsb-reset {
  position: sticky;
    top: 0;
    z-index: 10;
}

.el-input__inner {
  border-radius: 12px !important;
}

.input-tag-wrapper {
  padding: 6px !important;
}
.el-input-tag {
    min-height: 38px !important;
}

/* Free keywords */

.tag-container {
 /* width: 100%; */
 margin-bottom: 6px;
}





.button-new-tag {
    margin-left: 0px !important;
    height: 32px !important;
    font-size: 16px;
    line-height: 30px;
    margin-top: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-right: 15px !important;
    padding-left: 15px !important;
    border-radius: 0 !important;
}



/* .button-new-tag {
  height: 32px !important;
  line-height: 30px;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
} */

.input-new-tag {
  width: 90px !important;
  /* border: 1px solid #DCDFE6 !important; */
  margin-left: 0 !important;
  vertical-align: bottom !important;
}



.date-picker {
 width: calc(100% - 60px);
}
.input-group-text {
  width: 60px;;
}
.date-picker-prefix {
  width: 60px;
}

.clear-button {
  font-size: 12px; z-index: 1011;
}
.clear-icon-margin {
  margin-right: 38px;
}

</style>

<style scoped>
.switch-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 24px;
  margin-bottom: 12px;
}

.switch-label {
  margin-right: 10px; /* Space between text and switch */
  font-size: 1rem;
}
</style>