import axiosResource from '@/utils/axiosResource';
import swal from 'sweetalert2';
import moment from 'moment';
const swalBootstrap = swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary btn-swal',
    denyButton: 'btn btn-info btn-swal',
    cancelButton: 'btn btn-light btn-swal',
  },
  buttonsStyling: false,
});

export const reportActionMixin = {
  methods: {
    async handleReportCommand(command, reportUuid = null) {
      const clgStyle = 'color: white;background-color: gray;';
      console.log('%c' + 'handleReportCommand: ' + command, clgStyle);
      if (command == 'pdfSimple') {
        // await this.generatePDFsSequentially(selectedReportsFull);
        await this.servePDF();
      } else if (command == 'pdfHistory') {
        this.notReady();
      } else if (command == 'pdfSynthesis') {
        this.synthesisPDF();
      } else if (command == 'xlsExport') {
        this.xlsExport('withPhoto');
        // === EXPORT WITHOUT PHOTO DISABLED IN FRONT END
        // swalBootstrap
        //   .fire({
        //     title: this.$t('command.xlsExport', [
        //       this.$store.getters.selectedCount,
        //     ]),
        //     text: this.$t('command.xlsZip'),
        //     showDenyButton: true,
        //     showCancelButton: true,
        //     confirmButtonText: this.$t('command.withPhoto'),
        //     denyButtonText: this.$t('command.withoutPhoto'),
        //     icon: 'question',
        //   })
        //   .then((result) => {
        //     /* Read more about isConfirmed, isDenied below */
        //     if (result.isConfirmed) {
        //       this.xlsExport('withPhoto');
        //     } else if (result.isDenied) {
        //       this.xlsExport('withoutPhoto');
        //     }
        //   });
      } else if (command == 'ADDRESSED') {
        this.$store.commit('setReportUuid', reportUuid); // Store reportUuid in Vuex (for single action)
        console.log(
          `this.$store.getters.reportUuid`,
          this.$store.getters.reportUuid
        );
        this.$bvModal.show('modal-address'); // Open the modal
      } else if (command == 'WI') {
        this.$store.commit('setReportUuid', reportUuid); // Store reportUuid in Vuex (for single action)
        this.$bvModal.show('modal-wi'); // Open the modal
      } else if (command == 'EDIT') {
        this.editReport(reportUuid);
      } else if (command == 'COMMENT') {
        this.$store.commit('setReportUuid', reportUuid); // Store reportUuid in Vuex (for single action)
        this.$bvModal.show('modal-comments');
      } else if (command == 'closurePhoto') {
        this.notReady();
      } else if (command == 'solvedPhoto') {
        this.notReady();
      } else if (command == 'attachment') {
        this.notReady();
      } else if (command == 'customStatus') {
        this.notReady();
      } else {
        this.actionChangeStatus(command, reportUuid);
      }
    },
    actionChangeStatus(status, reportUuid = null) {
      let count;
      if (reportUuid) {
        count = 1;
      } else {
        count = this.$store.getters.selectedCount;
      }

      swalBootstrap
        .fire({
          title: this.$tc('modal.changeStatus.setReportStatus', count, {
            count: count,
            status: this.$t(`status.${status}`),
          }),
          showConfirmButton: true,
          showCancelButton: true,
          icon: 'question',
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$store.commit('loading', true);
            axiosResource
              .post(`/reports/changeStatus/${status}`, {
                reportUuid,
              })
              .then((res) => {
                this.$store.commit('loading', false);
                if (res.data.success) {
                  swalBootstrap
                    .fire({
                      title: this.$t('success.done'),
                      showConfirmButton: false,
                      icon: 'success',
                      timer: 1500,
                    })
                    .then(() => {
                      this.$store.dispatch('changeStatus', {
                        status: status,
                        reportUuids: res.data.reportUuids,
                      });
                    });
                }
              })
              .catch((err) => {
                this.$store.commit('loading', false);
                this.loading = false;
                console.log(err);

                const errorMessage = err.response?.data?.error;
                const failedReportRefs =
                  err.response?.data?.failedReportRefs || [];
                let swalMessage;

                switch (errorMessage) {
                  case 'error.report.closureNotAllowed':
                    swalMessage = this.$t('error.report.closureNotAllowed');
                    break;
                  case 'error.report.hasWiRef':
                    swalMessage = this.$t('error.report.hasWiRef');
                    break;
                  case 'error.report.containsQuick':
                    swalMessage = this.$t('error.report.containsQuick');
                    break;
                  case 'command.protect.selectLessThan':
                    swalMessage = this.$t('error.selection.actionExceedLimit', {
                      max: err.response?.data?.maxSelection,
                    });
                    break;
                  case 'error.report.deletionNotAllowed':
                    swalMessage = this.$t('error.report.deletionNotAllowed');
                    break;
                  case 'error.updateSomeReports':
                    const numFailed = failedReportRefs.length;
                    const reportRefsList = failedReportRefs.join(', ');
                    swalMessage = this.$t('error.updateSomeReports', {
                      num: numFailed,
                    });
                    break;
                  default:
                    swalMessage =
                      err.response?.data?.message ||
                      this.$t('error.unknownError');
                    break;
                }

                swalBootstrap.fire({
                  title: this.$t('error.uh-oh'),
                  text: this.$t(swalMessage),
                  icon: 'warning',
                  showConfirmButton: true,
                  footer:
                    errorMessage === 'error.updateSomeReports'
                      ? this.$t('error.failedReportsList', {
                          reportRefs: reportRefsList,
                        })
                      : undefined,
                });
              });
          }
        });
    },
    async editReport(reportUuid) {
      try {
        const response = await axiosResource.post(`/reports/checkEdit`, {
          reportUuid,
        });
        if (response.data.success) {
          const report = response.data.report;

          // Redirect to the edit page, passing the report as a parameter
          this.$router.push({
            name: 'create',
            params: { purpose: 'detailed', mode: 'edit', report },
          });
        }
      } catch (error) {
        console.error('Error fetching report for edit:', error);

        const { data } = error.response || {};
        const title = this.$t('error.sorry');
        let text;

        switch (data?.error || data?.message) {
          case 'command.protect.selectLessThan':
            text = this.$t('error.report.onlyOneAllowed');
            break;
          case 'error.report.notReportOwner':
            text = this.$t('error.report.notReportOwner');
            break;
          case 'error.report.isNotOpen':
            text = this.$t('error.report.isNotOpen');
            break;
          default:
            this.$globalSwal.errorSimple(this.$t('error.unknownError'));
            return;
        }

        // Display the error alert
        swalBootstrap.fire({
          title,
          text,
          showConfirmButton: true,
          icon: 'warning',
        });
      }
    },
    notReady() {
      swalBootstrap.fire({
        title: 'Oops',
        text: this.$t('error.notReady'),
        showConfirmButton: true,
        icon: 'info',
      });
    },
    workOngoing() {
      swalBootstrap.fire({
        title: this.$t('error.workOngoing.title'),
        text: this.$t('error.workOngoing.text'),
        showConfirmButton: true,
        icon: 'info',
      });
    },
    // areAllWiRefSimilar(selectedReportsFull) {
    //   if (selectedReportsFull.length === 0) {
    //     return true; // If the array is empty, consider them as similar
    //   }

    //   const firstWiRef = selectedReportsFull[0].wiRef;

    //   for (let i = 1; i < selectedReportsFull.length; i++) {
    //     if (selectedReportsFull[i].wiRef !== firstWiRef) {
    //       return false;
    //     }
    //   }

    //   return true;
    // },
    showHistory(reportUuid, reportRef) {
      this.$store.commit('setReportUuid', reportUuid);
      this.$store.commit('setReportRef', reportRef);
      // this.$refs.modalHistory.reportId = reportId;
      this.$bvModal.show('modal-history');
    },
    pdfSpinner(reportUuid, set) {
      const iconPdf = document.getElementById('iconPdf-' + reportUuid);
      const spinnerPdf = document.getElementById('spinnerPdf-' + reportUuid);
      if (
        typeof iconPdf != 'undefined' &&
        iconPdf != null &&
        typeof spinnerPdf != 'undefined' &&
        spinnerPdf != null
      ) {
        switch (set) {
          case 'ON':
            iconPdf.style.display = 'none';
            spinnerPdf.style.display = 'block';
            break;
          case 'OFF':
            iconPdf.style.display = 'block';
            spinnerPdf.style.display = 'none';
            break;
          default:
            break;
        }
        iconPdf;
      }
    },
    async servePDF(reportUuid = null) {
      this.loading = true;
      if (reportUuid) {
        this.pdfSpinner(reportUuid, 'ON');
      }

      try {
        const response = await axiosResource.post(
          `/serve/pdfSimple`, // The server endpoint
          { reportUuid }, // Send reportUuid in the body
          {
            headers: {
              Accept: 'application/pdf, application/zip',
            },
            responseType: 'blob', // Expect a file as a response
          }
        );

        // Extract file name from the Content-Disposition header
        const contentDisposition = response.headers['content-disposition'];
        const fileNameMatch = contentDisposition?.match(/filename="(.+)"/);
        const fileName = fileNameMatch ? fileNameMatch[1] : 'download';

        // Create a Blob and download the file
        const blob = new Blob([response.data], {
          type: response.headers['content-type'],
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove(); // Clean up

        if (reportUuid) {
          this.pdfSpinner(reportUuid, 'OFF');
        }
      } catch (error) {
        if (error.response?.data instanceof Blob) {
          // Attempt to parse the Blob as JSON
          const reader = new FileReader();
          reader.onload = () => {
            try {
              const errorData = JSON.parse(reader.result);
              console.error('Parsed error data:', errorData);

              if (errorData.error === 'command.protect.selectLessThan') {
                this.$toast.error(
                  this.$t('error.selection.exceeds', {
                    maxSelection: errorData.maxSelection,
                  }),
                  {
                    position: 'top-center',
                    timeout: 5000,
                  }
                );
              } else {
                this.$globalSwal.error(
                  this.$t(errorData.message || 'error.unknown')
                );
              }
            } catch (parseError) {
              console.error('Error parsing JSON from Blob:', parseError);
              this.$globalSwal.error(this.$t('error.unknown'));
            }
          };
          reader.onerror = () => {
            console.error('Error reading Blob:', reader.error);
            this.$globalSwal.error(this.$t('error.unknown'));
          };
          reader.readAsText(error.response.data);
        } else {
          // Handle other errors
          this.$globalSwal.error(this.$t('error.unknown'));
        }
      } finally {
        this.loading = false;
      }
    },
    // async generatePDFsSequentially(reports) {
    //   for (const report of reports) {
    //     await this.servePDF(report);
    //   }
    // },
    synthesisPDF() {
      this.loading = true;
      const projectUuid = this.$store.getters.currentProject.uuid;
      const initials = this.$store.getters.loggedUser.initials;

      axiosResource
        .get(`/pdf/synthesis/${projectUuid}/${initials}`, {
          responseType: 'arraybuffer',
          timeout: 10 * 60 * 1000, // Set timeout to 10 minutes (600000 ms)
        })
        .then((res) => {
          // Extract the filename from the Content-Disposition header
          const contentDisposition = res.headers['content-disposition'];
          let fileName = 'download.zip'; // Default name in case it's not provided
          // if (contentDisposition) {
          //   const fileNameMatch = contentDisposition.match(/filename="?(.+)"?/);
          //   if (fileNameMatch && fileNameMatch.length > 1) {
          //     fileName = fileNameMatch[1];
          //   }
          // }

          // Create a blob and download the ZIP file using the provided filename
          let blob = new Blob([res.data], { type: 'application/zip' });
          let link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = fileName;
          link.click();
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;

          if (error.response) {
            // Check the Content-Type header to determine the response type
            const contentType = error.response.headers['content-type'];

            if (contentType.includes('application/json')) {
              // Handle JSON error response
              try {
                const errorData = JSON.parse(
                  new TextDecoder().decode(new Uint8Array(error.response.data))
                );
                if (errorData.error === 'command.protect.selectLessThan') {
                  this.$toast.error(
                    this.$t('error.selection.exceeds', {
                      maxSelection: errorData.maxSelection,
                    }),
                    {
                      position: 'top-center',
                      timeout: 5000,
                    }
                  );
                } else {
                  this.$globalSwal.error(
                    this.$t(errorData.message || 'error.unknown')
                  );
                }
              } catch (parseError) {
                console.error('Error parsing JSON:', parseError);
                this.$globalSwal.error(this.$t('error.unknown'));
              }
            } else if (
              contentType.includes('application/pdf') ||
              contentType.includes('application/zip')
            ) {
              // Handle unexpected file response
              this.$globalSwal.error(this.$t('error.unexpectedFileResponse'));
            } else {
              // Handle unknown content type
              this.$globalSwal.error(this.$t('error.unknown'));
            }
          } else {
            // Handle errors with no response (e.g., network issues)
            this.$globalSwal.error(this.$t('error.networkError'));
          }
        });
    },
    xlsExport(option) {
      this.loading = true;
      const projectRef = this.$store.getters.currentProject.ref;
      const initials = this.$store.getters.loggedUser.initials;

      axiosResource
        .post(
          `/reports/xlsExport/${option}`,
          {
            data: {
              projectRef,
            },
          },
          {
            responseType: 'blob',
          }
        )
        .then((res) => {
          this.loading = false;
          let fileName;
          if (option == 'withPhoto') {
            fileName = `download.zip`;
          } else {
            fileName = `GOSO-${projectRef}_${moment().format(
              'YYYYMMDD-HHmmss'
            )}.xlsx`;
          }

          const url = URL.createObjectURL(
            new Blob([res.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          this.$globalSwal.error(err.response.data.msg);
        });
    },
  },
};
