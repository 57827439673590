<template>
  <div>
    <!-- MOBILE BOTTOM CONTROLS -->
    <div class="fixed-bottom d-block d-sm-none btn-container">
      <b-row>
        <b-col cols="9">
          <div
            class="inline-block d-flex align-items-center"
            style="height: 100%;"
          >
            <font-awesome-icon
              class="fa-fw mr-2"
              icon="fa-thin fa-bullseye-pointer"
              size="xl"
            />
            <!-- <span class="text-current-ref">{{ this.$store.getters.building.label }} {{ this.$store.getters.level }}{{ this.$store.getters.zone }}{{ this.$store.getters.serial }} {{ this.$store.getters.definition.value }}</span> -->

            <span class="text-current-ref">{{ this.$store.getters.building.label }} {{ this.$store.getters.level }}{{ this.$store.getters.zone }}{{ this.$store.getters.serial }} {{ this.$store.getters.definition.value }}</span>
          </div>
        </b-col>

        <b-col cols="3">

          <el-button
            class="btn-block btn-jumbo-action"
            @click="deployButtons"
          >
            <font-awesome-icon
              class="fa-fw"
              icon="fa-light fa-plus"
              size="lg"
            />
          </el-button>

          <!-- BUTTON: Detailed Report -->

          <router-link :to="{ name: 'create', params: { purpose: 'detailed', mode: 'new' }}">
            <el-button
              class="action-button buttonA neutral-gray"
              v-if="showBtnStandard"
            >
              <font-awesome-icon
                class="fa-fw"
                icon="fa-duotone fa-memo-pad"
                size="lg"
              />
            </el-button>
          </router-link>

          <!-- BUTTON: Quick like -->

          <el-button
            v-if="showBtnStandard"
            class="action-button buttonB quick-like"
            @click="rootQuickInteraction('like')"
          >
            <font-awesome-icon
              class="fa-fw"
              icon="fa-duotone fa-thumbs-up"
              size="lg"
            />
          </el-button>

          <!-- BUTTON: Quick visited -->

          <el-button
            class="action-button buttonC quick-visited"
            v-if="showBtnStandard"
            @click="rootQuickInteraction('visited')"
          >
            <font-awesome-icon
              class="fa-fw"
              icon="fa-duotone fa-person-walking"
              size="lg"
            />
          </el-button>

          <!-- BUTTON: Quick locked -->

          <el-button
            v-if="showBtnStandard"
            class="action-button buttonD quick-pink"
            @click="rootQuickInteraction('locked')"
          >
            <font-awesome-icon
              class="fa-fw"
              icon="fa-duotone fa-lock"
              size="lg"
            />
          </el-button>

          <!-- BUTTON: Quick busy -->

          <el-button
            v-if="showBtnStandard"
            class="action-button buttonE quick-busy"
            @click="rootQuickInteraction('busy')"
          >
            <font-awesome-icon
              class="fa-fw"
              icon="fa-duotone fa-ban"
              size="lg"
            />
          </el-button>

          <!-- BUTTON: Checklist -->

          <el-button
            v-if="showBtnStandard && (this.$store.getters.definition.value == 'FE' || this.$store.getters.definition.value == 'PO' || this.$store.getters.definition.value == 'PCF')"
            class="action-button buttonF checklist"
            @click="notReady"
          >
            <font-awesome-icon
              class="fa-fw"
              icon="fa-duotone fa-list"
              size="lg"
            />
          </el-button>
        </b-col>
      </b-row>
    </div>

    <!-- TABLET & DESKTOP CONTROLS -->
    <div class="d-none d-md-inline-flex justify-content-end align-items-center action-container">
      <div class="current-reference px-4">
        <font-awesome-icon
          class="mr-3"
          icon="fa-thin fa-bullseye-pointer"
          size="xs"
        />
        <!-- <span>{{ this.$store.getters.building.label }} {{ this.$store.getters.level }}{{ this.$store.getters.zone }}{{ this.$store.getters.serial }} {{ this.$store.getters.definition.value }}</span> -->
        <span>{{ this.localRef(this.$store.getters.building.label, this.$store.getters.level, this.$store.getters.zone, this.$store.getters.serial, this.$store.getters.definition.value) }}</span>
      </div>

      <!-- BUTTON: Detailed Report -->

      <router-link :to="{ name: 'create', params: { purpose: 'detailed', mode: 'new' }}">
        <el-button
          class="action-button buttonA neutral-gray"
          v-if="showBtnStandard"
        >
          <font-awesome-icon
            class="fa-fw"
            icon="fa-duotone fa-memo-pad"
            size="lg"
          />
        </el-button>
      </router-link>

      <!-- BUTTON: Quick like -->

      <el-button
        v-if="showBtnStandard"
        class="action-button buttonB quick-like"
        @click="rootQuickInteraction('like')"
      >
        <font-awesome-icon
          class="fa-fw"
          icon="fa-duotone fa-thumbs-up"
          size="lg"
        />
      </el-button>

      <!-- BUTTON: Quick visited -->

      <el-button
        class="action-button buttonC quick-visited"
        v-if="showBtnStandard"
        @click="rootQuickInteraction('visited')"
      >
        <font-awesome-icon
          class="fa-fw"
          icon="fa-duotone fa-person-walking"
          size="lg"
        />
      </el-button>

      <!-- BUTTON: Quick locked -->

      <el-button
        v-if="showBtnStandard"
        class="action-button buttonD quick-pink"
        @click="rootQuickInteraction('locked')"
      >
        <font-awesome-icon
          class="fa-fw"
          icon="fa-duotone fa-lock"
          size="lg"
        />
      </el-button>

      <!-- BUTTON: Quick busy -->

      <el-button
        v-if="showBtnStandard"
        class="action-button buttonE quick-busy"
        @click="rootQuickInteraction('busy')"
      >
        <font-awesome-icon
          class="fa-fw"
          icon="fa-duotone fa-ban"
          size="lg"
        />
      </el-button>

      <!-- BUTTON: Checklist -->

      <el-button
        v-if="showBtnStandard && (this.$store.getters.definition.value == 'FE' || this.$store.getters.definition.value == 'PO' || this.$store.getters.definition.value == 'PCF')"
        class="action-button buttonF checklist"
        @click="notReady"
      >
        <font-awesome-icon
          class="fa-fw"
          icon="fa-duotone fa-list"
          size="lg"
        />
      </el-button>

      <!-- ================== -->
      <!-- Deployement button -->

      <el-button
        @click="deployButtons"
        class="z-index-1011"
      >
        <font-awesome-icon
          class="fa-fw"
          icon="fa-light fa-plus"
          size="lg"
        />
      </el-button>

    </div>
  </div>
</template>
<script>
import axiosResource from '@/utils/axiosResource';
import swal from 'sweetalert2';
import { localRefMixin } from '../../mixins/localRefMixin';

const swalBootsrap = swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary btn-swal',
    cancelButton: 'btn btn-light btn-swal',
  },
  buttonsStyling: false,
});

export default {
  name: 'ActionControl',
  mixins: [localRefMixin],
  props: [
    'objRef',
    'buildingUuid',
    'buildingName',
    'levelName',
    'zoneName',
    'serial',
    'definition',
  ],
  data() {
    return {
      showBtnStandard: false,
      projectId: this.$store.getters.currentProject.id,
    };
  },
  methods: {
    deployButtons() {
      this.showBtnStandard = !this.showBtnStandard;
    },
    notReady() {
      swalBootsrap.fire({
        title: 'Oops',
        text: this.$t('error.notReady'),
        showConfirmButton: true,
        icon: 'info',
      });
    },
    rootQuickInteraction(quickType) {
      console.log(quickType);
      // Check for complete reference
      // Reference
      const buildingUuid = this.$store.getters.building.uuid;
      const buildingLabel = this.$store.getters.building.label;
      const level = this.$store.getters.level;
      const serial = this.$store.getters.serial;
      const zone = this.$store.getters.zone;
      const definition = this.$store.getters.definition.value;
      const reference = {
        buildingUuid,
        buildingLabel,
        level,
        serial,
        zone,
        definition,
      };

      function referenceIsValid() {
        console.log('referenceIsValid');
        return new Promise((resolve) => {
          let valid = true;
          for (const [key, value] of Object.entries(reference)) {
            console.log(key, value);
            if (
              value == '' ||
              value === null ||
              value.toString().trim().length == 0
            )
              valid = false;
          }
          resolve(valid);
        });
      }
      const vm = this;
      async function checkIsValid() {
        const clgStyle = 'color: red;';
        console.log('%c' + 'Action CONTROL: checkIsValid', clgStyle);
        const result = await referenceIsValid();
        if (!result) {
          swalBootsrap.fire({
            title: vm.$t('error.missingImformation'),
            text: vm.$t('error.incompleteReference'),
            showConfirmButton: true,
            icon: 'warning',
          });
        } else {
          vm.$router
            .push({
              name: 'create',
              params: { purpose: quickType, mode: 'new' },
            })
            .catch(() => {
              // Simulate an error handled, issue with duplication (tried with .replace() as well)
            });
        }
        // expected output: "resolved"
      }

      checkIsValid();

      // const referenceIsValid = function () {
      //   for (const [key, value] of Object.entries(reference)) {
      //     console.log(key, value);
      //     if (value == '' || value === null) return false;
      //   }
      // };

      // if (!referenceIsValid) {
      //   swalBootsrap.fire({
      //     title: this.$t('error.missingImformation'),
      //     text: this.$t('error.incompleteReference'),
      //     showConfirmButton: true,
      //     icon: 'warning',
      //   });
      // } else {
      //   this.$router
      //     .push({
      //       name: 'create',
      //       params: { purpose: quickType },
      //     })
      //     .catch(() => {
      //       // Simulate an error handled, issue with duplication (tried with .replace() as well)
      //     });
      // }

      // for (const [key, value] of Object.entries(reference)) {
      //   console.log(key, value);
      //   if (value == '' || value === null) {
      //     swalBootsrap.fire({
      //       title: this.$t('error.missingImformation'),
      //       text: this.$t('error.incompleteReference'),
      //       showConfirmButton: true,
      //       icon: 'warning',
      //     });
      //     return;
      //   }
      //   this.$router
      //     .push({
      //       name: 'create',
      //       params: { purpose: quickType },
      //     })
      //     .catch(() => {
      //       // Simulate an error handled, issue with duplication (tried with .replace() as well)
      //     });
      // }
    },
    // saveQuickInteraction(quickType) {
    //   this.showBtnStandard = false;
    //   // Reference
    //   const buildingUuid = this.$store.getters.building.uuid;
    //   const buildingLabel = this.$store.getters.building.label;
    //   const level = this.$store.getters.level;
    //   const serial = this.$store.getters.serial;
    //   const zone = this.$store.getters.zone;
    //   const definition = this.$store.getters.definition.value;

    //   const reference = {
    //     buildingUuid,
    //     buildingLabel,
    //     level,
    //     serial,
    //     zone,
    //     definition,
    //   };
    //   // Check for complete reference
    //   for (const [key, value] of Object.entries(reference)) {
    //     console.log(key, value);
    //     if (value == '' || value === null) {
    //       swalBootsrap.fire({
    //         title: 'Missing information',
    //         text: 'Please select building, level, zone, serial number and local definition.',
    //         showConfirmButton: true,
    //         icon: 'warning',
    //       });
    //     }
    //   }

    //   // Open map dialog

    //   axiosResource
    //     .post(`/reports/quick/${this.projectId}`, {
    //       data: {
    //         reference,
    //         quickType,
    //       },
    //     })
    //     .then((res) => {
    //       if (res.data.success) {
    //         swalBootsrap
    //           .fire({
    //             title: this.$t('success.done'),
    //             showConfirmButton: false,
    //             icon: 'success',
    //             timer: 1500,
    //           })
    //           .then(() => {
    //             // Reset filters so the report can appear in Explore
    //             // this.$store.commit('setPriority', priority);
    //           });
    //       }
    //     })
    //     .catch((err) => {
    //       this.loading = false;
    //       this.$globalSwal.error(err.response.data.msg);
    //     });
    // },
  },
};
</script>
<style lang="scss" scoped>
.action-container {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 1030;
  background-color: white;
  border-radius: 4px;
}
.current-reference {
  background-color: #ffffff;
  position: fixed;
  right: 81px;
  height: 44px;
  border-left: 1px solid #e5e8eb;
  border-top: 1px solid #e5e8eb;
  border-bottom: 1px solid #e5e8eb;
  font-size: 26px;
  font-weight: 600;
}

.action-button {
  box-shadow: rgb(4 17 29 / 25%) 0px 0px 8px 0px;
  border-radius: 12px;
  padding: 19px;
}

.buttonA {
  right: 20px;
  bottom: 90px;
  position: fixed;
  animation-name: fadein;
  animation-duration: 0.1s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
}
.buttonB {
  right: 20px;
  bottom: 160px;
  position: fixed;
  animation-name: fadein;
  animation-duration: 0.2s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
}
.buttonC {
  right: 20px;
  bottom: 230px;
  position: fixed;
  animation-name: fadein;
  animation-duration: 0.3s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
}
.buttonD {
  right: 20px;
  bottom: 300px;
  position: fixed;
  animation-name: fadein;
  animation-duration: 0.4s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
}
.buttonE {
  right: 20px;
  bottom: 370px;
  position: fixed;
  animation-name: fadein;
  animation-duration: 0.5s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
}
.buttonF {
  right: 20px;
  bottom: 440px;
  position: fixed;
  animation-name: fadein;
  animation-duration: 0.5s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.btn-container {
  background: rgb(255, 255, 255);
  border-top: 1px solid rgb(229, 232, 235);
  padding-left: 18px;
  padding-right: 18px;
  padding-top: 8px;
  padding-bottom: 16px;
  background-color: white;
}
.btn-jumbo-action {
  border-radius: 12px;
  padding: 16px 20px 16px 20px;
  font-size: 12px;
  font-weight: 600;
  // line-height: 22px;
  // letter-spacing: 0.01em;
}
.el-button:focus,
.el-button:hover {
  color: #606266 !important;
  border-color: #dcdfe6 !important;
  background-color: #ffffff !important;
}
.text-current-ref {
  font-size: 1.1rem;
  font-weight: 600;
}
</style>
