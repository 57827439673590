<template>
  <b-modal
    id="modal-wi"
    :title="$t('modal.wi.title')"
    button-size="sm"
    @show="onShow"
  >
    <div
      v-loading="loading"
      v-if="loadingIsVisible"
      style="height:200px; width: 100%"
    ></div>
    <b-alert
      variant="danger"
      v-if="tooManyReports"
      :show="true"
    >
      {{ $t('modal.address.tooManyReports', { count: this.maxSelection }) }}
    </b-alert>
    <b-alert
      variant="warning"
      v-if="!containsQuick && !DIFFERENT_WI_REF && !tooManyReports && !this.$store.getters.reportUuid && this.$store.getters.selectedCount > 1 && this.$store.getters.selectedCount <= 99"
      :show="true"
    >
      {{ $t('modal.address.multipleReports', { count: this.$store.getters.selectedCount }) }}
    </b-alert>
    <b-alert
      show
      variant="danger"
      v-if="DIFFERENT_WI_REF"
    >{{ this.$t('error.wi.DIFFERENT_WI_REF') }}</b-alert>
    <b-alert
      show
      variant="danger"
      v-if="containsQuick"
    >{{ this.$t('error.report.containsQuick') }}</b-alert>

    <form
      ref="form"
      v-if="formIsVisible"
    >
      <b-row>
        <b-col cols="12">
          <b-form-group
            type="text"
            :label="$t('modal.wi.number')+ '*'"
          >
            <b-form-input
              type="text"
              class="form-control"
              id="name"
              v-model="form.wiRef"
              :placeholder="$t('filter.select')"
              @blur="$v.form.wiRef.$touch"
            ></b-form-input>
            <label
              class="error"
              v-if="!$v.form.wiRef.required && $v.form.wiRef.$dirty"
            >{{ $t('validate.required') }}
            </label>
            <label
              class="error"
              v-if="!$v.form.wiRef.maxLength && $v.form.wiRef.$dirty"
            >{{ $t('validate.maxLength', { val: '20' }) }}</label>
            <label
              class="error"
              v-if="!$v.form.wiRef.minLength && $v.form.wiRef.$dirty"
            >{{ $t('validate.minLength', { val: '1' }) }}</label>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group
            type="text"
            :label="$t('modal.wi.dueDate')"
          >
            <el-date-picker
              v-model="form.wiDueDate"
              type="date"
              :placeholder="$t('filter.select')"
              class="date-picker w-100"
            >
            </el-date-picker>
            <!-- <label
              class="error"
              v-if="!$v.form.wiDueDate.required && $v.form.wiDueDate.$dirty"
            >{{ $t('validate.required') }}
            </label> -->
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <small class="text-muted">* {{ $t('validate.requiredFields') }}</small>
        </b-col>
      </b-row>
    </form>

    <template #modal-footer="{ hide }">
      <b-button
        variant="outline-danger"
        size="sm"
        @click="handleWI('remove')"
        v-if="wiPresence"
      >{{ $t('action.delete') }}</b-button>
      <b-button
        size="sm"
        variant="primary"
        @click="handleWI('save')"
        v-if="!(DIFFERENT_WI_REF || containsQuick)"
      >
        {{ $t('action.save') }}
      </b-button>
      <b-button
        size="sm"
        variant="light"
        @click="hide()"
      >
        {{ $t('action.close') }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import axiosResource from '@/utils/axiosResource';
import { globalSwal } from '@/plugins/globalSwal';
import swal from 'sweetalert2';
const swalBootsrap = swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary btn-swal',
    cancelButton: 'btn btn-light btn-swal',
  },
  buttonsStyling: false,
});

export default {
  name: 'modal-wi',
  data() {
    return {
      form: {
        wiRef: '',
        wiDueDate: '',
      },
      mode: 'new',
      hasWI: false,
      loading: true,
      loadingIsVisible: true,
      formIsVisible: true,
      DIFFERENT_WI_REF: false,
      tooManyReports: false,
      containsQuick: false,
      wiPresence: false,
      maxSelection: '',
    };
  },
  validations: {
    form: {
      wiRef: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(20),
      },
      wiDueDate: {},
      reportUuid: '',
    },
  },
  computed: {
    reportUuid() {
      return this.$store.getters.reportUuid; // Access `reportUuid` from Vuex
    },
  },
  methods: {
    onShow() {
      this.checkWI(this.reportUuid);
    },
    async checkWI(reportUuid = null) {
      // Start the loading indicator (spinner) for checking WI
      this.loading = true;
      this.wiPresence = false;
      console.log(reportUuid);

      try {
        // Prepare the data to be sent in the request
        const reportUuid = this.$store.getters.reportUuid;

        // Make an Axios POST request to the `checkWI` endpoint
        const response = await axiosResource.post(`reports/wi/check`, {
          reportUuid, // Send single reportUuid if provided, or fetch the selection on the server
        });

        // Handle the server response based on the `status` value returned
        if (response.data.success) {
          console.log(`SUCCESS`);
          console.log(response.data);
          this.loading = false;
          this.loadingIsVisible = false;
          this.DIFFERENT_WI_REF = false;
          this.containsQuick = false;
          this.tooManyReports = false;
          switch (response.data.status) {
            case 'NO_WI_REF':
              // No existing WI reference; proceed to set a new WI
              this.formIsVisible = true;
              break;
            case 'SAME_WI_REF':
              // All WI refs are the same; pre-fill form for editing
              if (response.data.wiRef) {
                this.wiPresence = true;
              }
              this.formIsVisible = true;
              this.form.wiRef = response.data.wiRef;
              this.form.wiDueDate = response.data.wiDueDate;
              break;
            case 'DIFFERENT_WI_REF':
              console.log(`DIFFERENT_WI_REF`);
              // Different WI refs exist; display an error message

              this.formIsVisible = false;
              this.DIFFERENT_WI_REF = true;
              break;
            case 'error.report.containsQuick':
              this.formIsVisible = false;
              this.containsQuick = true;
              break;
            case 'command.protect.selectLessThan':
              this.formIsVisible = false;
              this.tooManyReports = true;
              this.maxSelection = response.data.maxSelection;
              console.log(`command.protect.selectLessThan`);
              break;

            default:
              console.warn('Unexpected status:', response.data.status);
          }
        } else {
          // Handle error response

          this.$globalSwal.errorSimple(
            response.data.message || this.$t('error.unknown')
          );
        }
      } catch (error) {
        // Handle request failure or other errors
        this.$bvModal.hide('modal-wi');
        console.error('Error checking WI:', error);
        this.$globalSwal.errorSimple(this.$t('error.unknown'));
      } finally {
        // Stop the loading indicator
        this.loading = false;
      }
    },

    handleWI(aim) {
      this.$store.commit('loading', true);
      const reportUuid = this.$store.getters.reportUuid;
      // Close modal
      this.$bvModal.hide('modal-wi');

      // Initialize variables
      let wiRef, wiDueDate;

      if (aim === 'save') {
        if (this.$v.form.$invalid) {
          this.$v.form.$touch();
          return;
        }
        // Assign values from the form if saving
        wiRef = this.form.wiRef;
        wiDueDate = this.form.wiDueDate;
      } else {
        // Set values to null if removing
        wiRef = null;
        wiDueDate = null;
      }

      axiosResource
        .post(`/reports/wi`, {
          reportUuid,
          aim,
          data: { wiRef, wiDueDate },
        })
        .then((response) => {
          this.$store.commit('loading', false);
          console.log(response);
          console.log(response.data.reportUuids);
          this.$store.dispatch('updateWIForReports', {
            wiRef: wiRef,
            wiDueDate: wiDueDate,
            reportUuids: response.data.reportUuids,
          });

          globalSwal.successSimple('success.done');
        })
        .catch((error) => {
          this.$store.commit('loading', false);
          console.error('Failed to update WI', error);
          swalBootsrap.fire({
            title: this.$t('error.title'),
            text: this.$t('error.message'),
            icon: 'error',
          });
        });
    },
  },
};
</script>

<style scoped>
.flag {
  padding-top: 2px;
  padding-bottom: 1px;
  padding-right: 12px;
  padding-left: 6px;
  border-radius: 3px;
}
.el-select {
  width: 100%;
}
</style>
