<template>
  <b-card
    title="Card Title"
    img-alt="Image"
    img-top
    no-body
    tag="article"
    footer-tag="footer"
    :class="$store.getters.selectionMode ? 'goso-card-quick-selectionMode' : 'goso-card-quick'"
    @mouseover="checkboxVisible = true"
    @mouseleave="selected ? checkboxVisible = true : checkboxVisible = false"
  >
    <!-- style="min-width: 310px;" -->

    <b-row
      no-gutters
      class="d-flex align-items-center"
    >

      <b-col
        cols="10"
        class="inline"
      >
        <div class="quick-container mr-4">
          <Quick :quickType="report.quickInteraction" />
        </div>
        <div class="quick-container">
          <p class="text-left txt-article-title">{{ this.localRef(report.buildingName, report.level, report.zone, report.serial, report.definition) }}</p>
          <p class="text-left txt-article-subtitle">{{report.user.acronym4digits}} {{ report.interventionDate }}</p>
        </div>

      </b-col>
      <b-col
        cols="2"
        class="d-flex justify-content-end"
      >

        <!-- <el-button
          type="text"
          class="mr-2"
          style="padding: 0; font-size: 14px;"
          @click="notReady()"
        >
          <font-awesome-icon
            icon="fa-regular fa-ellipsis"
            :size="headerIconSize"
          />

        </el-button> -->
        <b-form-checkbox
          :class="['mr-1', { 'd-none d-md-inline': isMobile }]"
          v-show="checkboxVisible"
          :id="'checkbox-' + report.uuid"
          :name="'checkbox-' + report.uuid"
          v-model="selected"
          @change="checkboxChange"
          @click.stop
        >
        </b-form-checkbox>

        <el-dropdown
          trigger="click"
          class="ml-2 mr-2"
          @command="(command) => handleCommandPremiss(command, report.uuid)"
        >
          <span class="el-dropdown-link">
            <font-awesome-icon
              icon="fa-regular fa-ellipsis"
              :size="headerIconSize"
            />
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="EDIT">
              <font-awesome-icon
                icon="fa-light fa-trash"
                size="lg"
              /> {{ $t('action.delete')  }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>

      </b-col>
    </b-row>

    <!-- <div style="padding: 0.2rem; padding-bottom: 6px;">

      <b-row>
        <b-col
          cols="3"
          class="text-left txt-article-ref"
          style="border-right: 1px solid rgba(0, 0, 0, .0975);"
        >
          <span>Réf.:</span>
          <span>#{{ report.id }}</span>
        </b-col>
        <b-col
          cols="2"
          class="d-flex justify-content-center txt-article-type"
          style="border-right: 1px solid rgba(0, 0, 0, .0975);"
        >
          QUICK
        </b-col>
        <b-col
          cols="3"
          class="d-flex justify-content-center txt-article-di"
          style="border-right: 1px solid rgba(0, 0, 0, .0975);"
        >

        </b-col>
        <b-col
          cols="4"
          class="text-center txt-article-di"
        >
          <span :class="`status-${quickStatus}`">{{ $t('status.'+quickStatus) }}</span>
        </b-col>
      </b-row>

    </div> -->

  </b-card>
</template>

<script>
import Quick from '../TableComponents/Quick.vue';
import { localRefMixin } from '../../../mixins/localRefMixin';
import { selectionMixin } from '@/mixins/selectionMixin';
import swal from 'sweetalert2';
const swalBootsrap = swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary btn-swal',
    cancelButton: 'btn btn-light btn-swal',
  },
  buttonsStyling: false,
});

export default {
  name: 'article-quick',
  props: ['report'],
  mixins: [localRefMixin, selectionMixin],
  components: {
    Quick,
  },
  data() {
    return {
      selected: this.report.isSelected || false, // Initialize with report.isSelected
      checkboxVisible: this.report.isSelected || false, // Make checkbox visible if selected
      isMobile: window.innerWidth < 768, // Initial check for mobile
      headerIconSize: 'lg',
    };
  },
  watch: {
    'report.isSelected': {
      immediate: true,
      handler(newVal) {
        this.selected = newVal;
        this.checkboxVisible = newVal; // Show checkbox if report is selected
      },
    },
  },
  mounted() {
    window.addEventListener('resize', this.checkMobile); // Listen for resize
    this.checkMobile(); // Initial check
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkMobile); // Clean up
  },
  computed: {
    quickStatus() {
      const quickStatus = this.report.quickInteraction.split('.')[1];
      return quickStatus.toUpperCase();
    },
  },
  methods: {
    checkMobile() {
      // Update `isMobile` based on screen width
      this.isMobile = window.innerWidth < 768;
    },
    handleCommandPremiss(command, report) {
      console.log(report);
      this.notReady();
    },
    notReady() {
      swalBootsrap.fire({
        title: 'Oops',
        text: this.$t('error.notReady'),
        showConfirmButton: true,
        icon: 'info',
      });
    },
  },
};
</script>

<style scoped>
.card {
    border-radius: 0px !important;
    border-bottom: 0.0425rem solid rgba(52, 58, 64, 0.285);
    border-top: none;
    border-left: none;
    border-right: none;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 12px;
    padding: 0.975rem 0.525rem 0.975rem 0.525rem;
}
@media (min-width: 768px) { 
  .card {
    /* border-radius: 6px !important;
    border: 0.0625rem solid rgba(52, 58, 64, 0.285) !important; */
    border: 1px solid rgba(0,0,0,.125);
    padding: 0.8rem 0.525rem 0.8rem 0.525rem;
    /* margin-left: 6px;
    margin-right: 6px;
    margin-top: 6px;
    margin-bottom: 6px;*/
    min-width: 300px; 
  }
  .card:hover {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 4px; /* Lower opacity and subtle shadow */
    transition: all 0.2s ease;
    -webkit-transition: all 0.2s ease; /* Fixed typo for Webkit */
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
  }
}



.el-tooltip {
  border-radius: 10px;
}

.draw_share_atooltip{
   background: red !important;
 }

/* 
@media (min-width: 768px) { 
  .card-img, .card-img-top {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
  }
  .card-header {
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
  }

  .card-footer {
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
  }
} */

.card-custom-body {
  margin: 0.4rem 0.4rem;
}

.txt-article-title {
  font-weight: 600;
  line-height: 15px;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 5px;
  color: #262626;
  margin-top: 0.1em;
  margin-bottom: 0.1em;
}

.txt-article-subtitle {
  color: #262626;
  line-height: 12px;
  font-size: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0.1em;
  margin-left: 5px;
}

.txt-article-body {
color: #262626;
line-height: 12px;
font-size: 12px;
font-weight: lighter !important;
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
margin-top: 0;
margin-bottom: 0.3rem;
}

.txt-article-ref {
  color: #262626;
  line-height: 12px !important;
  font-size: 10px;
  font-weight: lighter !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0.1em;
}
.txt-article-type {
  color: #262626;
  line-height: 12px !important;
  font-size: 10px;
  font-weight: lighter !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0.1em;
}
.txt-article-di {
  color: #262626;
  line-height: 12px !important;
  font-size: 9px;
  font-weight: lighter !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0.1em;
}
/* .txt-article-ref {
  color: #262626;
  line-height: 12px !important;
  font-size: 12px;
  font-weight: lighter !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0.1em;
} */


.longterm {
    color: blue !important;
}
.mediumterm {
    color: yellow !important;
}
.shortterm {
    color: orange !important;
}
.urgent {
    color: red !important;
}

.goso-card-quick {
  border: none; 
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 15px;
}
.goso-card-quick-selectionMode {
  border: none; 
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 15px;
  background-color: #ecf5ff;
}


.quick-container {
  display: inline-block;
  vertical-align: middle;
}
</style>
