<template>
  <div
    class="masonry-wrapper"
    id="masonry-wrapper"
  >
    <!-- <div class="screen-size-label">{{ screenSize }}</div> -->

    <!-- CHECKBOX Select All -->

    <div
      v-if="$store.getters.qtt == 0"
      height="50px"
    >
      <div
        v-if="!this.$store.getters.loader"
        class="noMatch-card"
      >{{ $tc('explore.noMatch') }}</div>
    </div>

    <div id="masonry-content">
      <masonry
        :cols="columnCount"
        :gutter="{default: '30px', 700: '15px'}"
        class="masonry-inner ml-0"
      >
        <template v-for="item in sourceData">
          <ArticleQuick
            :key="item.uuid"
            :report="item"
            v-if="checkForQuick(item.interventionType)"
          />
          <ArticleDetailed
            :key="item.uuid"
            :report="item"
            @show-preview="openModal"
            @show-map="openMapModal(item)"
            v-else
          />

        </template>

      </masonry>
      <!-- <transition name="fade">
        <div
          class="load-more-button"
          @click="append"
          v-if="!this.$store.getters.loader && this.$store.getters.qttLoaded < this.$store.getters.qtt"
        >
          <span class="load-more-text">{{ $t('loadMore') }}</span>
        </div>
      </transition> -->
      <transition name="fade">
        <div
          class="load-more-button"
          @click="append"
          v-if="showButton"
        >
          <span class="load-more-text">{{ $t('loadMore') }}</span>
        </div>
      </transition>

    </div>

    <modal-history ref="modalHistory"></modal-history>
    <ModalImage
      ref="modalImage"
      :report="selectedReport"
    />

    <ModalMap
      ref="modalMap"
      :drawingUrl="selectedReport?.presignedUrlDrawing"
      :markerX="selectedReport?.markerX"
      :markerY="selectedReport?.markerY"
      v-if="selectedReport"
    />
  </div>
</template>


<script src="https://unpkg.com/vue-masonry-css"></script>

<script>
import ArticleDetailed from './CardComponents/ArticleDetailed.vue';
import ArticleQuick from './CardComponents/ArticleQuick.vue';
import ModalHistory from '../../components/Modals/ModalHistory.vue';
import ModalImage from '../../components/Modals/ModalImage.vue';

import ModalMap from '../../components/Modals/ModalMap.vue';
import VueMasonry from 'vue-masonry-css';

import { selectionMixin } from '@/mixins/selectionMixin';

export default {
  components: {
    VueMasonry,
    ArticleDetailed,
    ArticleQuick,
    ModalHistory,
    ModalImage,
    ModalMap,
  },
  name: 'card-view',
  mixins: [selectionMixin],
  data() {
    return {
      selectedReport: null,
      drawingPresignedURL: '',
      screenSize: '',
      showButton: false,
      delayTimeout: null,
    };
  },
  watch: {
    shouldShowButton(newValue) {
      if (this.delayTimeout) {
        clearTimeout(this.delayTimeout); // Clear any existing timeouts
      }
      if (newValue) {
        // Delay showing the button
        this.delayTimeout = setTimeout(() => {
          this.showButton = true;
        }, 2);
      } else {
        // Immediately hide the button
        this.showButton = false;
      }
    },
  },
  beforeDestroy() {
    // Clean up timeout on component destruction
    if (this.delayTimeout) {
      clearTimeout(this.delayTimeout);
    }
  },
  computed: {
    shouldShowButton() {
      return (
        !this.$store.getters.loader &&
        this.$store.getters.qttLoaded < this.$store.getters.qtt
      );
    },
    sourceData() {
      return this.$store.getters.reports;
    },
    columnCount() {
      const width = window.innerWidth;
      if (width >= 1400) {
        this.screenSize = 'XX-Large devices (1400px and up)';
        return { default: 3, 1000: 3, 700: 2, 400: 1 };
      } else if (width >= 1200) {
        this.screenSize = 'X-Large devices (1200px and up)';
        return { default: 2, 1000: 3, 700: 2, 400: 1 };
      } else if (width >= 992) {
        this.screenSize = 'Large devices (992px and up)';
        return { default: 1, 1000: 3, 700: 2, 400: 1 };
      } else if (width >= 768) {
        this.screenSize = 'Medium devices (768px and up)';
        return { default: 1, 1000: 1, 700: 1, 400: 1 };
      } else {
        this.screenSize = 'Small devices (576px and up)';
        return { default: 1, 1000: 1, 700: 1, 400: 1 };
      }
    },
  },
  beforeCreate() {
    this.$store.commit('loading', true);
  },
  async mounted() {
    setTimeout(() => {
      this.initialized = true;
    }, 1000);
    this.exitSelection(); // Exit selection
    this.$store.commit('loading', true);
    this.$store.commit('initSorting');
    this.$store.commit('initPage');
    this.$store.commit('initReports', 'TinyCardView');
    this.$store.commit('resetQttLoaded');
    try {
      await this.$store.dispatch('fetchReportsGeneric');
    } catch (error) {
      console.error('Error fetching reports:', error);
    }
    window.addEventListener('resize', this.updateScreenSize);
    this.updateScreenSize();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateScreenSize);
    this.$store.commit('SET_FETCHING', false);
  },
  methods: {
    setInitialOpacity() {
      this.$nextTick(() => {
        const loadMoreButton = this.$refs.loadMoreButton;
        if (loadMoreButton) {
          loadMoreButton.style.opacity = '1';
        }
      });
    },
    updateScreenSize() {
      this.columnCount; // Trigger computed property to update screenSize label
    },
    openModal(report) {
      this.selectedReport = report;
      if (this.$refs.modalImage && this.$refs.modalImage.show) {
        this.$refs.modalImage.show();
      } else {
        console.error('ModalImage component not properly referenced.');
      }
    },
    openMapModal(report) {
      this.selectedReport = report;
      this.$nextTick(() => {
        if (this.$refs.modalMap && this.$refs.modalMap.show) {
          this.$refs.modalMap.show();
        } else {
          console.error('ModalMap component not properly referenced.');
        }
      });
    },
    checkForQuick(interventionType) {
      if (typeof interventionType !== 'undefined') {
        return interventionType == 'QUICK';
      }
    },
    async append() {
      const qtt = this.$store.getters.qtt;
      const qttLoaded = this.$store.getters.qttLoaded;
      if (qttLoaded < qtt) {
        this.$store.commit('incrementPage');
        // If in selection mode, fetch from selection
        if (this.$store.getters.selectionMode) {
          await this.$store.dispatch('fetchReportsGeneric', true);
        } else {
          await this.$store.dispatch('fetchReportsGeneric');
        }
      } else {
        this.$store.commit('SET_FETCHING', false); // Reset flag if no more data to fetch
      }
    },
  },
};
</script>


<style scoped>
html {
  width: 100%;
}

.screen-size-label {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

.masonry-inner {
  margin-left: -14px !important;
}

.masonry-wrapper {
  margin-top: 14px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}

@media (min-width: 768px) {
  .masonry-wrapper {
    margin-top: 0;
    height: calc(100vh - 140px);
    overflow-y: auto;
  }
}

.noMatch-card {
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  margin-top: 25px;
  padding-top: 25px;
  border-top: 1px solid #e5e8eb;
}









/* Define the transition for the fade effect */
.fade-enter-active {
  transition: opacity 5s ease;
}
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

/* Base styles for the load-more button */
.load-more-button {
  margin-bottom: 80px;
  height: 46px;
  border-radius: 0px;
  background-color: rgba(28, 28, 30, 0.5);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Styles for the load-more text */
.load-more-text {
  color: #fff;
  font-weight: 300;
  font-size: 22px;
  pointer-events: none;  /* Ensures clicks pass through to the parent div */
}

/* Mobile styles (default) */
.load-more-button {
  width: 100%;
}

/* Desktop styles */
@media (min-width: 768px) {
  .load-more-button {
    margin-left: 12px;
    border-radius: 12px;
    margin-bottom: 12px;
    height: 42px;
    width: 26%;  /* 1/3 of the width */
  }
}



</style>
