import { mapActions } from 'vuex';

export const sortMixin = {
  methods: {
    // ...mapActions(['setSortingPreference']), // Vuex action for storing user's sorting choice

    /**
     * Handles sorting command
     * @param {String} command - The command string representing the sorting choice
     */
    async handleSortingCommand(command) {
      // INIT STORE FOR DISPLAY
      this.$store.commit('initPage');
      this.$store.commit('initReports');
      this.$store.commit('resetQttLoaded');

      // Save the selected sorting choice in Vuex
      this.$store.dispatch('setSortingPreference', command);

      // Trigger report fetch with updated sorting
      if (this.$store.getters.isFetching) return;

      const selectionMode = this.$store.getters.selectionMode;

      // If in selection mode, fetch from selection
      if (selectionMode) {
        await this.$store.dispatch('fetchReportsGeneric', true);
      } else {
        await this.$store.dispatch('fetchReportsGeneric');
      }
    },
  },
};
